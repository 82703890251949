import Vue from 'vue'
import Vuex from 'vuex'
import {getUserNotifications} from '@/helpers/api/notifications'

Vue.use(Vuex)

const NotificationsStore = {
  namespaced: true,
	state: {
		loadingNotifications: true,
		badgeText: '--',
		badgeColor: '#cacaca',
		notifications: []
	},
	mutations: {
		setNotifications(state, notifications){
			state.notifications = notifications
		},
		setLoadingNotifications(state, status){
			state.loadingNotifications = status
		},
		setBadgeText(state, text){
			state.badgeText = text
		},
		setBadgeColor(state, color){
			state.badgeColor = color
		},
	},
	actions: {
		async getNotifications({commit}, take = 10){
			commit('setLoadingNotifications', true)
			const notifications = await getUserNotifications({
				take
			})
			if(notifications.code === 200){
				commit('setNotifications', notifications.notifications)
				commit('setLoadingNotifications', false)
				commit('setBadgeText', notifications.unread_count.toString())

        let badge_color = 'success'
        if(notifications.unread_count >= 5){
            badge_color = '#fb8c00'
        }
        if(notifications.unread_count >= 10){
            badge_color = '#F4516C'
        }

				commit('setBadgeColor', badge_color)
			}
		}
	}
}

export default NotificationsStore