<template>
	<div>
		<NewUserModal v-if="$store.state.users.newUserModal"/>
		<EditPermisosUserModal v-if="$store.state.users.editPermisosUserModal"/>
		<CompanyConceptModal v-if="$store.state.companies.newCompanyConceptModal"/>
		<CompanyModal v-if="$store.state.companies.newCompanyModal"/>
		<CompanyModalFirst v-if="$store.state.companies.newCompanyModalFirst && $store.state.user.userInfo"/>
		<RoleModal v-if="$store.state.companies.newRoleModal"/>
		<ProjectTypesModal v-if="$store.state.companies.newProjectTypeModal"/>
		<ReportTemplateModal v-if="$store.state.vt.newReportTemplateModal"/>
		<Schedule v-if="$store.state.schedule.newScheduleModal"/>
		<AgendaClosure v-if="$store.state.vt.agendaClosureModal"/>
		<NewCrmContactModal v-if="$store.state.crm.newContactModal"/>
		<NewCrmContactModal v-if="$store.state.crm.newContactModal"/>
		<NewPreProjectModal v-if="$store.state.preprojects.newPreProjectModal"/>
		<BudgetModal v-if="$store.state.general.newBudgetModal?.status"/>
		<NewBudgetSelectModal v-if="$store.state.budget.modalNewBudget"/>
		<BudgetConceptsModal v-if="$store.state.budget.newBudgetConceptModal" />
		<BudgetCategoryModal v-if="$store.state.budget.newBudgetCategoryModal" />
		<BudgetDetailModal v-if="$store.state.budget.newBudgetDetailModal" />
		<BudgetNoteTemplateModal v-if="$store.state.budget.newBudgetNoteTemplateModal" />
		<UserBillingInfoModal v-if="$store.state.general.userTypePromptModal" />
		<EditMyUserData v-if="$store.state.user.editMyUserDataModal" />
		<AddressModal  v-if="$store.state.general.addressModal" />
		<SocialProfile  v-if="$store.state.general.publicProfileModal" />
		<ProjectModal v-if="$store.state.projects.projectModal" />
		<ContractCategoryModal v-if="$store.state.contracts.modalCategory" />
		<ContractSectionModal v-if="$store.state.contracts.modalSection" />
		<CreateContractModal v-if="$store.state.contracts.modalContract" />
	</div>
</template>

<script>
import NewUserModal from './Users/NewUserModal'
import EditPermisosUserModal from './Users/EditPermisosUserModal'
import CompanyConceptModal from './Company/CompanyConceptModal'
import CompanyModal from './Company/CompanyModal'
import CompanyModalFirst from './Company/CompanyModalFirst'
import RoleModal from './Company/RoleModal'
import ProjectTypesModal from './Company/ProjectTypesModal'
import ReportTemplateModal from './Schedules/ReportTemplateModal'
import Schedule from './Schedules/Schedule'
import AgendaClosure from './Schedules/AgendaClosure'
import NewCrmContactModal from './CRM/NewCrmContactModal'
import NewPreProjectModal from './PreProject/NewPreProjectModal'
import BudgetModal from './Budgets/BudgetModal'
import NewBudgetSelectModal from './Budgets/NewBudgetSelectModal'
import BudgetConceptsModal from './Budgets/BudgetConceptsModal'
import BudgetCategoryModal from './Budgets/BudgetCategoryModal'
import BudgetDetailModal from './Budgets/BudgetDetailModal'
import BudgetNoteTemplateModal from './Budgets/BudgetNoteTemplateModal'
import UserBillingInfoModal from './General/UserBillingInfoModal'
import EditMyUserData from './Users/EditMyUserData'
import AddressModal from './General/AddressModal'
import SocialProfile from './SocialProfiles/SocialProfile'
import ProjectModal from './Projects/ProjectModal'
import ContractCategoryModal from './Company/ContractCategoryModal'
import ContractSectionModal from './Company/ContractSectionModal'
import CreateContractModal from './Contracts/CreateContractModal'

export default {
	name: 'GlobalModalsIndex',
	components: {
		NewUserModal,
		EditPermisosUserModal,
		CompanyModal,
		CompanyConceptModal,
		RoleModal,
		ReportTemplateModal,
		Schedule,
		AgendaClosure,
		NewCrmContactModal,
		CompanyModalFirst,
		ProjectTypesModal,
		NewPreProjectModal,
		BudgetModal,
		NewBudgetSelectModal,
		BudgetConceptsModal,
		BudgetCategoryModal,
		BudgetDetailModal,
		BudgetNoteTemplateModal,
		UserBillingInfoModal,
		AddressModal,
		EditMyUserData,
		SocialProfile,
		ProjectModal,
		ContractCategoryModal,
		ContractSectionModal,
		CreateContractModal
	},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>
