<template>
	<v-menu
		v-model="menuOpen"
		bottom
		offset-y
		left
		min-width="500"
		max-width="500"
		content-class="container-notification-menu-header"
		:close-on-click="false"
		:close-on-content-click="false"
		@input="changeMenu"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				icon
				dark
				small
				color="#959595"
				class="mr-2"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon dark>mdi-cog-outline</v-icon>
			</v-btn>
		</template>
		<div class="white" style="position: relative;">
			<v-btn
				icon
				dark
				x-small
				color="white"
				style="position:absolute;top: 5px; right: 5px;z-index: 9999;"
				@click="changeMenu(false)"
			>
				<v-icon dark>mdi-close</v-icon>
			</v-btn>
			<v-tabs
				v-model="tab"
				background-color="#524D4D"
				grow
				dark
				height="40"
			>
				<v-tab>Etapas</v-tab>
				<v-tab>Conceptos</v-tab>
				<v-tab>Detalles</v-tab>
				<v-tab>Notas</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<div>
						<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-4">
							<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Etapas para Presupuesto</p>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="grey" class="mr-3"
												v-bind="attrs"
												v-on="on"
												small
												@click="setBudgetCategory(null)">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Nueva etapa para Presupuesto</span>
							</v-tooltip>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
							<v-list-item
								v-for="(item, i) in $store.state.budget.companiesBudgetCategories"
								:key="i"
								class="px-0 py-0"
							>
								<div style="width: 100%">
									<div class="d-flex flex-row px-4 pt-2 justify-space-between">
										<div class="px-4 caption">
											<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.name }}</p>
										</div>
										<div>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="warning"
														@click="setBudgetCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-pencil</v-icon>
													</v-btn>
												</template>
												<span>Editar Etapa</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="error"
														@click="deleteBudgetCategory(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-delete-outline</v-icon>
													</v-btn>
												</template>
												<span>Eliminar Etapa</span>
											</v-tooltip>
										</div>
									</div>
									<v-divider class="mt-2 mx-2"></v-divider>
								</div>
							</v-list-item>
						</v-list>
					</div>
				</v-tab-item>
				<v-tab-item>
					<div>
						<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-4">
							<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Conceptos para Presupuesto</p>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="grey" class="mr-3"
												v-bind="attrs"
												v-on="on"
												small
												@click="setBudgetConcept(null)">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Nuevo concepto para Presupuesto</span>
							</v-tooltip>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
							<v-list-item
								v-for="(item, i) in $store.state.budget.companiesBudgetConcepts"
								:key="i"
								class="px-0 py-0"
							>
								<div style="width: 100%">
									<div class="d-flex flex-row px-4 pt-2 justify-space-between">
										<div class="px-4 caption">
											<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.name }}</p>
										</div>
										<div>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="warning"
														@click="setBudgetConcept(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-pencil</v-icon>
													</v-btn>
												</template>
												<span>Editar Concepto</span>
											</v-tooltip>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														icon
														dark
														x-small
														color="error"
														@click="deleteBugdetConcept(item)"
														v-bind="attrs"
														v-on="on"
													>
														<v-icon small dark>mdi-delete-outline</v-icon>
													</v-btn>
												</template>
												<span>Eliminar Concepto</span>
											</v-tooltip>
										</div>
									</div>
									<v-divider class="mt-2 mx-2"></v-divider>
								</div>
							</v-list-item>
						</v-list>
					</div>
				</v-tab-item>
				<v-tab-item>
					<div>
						<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-4">
							<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Detalles para Presupuesto</p>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="grey" class="mr-3"
												v-bind="attrs"
												v-on="on"
												small
												@click="setBudgetDetail(null)">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Nuevo detalle para Presupuesto</span>
							</v-tooltip>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
							<draggable
								v-model="$store.state.budget.companiesBudgetDetails"
								handle=".handle_drag"
								@end="startEndDragDetailsEvent">
								<v-list-item
									v-for="(item, i) in $store.state.budget.companiesBudgetDetails"
									:key="i"
									class="px-0 py-0"
								>
									<div style="width: 100%">
										<div class="d-flex flex-row px-4 pt-2 justify-space-between">
											<div class="d-flex flex-row align-center caption">
												<v-icon class="handle_drag mr-2">mdi-unfold-more-horizontal</v-icon>
												<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.name }}</p>
											</div>
											<div>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															dark
															x-small
															color="warning"
															@click="item.company_project_type ? editProjectType(item.company_project_type):setBudgetDetail(item)"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small dark>mdi-pencil</v-icon>
														</v-btn>
													</template>
													<span>Editar Detalle</span>
												</v-tooltip>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															dark
															x-small
															color="error"
															@click="deleteBudgetDetail(item)"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small dark>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Detalle</span>
												</v-tooltip>
											</div>
										</div>
										<v-divider class="mt-2 mx-2"></v-divider>
									</div>
								</v-list-item>
							</draggable>
						</v-list>
					</div>
				</v-tab-item>
				<v-tab-item>
					<div>
						<div class="d-flex flex-row align-center justify-space-between my-2 mb-0 px-4">
							<p class="mb-0 font-weight-bold body-2" style="color: #524D4D">Notas para Presupuesto</p>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="grey" class="mr-3"
												v-bind="attrs"
												v-on="on"
												small
												@click="setBudgetNote(null)">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Nueva nota para Presupuesto</span>
							</v-tooltip>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-list class="pt-0" style="max-height: 300px;overflow: scroll;">
							<draggable
								v-model="$store.state.budget.companiesBudgetNotes"
								handle=".handle_drag"
								@end="startEndDragNotesEvent">
								<v-list-item
									v-for="(item, i) in $store.state.budget.companiesBudgetNotes"
									:key="i"
									class="px-0 py-0"
								>
									<div style="width: 100%">
										<div class="d-flex flex-row px-4 pt-2 justify-space-between">
											<div class="d-flex flex-row align-center caption">
												<v-icon class="handle_drag mr-2">mdi-unfold-more-horizontal</v-icon>
												<p class="mb-0 caption font-weight-bold" style="color: #707070;">{{ item.name }}</p>
											</div>
											<div>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															dark
															x-small
															color="warning"
															@click="setBudgetNote(item)"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small dark>mdi-pencil</v-icon>
														</v-btn>
													</template>
													<span>Editar Plantilla</span>
												</v-tooltip>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															dark
															x-small
															color="error"
															@click="deleteBudgetNoteTemplate(item)"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small dark>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Plantilla</span>
												</v-tooltip>
											</div>
										</div>
										<v-divider class="mt-2 mx-2"></v-divider>
									</div>
								</v-list-item>
							</draggable>
						</v-list>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</div>
		<v-divider class="mx-2"></v-divider>
	</v-menu>
</template>

<script>
import draggable from 'vuedraggable'
import _debounce from 'lodash/debounce'
import {removeBudgetConcept, removeBudgetCompanyCategory, removeBudgetCompanyDetail, updateOrderBudgetDetail, updateOrderBudgetNoteTemplate, deleteBudgetNoteTemplate} from '@/helpers/api/budgets'
export default {
  name: 'BudgetSettings',
	components: {draggable},
  data: () => ({
		menuOpen: false,
		tab: null,
    validform: true,
		loadingData: false,
    newBudgetConcept: {
			budget_concept_id: null,
			name: null
    },
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
  mounted(){
		if(this.$store.state.budget.newBudgetConceptModalEditing){
			this.newBudgetConcept = this.$store.state.budget.newBudgetConceptModalEditing
		}
  },
  methods: {
    editProjectType(project_type) {
			const data = {
				project_type_id: project_type.id,
				name: project_type.name,
				description: project_type.description,
				code: project_type.code
			}
			this.$store.commit('companies/setNewProjectTypeModalEditing', data)
			this.$store.commit('companies/setNewProjectTypeModal', true)
    },
		startEndDragDetailsEvent: _debounce(function(){ this.endDragDetailEvent() }, 500),
		async endDragDetailEvent(){
			const details = this.$store.state.budget.companiesBudgetDetails.map((detail, index) => {
				return {id: detail.id, order: index + 1}
			})
			await updateOrderBudgetDetail({details})
			this.$store.dispatch('budget/getBudgetDetails')
		},
		startEndDragNotesEvent: _debounce(function(){ this.endDragNotesEvent() }, 500),
		async endDragNotesEvent(){
			const notes = this.$store.state.budget.companiesBudgetNotes.map((note, index) => {
				return {id: note.id, order: index + 1}
			})
			await updateOrderBudgetNoteTemplate({notes})
			this.$store.dispatch('budget/getBudgetNotes')
		},
		changeMenu(value){
			this.menuOpen = value
		},
		setBudgetCategory(category = null){
			this.$store.commit('budget/setNewBudgetCategoryModalEditing', category ? {...category, ...{category_id: category.id}}:null)
      this.$store.commit('budget/setNewBudgetCategoryModal', true)
		},
		setBudgetConcept(concept = null){
			this.$store.commit('budget/setNewBudgetConceptModalEditing', concept ? {...concept, ...{budget_concept_id: concept.id}}:null)
      this.$store.commit('budget/setNewBudgetConceptModal', true)
		},
		setBudgetDetail(detail = null){
			this.$store.commit('budget/setNewBudgetDetailModalEditing', detail ? {...detail, ...{detail_id: detail.id}}:null)
      this.$store.commit('budget/setNewBudgetDetailModal', true)
		},
		setBudgetNote(note = null){
			this.$store.commit('budget/setNewBudgetNoteTemplateModalEditing', note ? {...note, ...{budget_note_id: note.id}}:null)
      this.$store.commit('budget/setNewBudgetNoteTemplateModal', true)
		},
		async deleteBugdetConcept(concept){
			await removeBudgetConcept({budget_concept_id: concept.id})
			this.$store.dispatch('budget/getBudgetConcepts')
		},
		async deleteBudgetCategory(category){
			await removeBudgetCompanyCategory({category_id: category.id})
			this.$store.dispatch('budget/getBudgetCategories')
		},
		async deleteBudgetDetail(detail){
			await removeBudgetCompanyDetail({detail_id: detail.id})
			this.$store.dispatch('budget/getBudgetDetails')
		},
		async deleteBudgetNoteTemplate(note){
			await deleteBudgetNoteTemplate({budget_note_id: note.id})
			this.$store.dispatch('budget/getBudgetNotes')
		},
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
	.handle_drag{
		cursor: move;
	}
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>