import {endpoints} from './endpoints'
import request from '../request'

export function createCostCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCostSubcategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostSubcategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createCostItem (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createCostItem,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCostCategories (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCostCategories,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCostCategory (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCostCategory,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function saveAllCostCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.saveAllCostCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostCategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostSubcategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostSubcategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteCostItem (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteCostItem,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}