<template>
<v-container fluid>
  <div>
    <vue-file-toolbar-menu :content="menu" class="bar" />
    <vue-document-editor
			id="contract-editor"
			ref="textEditor"
			:content.sync="testingContent"
      :zoom="zoom"
      :page_format_mm="page_format_mm"
      :page_margins="page_margins"
			:overlay="overlay"
      :display="display"/>
  </div>
</v-container>
</template>

<script>
import VueFileToolbarMenu from 'vue-file-toolbar-menu';
import VueDocumentEditor from 'vue-document-editor'
import { createContractPdfFromPreview } from '@/helpers/api/contracts'

export default {
	name: 'TextEditor',
	components: { VueDocumentEditor, VueFileToolbarMenu },
	filters: {
	},
	props: {
		contract: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		testingContent: [],
		zoom: 0.8,
		zoom_min: 0.10,
		zoom_max: 5.0,
		page_format_mm: [216, 279],
		page_margins: "18mm 18mm 25mm 30mm",
		display: "grid",
		font: "Archivo Narrow",
		font_size: '12pt',
		mounted: false
	}),
	computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		menu () {
      return [
        { text: "Convertir a PDF", title: "PDF", icon: "picture_as_pdf", click: () => { this.contractToPdf() } },
        { is: "spacer" },
        { icon: "format_align_left", title: "Align left", active: this.isLeftAligned, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+l" : "ctrl+shift+l", click: () => document.execCommand("justifyLeft") },
        { icon: "format_align_center", title: "Align center", active: this.isCentered, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+e" : "ctrl+shift+e", click: () => document.execCommand("justifyCenter") },
        { icon: "format_align_right", title: "Align right", active: this.isRightAligned, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+r" : "ctrl+shift+r", click: () => document.execCommand("justifyRight") },
        { icon: "format_align_justify", title: "Justify content", active: this.isJustified, disabled: !this.current_text_style, hotkey: this.isMacLike ? "shift+command+j" : "ctrl+shift+j", click: () => document.execCommand("justifyFull") },
        { is: "separator" },
        { icon: "format_bold", title: "Bold", active: this.isBold, disabled: !this.current_text_style, hotkey: this.isMacLike ? "command+b" : "ctrl+b", click: () => document.execCommand("bold") },
        { icon: "format_italic", title: "Italic", active: this.isItalic, disabled: !this.current_text_style, hotkey: this.isMacLike ? "command+i" : "ctrl+i", click: () => document.execCommand("italic") },
        { icon: "format_underline", title: "Underline", active: this.isUnderline, disabled: !this.current_text_style, hotkey: this.isMacLike ? "command+u" : "ctrl+u", click: () => document.execCommand("underline") },
        { icon: "format_strikethrough", title: "Strike through", active: this.isStrikeThrough, disabled: !this.current_text_style, click: () => document.execCommand("strikethrough") },
        { is: "button-color", type: "compact", menu_class: "align-center", disabled: !this.current_text_style, color: this.curColor, update_color: (new_color) => document.execCommand('foreColor', false, new_color.hex8) },
        { is: "button-color", icon: "format_strikethrough", type: "compact", menu_class: "align-center", disabled: !this.current_text_style, color: this.backColor, update_color: (new_color) => document.execCommand('hiliteColor', false, new_color.hex8) },
				{
					html: '<div class="ellipsis" style="width: 120px; font-size: 95%;">'+this.font+'</div>',
					title: "Font",
					chevron: true,
					menu: this.font_menu,
					menu_height: 200
				},
				{
					html: '<div class="ellipsis" style="width: 40px; font-size: 95%;">'+this.font_size+'</div>',
					title: "Font Size",
					chevron: true,
					menu: this.font_size_menu,
          menu_width: 80,
					menu_height: 200
				},
        { is: "spacer" },
        { icon: "format_list_numbered", title: "Numbered list", active: this.isNumberedList, disabled: !this.current_text_style, click: () => document.execCommand("insertOrderedList") },
        { icon: "format_list_bulleted", title: "Bulleted list", active: this.isBulletedList, disabled: !this.current_text_style, click: () => document.execCommand("insertUnorderedList") },
        { html: "<b>H1</b>", title: "Header 1", active: this.isH1, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h1>') },
        { html: "<b>H2</b>", title: "Header 2", active: this.isH2, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h2>') },
        { html: "<b>H3</b>", title: "Header 3", active: this.isH3, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h3>') },
        { icon: "format_clear", title: "Clear format", disabled: !this.current_text_style, click () { document.execCommand('removeFormat'); document.execCommand('formatBlock', false, '<div>'); } },
        
        { is: "spacer" },

        { // Format menu
          text: this.current_format_name,
          title: "Format",
          icon: "crop_free",
          chevron: true,
          menu: this.formats.map(([text, w, h]) => {
            return {
              text,
              active: (this.page_format_mm[0] == w && this.page_format_mm[1] == h),
              click: () => { this.page_format_mm = [w, h]; }
            }
          }),
          menu_width: 80,
          menu_height: 280
        },
        // { // Margins menu
        //   text: this.current_margins_name,
        //   title: "Margins",
        //   icon: "select_all",
        //   chevron: true,
        //   menu: this.margins.map(([text, value]) => {
        //     return {
        //       text: text+" ("+value+")",
        //       active: (this.page_margins == value),
        //       click: () => { this.page_margins = value; }
        //     }
        //   }),
        //   menu_width: 200,
        //   menu_class: "align-center"
        // },
        { // Zoom menu
          text: Math.floor(this.zoom * 100) + "%",
          title: "Zoom",
          icon: "zoom_in",
          chevron: true,
          menu: [
            ["200%", 2.0],
            ["150%", 1.5],
            ["125%", 1.25],
            ["100%", 1.0],
            ["75%", 0.75],
            ["50%", 0.5],
            ["25%", 0.25]
          ].map(([text, zoom]) => {
            return {
              text,
              active: this.zoom == zoom,
              click: () => { this.zoom = zoom; }
            }
          }),
          menu_width: 80,
          menu_height: 280,
          menu_class: "align-center"
        },
        // { // Display mode menu
        //   title: "Display",
        //   icon: this.display == "horizontal" ? "view_column" : (this.display == "vertical" ? "view_stream" : "view_module"),
        //   chevron: true,
        //   menu: [{
        //     icon: "view_module",
        //     active: this.display == "grid",
        //     click: () => { this.display = "grid"; }
        //   }, {
        //     icon: "view_column",
        //     active: this.display == "horizontal",
        //     click: () => { this.display = "horizontal"; }
        //   }, {
        //     icon: "view_stream",
        //     active: this.display == "vertical",
        //     click: () => { this.display = "vertical"; }
        //   }],
        //   menu_width: 55,
        //   menu_class: "align-right"
        // }
      ]
    },
		current_format_name () {
			const format = this.formats.find(([, width_mm, height_mm]) => (this.page_format_mm[0] == width_mm && this.page_format_mm[1] == height_mm));
			return format ? format[0] : (this.page_format_mm[0]+"mm x "+this.page_format_mm[1]+"mm");
		},
		formats: () => [
			["Carta", 216, 279]
		],
		current_margins_name () {
			const margins = this.margins.find(([, margins]) => (this.page_margins == margins));
			return margins ? margins[0] : this.page_margins;
		},
		margins: () => [
			["Margen Pequeño", "5mm"],
			["Margen Normal", "15mm"],
			["Margen Medio", "20mm"],
			["Margen Angosto", "10mm 15mm"]
		],
		current_text_style () { return this.mounted ? this.$refs.textEditor.current_text_style : false; },
		isLeftAligned () { return ["start", "left", "-moz-left"].includes(this.current_text_style.textAlign); },
		isRightAligned () { return ["end", "right", "-moz-right"].includes(this.current_text_style.textAlign); },
		isCentered () { return ["center", "-moz-center"].includes(this.current_text_style.textAlign); },
		isJustified () { return ["justify", "justify-all"].includes(this.current_text_style.textAlign); },
		isBold () {
			const fontWeight = this.current_text_style.fontWeight;
			return fontWeight && (parseInt(fontWeight) > 400 || fontWeight.indexOf("bold") == 0);
		},
		isItalic () { return this.current_text_style.fontStyle == "italic"; },
		isUnderline () { // text-decoration is not overridden by children, so we query the parent stack
			const stack = this.current_text_style.textDecorationStack;
			return stack && stack.some(d => (d.indexOf("underline") == 0));
		},
		isStrikeThrough () { // text-decoration is not overridden by children, so we query the parent stack
			const stack = this.current_text_style.textDecorationStack;
			return stack && stack.some(d => (d.indexOf("line-through") == 0));
		},
		isNumberedList () { return this.current_text_style.isList && this.current_text_style.listStyleType == "decimal"; },
		isBulletedList () { return this.current_text_style.isList && ["disc", "circle"].includes(this.current_text_style.listStyleType); },
		isH1 () { return this.current_text_style.headerLevel == 1; },
		isH2 () { return this.current_text_style.headerLevel == 2; },
		isH3 () { return this.current_text_style.headerLevel == 3; },
		curColor () { return this.current_text_style.color || "transparent"; },
		backColor () { return this.current_text_style.backgroundColor || "transparent"; },
		isMacLike: () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
    font_menu () {
      return this.font_list.map(font => {
        return {
          html: '<span class="ellipsis" style="padding:2px;font-family:'+font+'">'+font+'</span>',
          icon: (this.theme != "default" && this.font == font) ? 'check' : false,
          active: (this.font == font),
          height: 20,
          click: () => {
            document.execCommand("fontName", false, font);
            this.font = font;
          }
        };
      });
    },
    font_list: () => ["Archivo Narrow","Arial", "Avenir", "Courier New", "Garamond", "Georgia", "Impact", "Helvetica", "Palatino", "Roboto", "Times New Roman", "Verdana"],
    font_size_menu () {
			let fontSizes = []
			for (let fontSize = 1; fontSize <= 40; fontSize++) {
				const size_str = fontSize + 'pt'
				fontSizes.push({
          html: '<span class="ellipsis">'+size_str+'</span>',
          icon: (this.theme != "default" && this.font == size_str) ? 'check' : false,
          active: (this.font_size == size_str),
          height: 20,
          click: () => {
            // document.execCommand("formatBlock", false, '<span>');
						// let fontElements = window.getSelection()
						// console.log(fontElements)
						// fontElements.style.fontSize = size_str;
            this.font_size = size_str;
          }
        })
			}
			return fontSizes
    }

	},
	watch: {
		selectedCategory(newValue){
			if(newValue){
				this.$store.dispatch('contracts/getCompanyContractsConfig', {category_id: this.selectedCategory.id, start: 0, limit: 100})
			}
		}
	},
	mounted(){
		this.setContent()
	},
	methods: {
		async contractToPdf(){
			const data = {
				contract_id: this.contract.id,
				contents: JSON.stringify( this.$refs.textEditor.pages.map(page => page.prev_innerHTML) )
			}
			const pdf = await createContractPdfFromPreview(data)
			if(pdf.code == 200){
				window.open(pdf.url);
			}
		},
    overlay () {
      let html = `
					<div class="contract-footer" style="margin: 0 18mm 0 30mm">
						<div class="company-footer-brand">
							<span style="color: #FE0000">TOLEDO</span><span style="color: #939393">ESTUDIO</span><span style="color: #504F4D">ARQUITECTOS</span>
						</div>
						<div class="company-contact">
							<p class="company-first-line"><b>CONTACTO:</b> ${this.selectedCompany.company.phone} - ${this.selectedCompany.company.email} - ${this.selectedCompany.company.address}, ${this.selectedCompany.company.city.name}, ${this.selectedCompany.company.city.country.name}</p>
							<a class="company-second-line" href="${this.selectedCompany.company.url}" target="_blank">${this.selectedCompany.company.url.replace('https://', '').replace('http://', '')}</a>
						</div>
					</div>`
      return html;
    },
		setContent(){
			let start_date = 'A Convenir'
			if(this.contract.start_date){
				start_date = this.$options.filters.toDate(this.contract.start_date)
			}
			let content_final = `
					<div class="d-flex justify-space-between mb-4" style="font-size: 12px;">
						<div class="d-flex flex-grow-1">
							<img src="${this.selectedCompany.company.logo}" style="width: 120px;height: 120px;margin-right: 5px;"/>
							<div>
								<p class="font-weight-bold my-0" style="font-size: 16px;">${this.selectedCompany.company.name}</p>
								<p class="caption my-0">${this.selectedCompany.company.description}</p>
								<p class="caption my-0">Rut: ${this.$options.filters.formatRut(this.selectedCompany.company.rut)}</p>
								<p class="caption my-0">${this.selectedCompany.company.phone}</p>
								<p class="caption my-0">${this.selectedCompany.company.email}</p>
							</div>
						</div>
						<div>
							<div class="d-flex justify-space-between caption">
								<div style="width: 120px">Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">${ this.contract.code } - ${ this.$options.filters.toYear(this.contract.created_at) }</b>
							</div>
							<div class="d-flex justify-space-between caption">
								<div style="width: 120px">Fecha Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">${ this.$options.filters.toDate(this.contract.created_at) }</b>
							</div>
							<div class="d-flex justify-space-between caption">
								<div style="width: 120px">Fecha Inicio</div><span>:</span>
								<b class="text-end" style="flex: 1;">
									${start_date}
								</b>
							</div>
						</div>
					</div>`

			this.contract.sections.forEach((section, index) => {
				if(section.section_type == 1){
					content_final += '<p style="font-weight: bold;text-align:center;">' + section.title + '</p>'
				}
				else{
					content_final += '<p style="font-weight: bold;">' + this.sectionIndex(index) + ' ' + section.title + '</p>'
				}
				section.contents.forEach(content => {
					if(section.show_title){
						content_final += '<p style="font-weight: bold;">' + content.title + '</p>'
					}
					content_final += content.content
				});
			})
			this.testingContent = [content_final]
			this.$nextTick(() => {
				console.log('Pages: ', this.$refs.textEditor.pages)
				console.log('Editor: ', this.$refs.textEditor)
				this.mounted = true
			})
		},
		sectionIndex(index){
			return '' +  this.$store.state.general.romanNumerals[index] + '. ' + this.$store.state.general.ordinalsNumbers[index] + ':'
		},
	}
};
</script>

<style>
	#contract-editor{
		font-size: 10pt;
		text-transform: none;
	}
	.contract-footer{
		position: absolute;
		bottom: 7mm;
		left: 0;
		right: 0;
	}
	.company-footer-brand{
		background-color: #BFBFBF;
		font-weight: bold;
		text-align: center;
	}
	.contract-footer .company-contact{
			text-align: center;
		}
	.contract-footer .company-first-line{
			margin: 0;
			padding-top: 2pt;
			font-size: 10pt;
			line-height: 10pt;
			text-align:center;
		}
	.contract-footer .company-second-line{
			margin: 0;
			text-align:center;
			font-size: 10pt;
			line-height: 10pt;
			width: 100%;
			font-weight: bold;
			color: #504F4D;
			text-decoration: none;
		}
</style>