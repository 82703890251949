<template>
  <div class="text-center">
    <v-dialog
      v-model="modalStatus"
			content-class="budget-modal-custom"
      scrollable
    >
			<v-card :loading="loadingBudget" :disabled="loadingBudget" class="budget-modal-card">
				<v-card-text class="py-2">
					<div class="d-flex align-center justify-space-between">
						<div class="d-flex flex-column">
							<p class="body-2 mb-0 mr-2"><b>Detalle Presupuesto</b> - {{getTypeName(budget.morphable_type)}}: {{budget.morphable.name}}</p>
							<p class="body-2 mb-0 mr-2"><b>Cliente:</b> {{budget.morphable.client.profile.name}} {{budget.morphable.client.profile.lastname}} - {{budget.morphable.client.email}}</p>
						</div>
						<div>
							<BudgetSettings />
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										icon
										dark
										small
										color="#959595"
										@click="$store.commit('general/setNewBudgetModal', null)"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon dark>mdi-close-outline</v-icon>
									</v-btn>
								</template>
								<span>Cerrar</span>
							</v-tooltip>
						</div>
					</div>
					<v-divider class="my-2"></v-divider>
					<div>
						<div>
							<!-- <v-textarea
								v-model="budget.description"
								label="Detalle"
								placeholder="Detalle (Opcional)"
								outlined
								required
								rows="2"
								dense
							></v-textarea> -->
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left" style="width: 35%;">
												Concepto
											</th>
											<th class="text-left" style="width: 30%;">
												Detalle
											</th>
											<th class="text-left" style="width: 15%;min-width: 120px;">
												UF
											</th>
											<th class="text-right" style="width: 10%;min-width: 130px;">
												Pesos
											</th>
											<th class="text-center" style="width: 5%;min-width: 85px;">
												Acciones
											</th>
										</tr>
									</thead>
									<tbody
											v-for="( budget_category, index ) in budget?.categories"
											:key="'cat' + index">
										<tr>
											<td colspan="5">
												<div class="d-flex align-center" v-if="budget_category.edit">
													<v-text-field
														v-model="budget_category.number"
														label="Etapa"
														outlined
														required
														dense
														hide-details
														:loading="loadingBudget"
														class="custom-height-search custom-height-calendar mr-2"
														style="max-width: 90px"
														type="number"
														@keypress="isNumberKey"
														:rules="requiredRule"
													></v-text-field>
													<v-select
														v-model="budget_category.name"
														:items="$store.state.budget.companiesBudgetCategories"
														:loading="$store.state.budget.loadingBudgetCategories"
														:menu-props="{ top: true, offsetY: true }"
														item-text="name"
														item-value="name"
														label="Descripción"
														class="custom-height-search custom-height-calendar"
														outlined
														dense
														required
													>
													</v-select>
													<!-- <v-text-field
														v-model="budget_category.name"
														label="Descripción"
														outlined
														required
														dense
														hide-details
														:loading="loadingBudget"
														class="custom-height-search custom-height-calendar"
														style="max-width: 100%"
														:rules="requiredRule"
													></v-text-field> -->
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																icon
																dark
																x-small
																color="success"
																class="mx-4"
																@click="sendEditBugdetPartCategory(budget_category, index)"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon small dark>mdi-check-outline</v-icon>
															</v-btn>
														</template>
														<span>Editar categoría</span>
													</v-tooltip>
												</div>
												<div class="d-flex align-center justify-space-between py-2 font-weight-bold text--secondary" v-else>
													{{budget_category.number}}º Etapa - {{budget_category.name}}
													<div class="d-flex align-center">
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	icon
																	dark
																	x-small
																	color="warning"
																	class="mx-2"
																	@click="editBudgetCategory(index, true)"
																	v-bind="attrs"
																	v-on="on"
																>
																	<v-icon small dark>mdi-pencil</v-icon>
																</v-btn>
															</template>
															<span>Editar categoría</span>
														</v-tooltip>
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	icon
																	dark
																	x-small
																	color="error"
																	@click="removeCategory(budget_category.id)"
																	v-bind="attrs"
																	v-on="on"
																>
																	<v-icon small dark>mdi-delete-outline</v-icon>
																</v-btn>
															</template>
															<span>Eliminar categoría</span>
														</v-tooltip>
													</div>
												</div>
											</td>
										</tr>
										<tr
											v-for="( budget_part, index_b ) in budget_category.parts"
											:key="'part' + index_b">
											<td class="text-left">
												<v-form ref="formconcept_filled" style="width: 100%;">
													<v-select
														v-model="budget_part.concept"
														:items="$store.state.budget.companiesBudgetConcepts"
														:loading="$store.state.budget.loadingBudgetConcepts || loadingBudget"
														:menu-props="{ top: true, offsetY: true }"
														item-text="name"
														item-value="name"
														label="Concepto"
														class="custom-height-search custom-height-calendar"
														outlined
														dense
														style="font-size: 14px;max-width: 360px;"
														@change="autoConfirm"
														required
													>
													</v-select>
												</v-form>
											</td>
											<td class="text-left">
												<v-form ref="formconcept_filled" style="width: 100%;">
													<v-select
														v-model="budget_part.detail"
														:items="$store.state.budget.companiesBudgetDetails"
														:loading="$store.state.budget.loadingBudgetDetails || loadingBudget"
														:menu-props="{ top: true, offsetY: true }"
														item-text="name"
														item-value="name"
														label="Detalle"
														class="custom-height-search custom-height-calendar"
														outlined
														dense
														style="font-size: 14px;max-width: 300px;"
														@change="autoConfirm"
														required
													>
													</v-select>
												</v-form>
											</td>
											<td class="text-right">
												<div class="d-flex align-center justify-end">
													<v-form ref="formvalue" style="width: 100%;">
														<v-text-field
															v-model="budget_part.value"
															label="Monto (UF)"
															outlined
															required
															dense
															hide-details
															:loading="loadingBudget"
															class="custom-height-search custom-height-calendar"
															style="font-size: 14px;"
															@keypress="isNumberKey"
															@input="autoConfirm"
															:rules="requiredRule"
														></v-text-field>
													</v-form>
												</div>
											</td>
											<td class="text-right">
												${{Math.round(budget_part.value * ufValue) | toThousandFilter}}
												<small><b>+ IVA</b></small>
											</td>
											<td class="text-center">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															dark
															x-small
															color="error"
															@click="deleteBugdetPart(budget_part, index, index_b)"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small dark>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Item</span>
												</v-tooltip>
											</td>
										</tr>
										<tr>
											<td colspan="5">
												<div class="d-flex align-center">
													<v-btn
														depressed
														color="#959595"
														class="my-2 flex-grow-1 mx-1"
														small
														outlined
														@click="addNewItem(budget_category.id, index)"
													>
														<v-icon>mdi-plus</v-icon>
														<p class="mb-0 ml-2 caption">Agregar Item a Etapa</p>
													</v-btn>
												</div>
											</td>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td colspan="5">
												<v-divider class="my-2"></v-divider>
												<v-btn
													depressed
													color="#959595"
													class="my-2 flex-grow-0 mx-1"
													small
													outlined
													block
													@click="addNewItem()"
												>
													<v-icon>mdi-plus</v-icon>
													<p class="mb-0 ml-2 caption">Agregar Etapa</p>
												</v-btn>
											</td>
										</tr>
										<tr v-if="budget">
											<td colspan="2" class="text-left font-weight-bold">
												Presupuesto Total
											</td>
											<td class="text-right">
												{{budget_total | toThousandFilter}}
												<small><b>+ IVA</b></small>
											</td>
											<td class="text-right">
												${{Math.round(budget_total * ufValue) | toThousandFilter}}
												<small><b>+ IVA</b></small>
											</td>
											<td>
											</td>
										</tr>
										<tr v-else>
											<td colspan="5" class="text-center">
												<div class="pt-2">
													<p class="mb-1">Aún no hay items para este presupuesto, agrega uno para comenzar</p>
													<v-btn
														depressed
														color="#524D4D"
														small
														outlined
														@click="addNewItem()"
													>
														<v-icon>mdi-plus</v-icon>
														<p class="mb-0 ml-2 caption">Agregar Item</p>
													</v-btn>
												</div>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
							<v-divider class="my-2"></v-divider>
							<div>
								<div class="d-flex align-center justify-space-between my-2">
									<div class="d-flex align-center">
										<p class="font-weight-bold body-2 mb-0 mr-2">Notas del Presupuesto</p>
									</div>
									<div>
										<v-menu
											v-model="menuNotesOpen"
											top
											offset-x
											left
											min-width="500"
											max-width="500"
											content-class="container-notification-menu-header"
											:close-on-click="false"
											:close-on-content-click="false"
											@input="changeMenu"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													icon
													dark
													small
													color="#959595"
													class="mr-2"
													v-bind="attrs"
													v-on="on"
												>
													<v-icon dark>mdi-plus</v-icon>
												</v-btn>
											</template>
											<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
												<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
													<div class="d-flex justify-space-between w-100">
														<p class="caption mb-0 font-weight-bold white--text">Agregar Nota a Presupuesto</p>
														<v-btn
															icon
															dark
															x-small
															color="white"
															@click="changeMenu(false)"
														>
															<v-icon dark>mdi-close</v-icon>
														</v-btn>
													</div>
												</v-list-item>
												<v-list-item-group color="#524D4D">
													<v-list-item
														dense
														@click="addNote(null)"
													>
														<v-list-item-title dense>
															Nueva (Vacía)
														</v-list-item-title>
													</v-list-item>
													<v-list-item
														v-for="(note, index) in $store.state.budget.companiesBudgetNotes"
														:key="index"
														dense
														@click="addNote(note)"
													>
														<v-list-item-title dense>
															{{ note.name }}
														</v-list-item-title>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</v-menu>
									</div>
								</div>
								<div v-if="editNewNote" class="py-4">
									<div class="pa-2">
										<vue2-tinymce-editor
											v-model="newNote.note"
											:height="150"
											:options="{ menubar: false }"
											ref="tinymceEditor">
										</vue2-tinymce-editor>
									</div>
									<div class="d-flex justify-end">
										<v-btn
											depressed
											color="#959595"
											small
											dark
											outlined
											class="mr-2"
											@click="cancelEditNote"
										>
											<v-icon small>mdi-close</v-icon>
											<p class="mb-0 ml-2 caption">Cancelar Nota</p>
										</v-btn>
										<v-btn
											depressed
											color="#FF120B"
											small
											dark
											@click="acceptEditNote"
										>
											<v-icon small>mdi-check</v-icon>
											<p class="mb-0 ml-2 caption">Guardar Nota</p>
										</v-btn>
									</div>
									<v-divider class="my-2"></v-divider>
								</div>
								<v-list v-else dense class="py-0 mb-2" max-height="300" style="z-index: 9999;background-color: white;">
									<draggable
										v-if="budget"
										v-model="budget.notes"
										handle=".handle_drag"
										@end="startEndDragNotesEvent">
										<v-list-item
											v-for="(note, index) in budget?.notes"
											:key="index"
											dense
										>
											<v-list-item-title dense>
												<div style="width: 100%">
													<div class="d-flex flex-row px-0 pt-2 justify-space-between">
														<div class="d-flex flex-row align-center caption">
															<v-icon class="handle_drag mr-2">mdi-unfold-more-horizontal</v-icon>
															<div class="budget-note-container" v-html="note.note"></div>
														</div>
														<div>
															<v-tooltip bottom>
																<template v-slot:activator="{ on, attrs }">
																	<v-btn
																		icon
																		dark
																		x-small
																		color="warning"
																		v-bind="attrs"
																		v-on="on"
																		@click="editNote(note)"
																	>
																		<v-icon small dark>mdi-pencil</v-icon>
																	</v-btn>
																</template>
																<span>Editar Plantilla</span>
															</v-tooltip>
															<v-tooltip bottom>
																<template v-slot:activator="{ on, attrs }">
																	<v-btn
																		icon
																		dark
																		x-small
																		color="error"
																		v-bind="attrs"
																		v-on="on"
																	>
																		<v-icon small dark>mdi-delete-outline</v-icon>
																	</v-btn>
																</template>
																<span>Eliminar Plantilla</span>
															</v-tooltip>
														</div>
													</div>
													<v-divider class="mt-2 mx-2"></v-divider>
												</div>
											</v-list-item-title>
										</v-list-item>
									</draggable>
								</v-list>
							</div>
							<div class="d-flex justify-end">
								<v-btn
									depressed
									color="#959595"
									small
									dark
									outlined
									class="mr-2"
									@click="$store.commit('general/setNewBudgetModal', null)"
								>
									<v-icon small>mdi-close</v-icon>
									<p class="mb-0 ml-2 caption">Cancelar</p>
								</v-btn>
								<v-btn
									depressed
									color="#FF120B"
									small
									dark
									@click="confirmBudget(true, true)"
								>
									<v-icon small>mdi-check</v-icon>
									<p class="mb-0 ml-2 caption">Guardar</p>
								</v-btn>
							</div>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import _debounce from 'lodash/debounce'
import {setBudgetsPartCategory, removeBudgetPartCategory, setItemBudgetPart, addPreProjectBudgetsPart, removeItemBudgetPart, createBudgetNote, updateBudgetNote, updateOrderBudgetNote, getBudgetById} from '@/helpers/api/budgets'
import BudgetSettings from '@/components/Modals/Budgets/BudgetSettings'

export default {
	name: 'BudgetModal',
	components: {BudgetSettings, draggable, Vue2TinymceEditor},
	computed: {
		openBudgetModal(){
			return this.$store.state.general.newBudgetModal
		},
		modalStatus: {
			get() {
				return this.$store.state.general.newBudgetModal?.status;
			},
			set(value) {
				this.$store.commit('general/setNewBudgetModalStatus', value);
			},
		},
		budget_total(){
			return this.budget.categories
				.map(category => category.parts)
				.flat()
				.map(part => part.value)
				.reduce((acc, value) => acc + parseInt(value), 0)
		},
		item(){
			return this.$store.state.general.newBudgetModal.item
		},
		ufValue(){
			return this.$store.state.general.dayUfValue
		}
	},
	watch: {
		openBudgetModal(value){
			if(value){
				localStorage.setItem('budget_open', JSON.stringify(value))
			}
			else{
				localStorage.removeItem('budget_open')
			}
		},
	},
  data() {
    return {
			menuNotesOpen: false,
			menuConcepts: false,
			showNewBudgetPart: true,
			loadingBudget: false,
			concept: null,
			budget: null,
			editNewNote: false,
			newNote: {
				id: null,
				note: null
			},
			newBudgetPart: {
				value: null,
				stage: null,
				concept: null,
				detail: null,
				item_type: null,
				item_id: null
			},
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			concepts: [
				{ "id": 1, "name": "Proyecto de Arquitectura" },
				{ "id": 2, "name": "Proyecto de Ingeniería y Memoria de Cálculo Estructural" },
				{ "id": 3, "name": "Proyectos de Agua Potable y Alcantarillado + Certificación Sanitaria" },
				{ "id": 4, "name": "Proyecto de Electricidad + Certificación SEC" },
				{ "id": 5, "name": "Proyecto de Corrientes Débiles" },
				{ "id": 6, "name": "Proyecto de Gas + Certificación SEC" },
				{ "id": 7, "name": "Proyecto de Climatización" },
				{ "id": 8, "name": "Recepción Definitiva" },
				{ "id": 9, "name": "Otros" }
			]
		}
	},
	mounted(){
		this.newBudgetPart = {
			value: null,
			budget_category_id: null,
			concept: null,
			detail: null,
			item_type: this.item.item_type,
			item_id: this.item.item_id
		}
		this.budget = this.$store.state.general.newBudgetModal.budget
		if(this.$store.state.general.newBudgetModal){
			localStorage.setItem('budget_open', JSON.stringify(this.$store.state.general.newBudgetModal))
		}
	},
	beforeDestroy(){
		localStorage.removeItem('budget_open')
	},
	methods: {
		getTypeName(type){
			switch (type) {
				case "App\\Models\\BotConversation":
					return 'CRM'
				case "App\\Models\\PreProject":
					return 'PreProyecto'
				case "App\\Models\\Project":
					return 'Proyecto'
				default:
					break;
			}
			return 'Otro'
		},
		async getBudgetInfo(){
			const budget = await getBudgetById({budget_id: this.budget.id})
			if(budget.code == 200){
				this.budget = budget.budget
				const data = {
					status: true,
					item: this.item,
					budget: budget.budget
				}
				this.$store.commit('general/setNewBudgetModal', data)
			}
		},
		editNote(note){
			this.newNote = {
				id: note.id,
				note: note.note
			}
			this.editNewNote = true
		},
		cancelEditNote(){
			this.editNewNote = false
			this.newNote = {
				id: null,
				note: null
			}
		},
		async acceptEditNote(){
			if(this.newNote.id){
				console.log('ac')
				const data = {
					budget_note_id: this.newNote.id,
					note: this.newNote.note
				}
				await updateBudgetNote(data)
			}
			else{
				this.addNote({note: this.newNote.note})
			}
			this.cancelEditNote()
		},
		changeMenu(value){
			this.menuNotesOpen = value
		},
		async addNote(note = null){
			if(note){
				await createBudgetNote({
					budget_id: this.budget.id,
					note: note.note
				})
				this.getBudgetInfo()
			}
			else{
				this.newNote = {
					id: null,
					note: null
				}
				this.editNewNote = true
			}
			this.changeMenu(false)
		},
		startEndDragNotesEvent: _debounce(function(){ this.endDragNotesEvent() }, 500),
		async endDragNotesEvent(){
			const notes = this.budget.notes.map((note, index) => {
				return {id: note.id, order: index + 1}
			})
			await updateOrderBudgetNote({notes})
			this.getBudgetInfo()
		},
		autoConfirm: _debounce(function() { this.confirmBudget(false) }, 800),
		async confirmBudget(showLoader = true, final = false){
			this.loadingBudget = showLoader
			const parts = this.budget.categories.map(cat => 
				cat.parts.map(part => ({
					...part, 
					value: parseInt(part.value, 10) // Convierte el valor a entero
				}))
			).flat()
			const budget = await setItemBudgetPart({
				item_type: this.item.item_type,
				item_id: this.item.item_id,
				parts
			})
			if(budget.code == 200){
				this.budget = budget.budget
			}
			this.getParentInfo()
			this.loadingBudget = false
			if(final){
				if(budget.code == 200){
					const messageData = {
						message: 'Presupuesto guardado correctamente',
						title: 'Presupuesto Guardado',
						type: 'success',
						icon: 'mdi-check-circle',
						color: '#3EBFA3',
					}
					this.setMessage(messageData)
				}
				this.$store.commit('general/setNewBudgetModal', null)
			}
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
		async removeCategory(category_id){
			this.loadingBudget = true
			const budget = await removeBudgetPartCategory({category_id})
			if(budget.code == 200){
				this.budget = budget.budget
			}
			this.getParentInfo()
			this.loadingBudget = false
		},
		async sendEditBugdetPartCategory(budget_category, index){
			this.loadingBudget = true
			const category = await setBudgetsPartCategory({
				...{
					item_type: this.item.item_type,
					item_id: this.item.item_id,
				},
				...budget_category
			})
			if(category.code == 200){
				this.budget = category.budget
			}
			this.$set(this.budget.categories[index], 'edit', false);
			this.getParentInfo()
			this.loadingBudget = false
		},
		editBudgetCategory(index){
			this.$set(this.budget.categories[index], 'edit', true);
		},
		async addNewItem(category_id = null, index = null){
			if(!category_id){
				this.loadingBudget = true
				const index = this.budget?.categories ? this.budget.categories.length:0
				const category_number = this.budget && this.budget.categories ? index + 1:1
				let category_name = 'Nueva Etapa'
				
				const category = await setBudgetsPartCategory({
					item_type: this.item.item_type,
					item_id: this.item.item_id,
					number: category_number,
					name: category_name
				})
				if(category.code == 200){
					this.budget = category.budget
				}
				this.getParentInfo()
				this.loadingBudget = false
				this.$set(this.budget.categories[index], 'edit', true);
			}
			else if(index || index === 0){
				this.$set(
					this.budget.categories[index],
					'parts',
					[
						...this.budget.categories[index].parts,
						...[{
							value: null,
							concept: null,
							detail: null,
							budget_category_id: category_id,
							item_type: this.item.item_type,
							item_id: this.item.item_id
						}]
					]
				)
			}
		},
		handleConceptChange(value, index) {
			if (value.name === 'Otros') {
				this.$set(this.budget[index], 'concept', '');
			}
			this.autoConfirm()
		},
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
		async addNewPreProjectBudgetsPart(){
			this.newBudgetPart.value = parseInt(this.newBudgetPart.value)
			const data = {...this.newBudgetPart}
			this.loadingBudget = true
			this.newBudgetPart = {
				value: null,
				stage: null,
				concept: null,
				detail: null,
				item_type: this.item.item_type,
				item_id: this.item.item_id,
			}
			this.$refs.formconcept.reset()
			this.$refs.formvalue.reset()
			const budget = await addPreProjectBudgetsPart(data)
			if(budget.code == 200){
				const data = {
					status: true,
					item: this.item,
					budget: budget.budget
				}
				this.$store.commit('general/setNewBudgetModal', data)
			}
			this.loadingBudget = false
			this.getParentInfo()
		},
		async deleteBugdetPart(budget_part, index = null, index_b = null){
			if(budget_part.id){
				this.loadingBudget = true
				const budget = await removeItemBudgetPart({budget_part_id: budget_part.id})
				if(budget.code == 200){
					this.budget = budget.budget
					const data = {
						status: true,
						item: this.item,
						budget: budget.budget
					}
					this.$store.commit('general/setNewBudgetModal', data)
				}
				this.loadingBudget = false
				this.getParentInfo()
			}
			else if (Number.isInteger(index) && Number.isInteger(index_b)) {
				this.budget.categories[index].parts.splice(index_b, 1);
			}
		},
		showNewBudgetPartStart(){
			this.newBudgetPart = {
				value: null,
				stage: null,
				concept: null,
				detail: null,
				item_type: this.item.item_type,
				item_id: this.item.item_id
			}
			this.showNewBudgetPart = true
		},
		cleanNewBudgetPart(){
			this.newBudgetPart = {
				value: null,
				stage: null,
				concept: null,
				detail: null,
				item_type: this.item.item_type,
				item_id: this.item.item_id
			}
		},
		getParentInfo(){
			if(this.$route.name == 'PreProject'){
				this.$store.dispatch('preprojects/getPreProject', {preproject_id: this.item.item_id, showLoader: false})
			}
			else if(this.$route.name == 'PreProjects'){
				this.$store.dispatch('preprojects/getPreProjects')
			}
			else if(this.$route.name == 'CRM'){
				this.$store.dispatch('crm/getConversations')
			}
			else if(this.$route.name == 'ArchivedCRM'){
				this.$store.dispatch('crm/getArchivedConversations')
			}
			else if(this.$route.name == 'Conversation'){
				this.$store.dispatch('crm/getConversation', {conversation_id: this.item.item_id, showLoader: false})
			}
			else if(this.newBudgetPart.item_type == 'preproject'){
				this.$store.dispatch('preprojects/getPreProject', {preproject_id: this.item.item_id, showLoader: false})
			}
			else if(this.newBudgetPart.item_type == 'crm'){
				this.$store.dispatch('crm/getConversation', {conversation_id: this.item.item_id, showLoader: false})
			}
		}
	}
}
</script>

<style>
	.budget-modal-custom{
		max-width: 1200px;
		width: 80vw;
	}
	.budget-note-tiny-editor {
		max-height: 140px !important;
		overflow: auto !important;
	}
</style>

<style scoped>
	/deep/ .budget-note-container p{margin-bottom: 0;}
</style>