<template>
  <v-container fluid style="max-width: 1400px; margin-left: 0;"
		:style="'height:'+$store.getters['general/availableHeight']+'px'">
		<v-row v-if="!loading" class="fill-height pb-0">
			<v-col ref="colContractContainer" cols="9" class="d-flex flex-column fill-height pb-0" style="overflow-y: scroll;overflow-x: hidden;">
				<div class="d-flex justify-space-between align-start w-100">
					<div class="flex-grow-1">
						<div class="caption" v-if="contract">
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable.name}}</b></div>
								<div class="action-container">

									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="warning"
												x-small
												icon
												v-bind="attrs"
												v-on="on"
											>
												<v-icon small>mdi-eye-outline</v-icon>
											</v-btn>
										</template>
										<v-list dense class="py-0">
											<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
												<p class="caption mb-0 font-weight-bold white--text">Asociaciones</p>
											</v-list-item>
											<v-list-item-group color="#524D4D">
												<v-list-item
													v-for="(association, index) in contract.morphable.associations"
													:key="index"
													dense
													@click="handleGoTo(association)"
													style="min-width: 200px"
												>
													<v-list-item-title dense>
														<div class="d-flex justify-space-between align-center item-background-hover">
															<div class="d-flex justify-space-between caption" style="width: 100%">
																<div style="width: 90px">{{ association.morphable_type == 'App\\Models\\PreProject' ? 'Pre Proyecto':'Visita Técnica'}}</div>
																<span>:</span>
																<b class="text-end" style="flex: 1;">#{{association.morphable_id}}</b>
															</div>
														</div>
													</v-list-item-title>
												</v-list-item>
											</v-list-item-group>
										</v-list>
									</v-menu>
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Zona Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable.info?.zone?.name}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Tipo Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable.info?.project_type?.name}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Dirección</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable.info.address?.address}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Municipio</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable.info.address?.district?.name}}</b></div>
								<div class="action-container">
								</div>
							</div>
						</div>
					</div>
					<v-divider vertical class="mx-4"></v-divider>
					<div class="flex-grow-1">
						<div class="caption" v-if="contract">
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Cliente</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable.client?.profile?.name}} {{contract.morphable.client?.profile?.lastname}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Rut</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable.client?.profile?.rut | formatRut}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Teléfono</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable.client?.profile?.phone}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Email</div><span>:</span> <b class="text-end" style="flex: 1;">{{contract.morphable.client?.email}}</b></div>
								<div class="action-container">
								</div>
							</div>
						</div>
					</div>
				</div>
				<v-divider class="my-2"></v-divider>
				<div class="d-flex justify-space-between align-center mb-2">
					<div class="flex-grow-1">
						<v-btn
							depressed
							color="#959595"
							dark
							class="mb-2 mx-2"
							x-small
							@click="$router.push({ name: 'ProjectCostBreakdown', params: { id: contract.morphable.id } })"
						>
							<p class="mb-0 ml-2 caption">Ir a Tabla de Costo Proyecto</p>
						</v-btn>
						<v-btn
							depressed
							color="#959595"
							dark
							outlined
							class="mb-2"
							x-small
							@click="previewContract"
						>
							<v-icon small>{{contractPreviewing ? 'mdi-briefcase-outline':'mdi-file-pdf-box'}}</v-icon>
							<p class="mb-0 ml-2 caption">{{ contractPreviewing ? 'Volver a Edición':'Previsualizar y Editar Contrato'}}</p>
						</v-btn>
						<!-- <v-btn
							depressed
							color="#959595"
							dark
							outlined
							class="mb-2"
							x-small
							:loading="loadingPdf"
							@click="downloadPDF"
						>
							<v-icon small>mdi-file-pdf-box</v-icon>
							<p class="mb-0 ml-2 caption">Convertir a PDF</p>
						</v-btn> -->
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="#959595"
									dark
									outlined
									class="mb-2"
									x-small
									v-bind="attrs"
									v-on="on"
									:loading="loadingPdf"
									@click="sendEmail"
								>
									<v-icon small>mdi-email-outline</v-icon>
									<p class="mb-0 ml-2 caption">Enviar por Email</p>
								</v-btn>
							</template>
							<span>Se enviará correo al usuario con una copia del contrato</span>
						</v-tooltip>
						<v-select
							v-model="selectedSectionToWork"
							:items="contract?.sections"
							:loading="loading"
							:menu-props="{ bottom: true, offsetY: true }"
							item-text="title"
							item-value="id"
							label="Seleccione Sección Especifica a Trabajar"
							class="custom-height-search custom-height-calendar"
							style="max-width: 300px"
							outlined
							dense
							clearable
							required
						></v-select>
					</div>
					<div class="text-right">
						<v-menu
							v-model="menuTemplate"
							:close-on-content-click="false"
							offset-y
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="#959595"
									dark
									class="mb-2"
									x-small
									v-bind="attrs"
									v-on="on"
								>
									<v-icon small>mdi-check</v-icon>
									<p class="mb-0 ml-2 caption">Guardar como Plantilla</p>
								</v-btn>
							</template>
							<v-card style="min-width: 300px;">
								<v-card-text>
									<v-text-field
										label="Nombre de la Plantilla"
										v-model="templateName"
										class="custom-height-search custom-height-calendar mb-2"
										hide-details
										dense
										outlined
										:rules="requiredRule"
									></v-text-field>
									<v-btn
										depressed
										color="green"
										:dark="templateName"
										small
										block
										:disabled="!templateName"
										@click="saveTemplate"
									>
										<v-icon small>mdi-check</v-icon>
										<p class="mb-0 ml-2 caption">Confirmar</p>
									</v-btn>
									<v-btn
										depressed
										color="#959595"
										text
										x-small
										block
										class="mt-2"
										@click="closeTemplateMenu"
									>
										<p class="mb-0">Cancelar</p>
									</v-btn>
								</v-card-text>
							</v-card>
						</v-menu>
						<!-- <v-btn
							depressed
							color="green"
							dark
							class="mb-2"
							x-small
							@click="saveTemplate"
						>
							<v-icon small>mdi-check</v-icon>
							<p class="mb-0 ml-2 caption">Guardar como Plantilla</p>
						</v-btn> -->
						<!-- <v-select
							v-model="selectedTemplate"
							:items="$store.state.contracts.templateContracts"
							:loading="$store.state.contracts.loadingTemplateContracts"
							:menu-props="{ top: true, offsetY: true }"
							item-text="title"
							item-value="id"
							label="Seleccione Plantilla"
							class="custom-height-search custom-height-calendar"
							style="max-width: 300px"
							outlined
							dense
							return-object
							required
							@change="onChangeTemplate"
						></v-select> -->
						<v-menu
							ref="menu"
							v-model="menuTemplates"
							transition="scale-transition"
							offset-y
							left
							nudge-top="-10"
							eager
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="#524D4D"
									outlined
									dark
									small
									block
									v-bind="attrs"
									v-on="on"
								>
									<div class="d-flex align-center justify-space-between py-2 w-100">
										<p class="mb-0 mr-2">Seleccione Plantilla</p>
										<v-icon>mdi-chevron-down</v-icon>
									</div>
								</v-btn>
							</template>
							<div class="white d-flex flex-column">
								<div>
									<v-list class="template-list white" max-height="300px" dense>
										<v-list-item
											class="template-list-item white"
											v-for="(template, i) in $store.state.contracts.templateContracts" :key="'t-' + i"
											@click="onChangeTemplate(template)">
											<v-list-item-content>
												<v-list-item-title>{{template.title}}</v-list-item-title>
											</v-list-item-content>
											<v-list-item-action>
												<div class="d-flex align-center">
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																fab
																dark
																x-small
																icon
																color="error"
																@click="deleteTemplate(template)"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon small dark>mdi-delete-outline</v-icon>
															</v-btn>
														</template>
														<span>Eliminar</span>
													</v-tooltip>
												</div>
											</v-list-item-action>
										</v-list-item>
									</v-list>
								</div>
							</div>
						</v-menu>
					</div>
				</div>
				<v-divider class="my-2"></v-divider>
				<TextEditor v-if="contract && selectedCompany && contractPreviewing" :contract="contract"/>
				<NonEditableContract v-else-if="contract && selectedCompany && showNonEditable" :contract="contract" />
				<div class="contract-container flex-grow-1 pb-0" v-else-if="contract && selectedCompany">
					<div class="py-2 px-4 d-flex justify-space-between">
						<div class="d-flex justify-space-between">
							<img :src="selectedCompany.company.logo" style="width: 120px;"/>
							<div class="px-2">
								<p class="font-weight-bold mb-0" style="color: #606060;font-size: 19.6px !important">{{$store.state.companies.selectedCompany.company.name}}</p>
								<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.description}}</p>
								<p class="caption mb-0" style="color: #606060;">Rut: {{$store.state.companies.selectedCompany.company.rut | formatRut}}</p>
								<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.phone}}</p>
								<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.email}}</p>
							</div>
						</div>
						<div>
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
								<div style="width: 120px">Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">{{ contract.code }} - {{ contract.created_at | toYear }}</b>
							</div>
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
								<div style="width: 120px">Fecha Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">{{ contract.created_at | toDate }}</b>
							</div>
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
								<div style="width: 120px">Fecha Inicio</div><span>:</span>
								<b class="text-end ml-3" style="flex: 1;" v-if="contract.start_date">{{ contract.start_date | toDate }}</b>
								<b class="text-end ml-3" style="flex: 1;" v-else>A Convenir</b>
							</div>
						</div>
					</div>
					<draggable
						v-if="contract"
						v-model="contract.sections"
						group="project_contract"
						id="contracts"
						item-key="id"
						:move="onMove"
						filter=".exclude"
						@end="startEndDragEventCategory"
					>
						<div class="py-2 px-4" v-for="(section, index) in contract.sections" :key="index"
							:class="section.section_type == 1 ? 'exclude':''">
							<div class="section-container rounded" v-if="!selectedSectionToWork || (selectedSectionToWork && selectedSectionToWork == section.id)">
								<div class="pa-4">
									<div class="d-flex align-center mb-3 justify-space-between">
										<div class="pa-2 w-100" v-if="newSection.contract_section_id == section.id">
											<v-menu
												ref="menu"
												v-if="section.section_type == 1"
												v-model="menuTitles"
												transition="scale-transition"
												offset-y
												left
												nudge-top="-10"
												eager
											>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														depressed
														color="#524D4D"
														outlined
														dark
														small
														block
														v-bind="attrs"
														v-on="on"
													>
														<div class="d-flex align-center justify-space-between py-2 w-100">
															<p class="mb-0 mr-2">{{newSection.title ? newSection.title:'Seleccione Título'}}</p>
															<v-icon>mdi-chevron-down</v-icon>
														</div>
													</v-btn>
												</template>
												<div class="white d-flex flex-column">
													<div>
														<v-list class="template-list white" max-height="300px" dense>
															<v-list-item
																class="template-list-item white"
																v-for="(title, i) in $store.state.contracts.titleContracts" :key="'ct-' + i"
																@click="onChangeTitle(title)">
																<v-list-item-content>
																	<v-list-item-title>{{title.title}}</v-list-item-title>
																</v-list-item-content>
																<v-list-item-action>
																	<div class="d-flex align-center">
																		<v-tooltip bottom>
																			<template v-slot:activator="{ on, attrs }">
																				<v-btn
																					fab
																					dark
																					x-small
																					icon
																					color="error"
																					@click="deleteTitle(title.id)"
																					v-bind="attrs"
																					v-on="on"
																				>
																					<v-icon small dark>mdi-delete-outline</v-icon>
																				</v-btn>
																			</template>
																			<span>Eliminar</span>
																		</v-tooltip>
																	</div>
																</v-list-item-action>
															</v-list-item>
														</v-list>
													</div>
												</div>
											</v-menu>
											<!-- <v-select
												v-model="newSection.title"
												:items="$store.state.contracts.titleContracts"
												:loading="$store.state.contracts.loadingTitleContracts"
												:menu-props="{ top: true, offsetY: true }"
												item-text="title"
												item-value="title"
												label="Seleccione Título"
												class="custom-height-search custom-height-calendar mb-2 w-100"
												outlined
												dense
												required
											></v-select> -->

											<div class="d-flex justify-start align-center">
												<v-btn
													depressed
													color="#FF120B"
													class="my-2 mx-2"
													small
													outlined
													@click="closeNewSection()"
												>
													<v-icon>mdi-window-close</v-icon>
													<p class="mb-0 ml-2 caption">Cancelar</p>
												</v-btn>
												<v-btn
													depressed
													color="green"
													dark
													class="my-2"
													small
													@click="confirmUpdateSection()"
												>
													<v-icon>mdi-check</v-icon>
													<p class="mb-0 ml-2 caption">Confirmar Cambios</p>
												</v-btn>
											</div>
										</div>
										<div class="d-flex flex-grow-1 justify-space-between" v-else>
											<div v-if="section.section_type == 1"  class="d-flex align-center text-center flex-grow-1">
												<v-menu
													v-model="menuAddTitle"
													:close-on-content-click="false"
													offset-y
												>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="#959595"
															dark
															class="mb-0"
															x-small
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small>mdi-cog</v-icon>
														</v-btn>
													</template>
													<v-card style="min-width: 300px;">
														<v-card-text>
															<v-text-field
																label="Ingrese Título"
																v-model="contractTitle"
																class="custom-height-search custom-height-calendar mb-2"
																hide-details
																dense
																outlined
																:rules="requiredRule"
															></v-text-field>
															<v-btn
																depressed
																color="green"
																:dark="contractTitle"
																small
																block
																:disabled="!contractTitle"
																@click="saveTitle"
															>
																<v-icon small>mdi-check</v-icon>
																<p class="mb-0 ml-2 caption">Confirmar</p>
															</v-btn>
															<v-btn
																depressed
																color="#959595"
																text
																x-small
																block
																class="mt-2"
																@click="closeTitleMenu"
															>
																<p class="mb-0">Cancelar</p>
															</v-btn>
														</v-card-text>
													</v-card>
												</v-menu>
												<p class="mb-0 font-weight-bold text-center flex-grow-1">{{section.title}}</p>
											</div>
											<p class="mb-0 font-weight-bold" v-else>{{ sectionIndex(index) }} {{section.title}}</p>
											<div class="d-flex align-center">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="warning"
															small
															icon
															v-bind="attrs"
															v-on="on"
															@click="editNewSection(section)"
														>
															<v-icon small>mdi-pencil-outline</v-icon>
														</v-btn>
													</template>
													<span>Editar Sección</span>
												</v-tooltip>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="#FF120B"
															small
															icon
															v-bind="attrs"
															v-on="on"
															@click="deleteSection(section)"
														>
															<v-icon small>mdi-delete-outline</v-icon>
														</v-btn>
													</template>
													<span>Eliminar Sección</span>
												</v-tooltip>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															depressed
															color="green"
															small
															icon
															v-bind="attrs"
															v-on="on"
															v-show="addingContent !== section.id"
															@click="addNewContent(section)"
														>
															<v-icon>mdi-plus</v-icon>
														</v-btn>
													</template>
													<span>Agregar Contenido a Sección</span>
												</v-tooltip>
											</div>
										</div>
									</div>
									<draggable
										v-model="section.contents"
										group="project_contract_sections"
										:id="'contracts_sections_' + index"
										item-key="id"
										@end="(ev) => startEndDragEventSection(ev)"
									>
										<div v-for="(section_content, indexB) in section.contents" :key="'a'+indexB" :id="'section_content_id_' + section_content.id">
											<div class="mb-4">
												<v-hover v-slot="{ hover }">
													<div class="section-content-container">
														<div class="pa-2" v-if="newContent.contract_section_content_id == section_content.id">
															<v-text-field
																v-model="newContent.title"
																label="Título"
																placeholder="Ingrese título identificador"
																outlined
																required
																dense
																hide-details
																:rules="requiredRule"
															></v-text-field>
															<v-checkbox v-model="newContent.show_title" class="mb-2" color="black" label="Mostrar Título en Contrato" hide-details dense></v-checkbox>
															<vue2-tinymce-editor
																:inline="true"
																v-model="newContent.content"
																:options="{ menubar: false }" ref="tinymceEditor"></vue2-tinymce-editor>
															<div class="d-flex justify-end align-center">
																<v-btn
																	depressed
																	color="#FF120B"
																	class="my-2 mx-2"
																	small
																	outlined
																	@click="closeNewContent()"
																>
																	<v-icon>mdi-window-close</v-icon>
																	<p class="mb-0 ml-2 caption">Cancelar</p>
																</v-btn>
																<v-btn
																	depressed
																	color="green"
																	dark
																	class="my-2"
																	small
																	@click="confirmUpdateContent()"
																>
																	<v-icon>mdi-check</v-icon>
																	<p class="mb-0 ml-2 caption">Confirmar Cambios</p>
																</v-btn>
															</div>
														</div>
														<div class="px-2" v-else>
															<div v-if="section_content.show_title" class="font-weight-bold">{{ (indexB + 1) + '.- ' }} {{ section_content.title }}</div>
															<div class="section-content pa-2" v-html="transformPlaceholdersToFriendlyView(section_content.content)"></div>
															<div class="d-flex justify-end py-2">
																<v-tooltip left>
																	<template v-slot:activator="{ on, attrs }">
																		<v-badge
																			:value="!!section_content?.observation"
																			:content="section_content.observation?.comments?.length"
																			color="red"
																			overlap
																		>
																			<v-btn
																				v-show="section_content.observation"
																				depressed
																				color="warning"
																				outlined
																				small
																				rounded
																				@click="menuComment = section_content.id"
																				v-bind="attrs"
																				v-on="on"
																			>
																				<v-icon class="mr-4" :class="{'pulse-animation': section_content.observation && !hover}">mdi-comment-plus-outline</v-icon>
																				<p class="mb-0">Hay Observaciones / Comentarios en esta sección</p>
																			</v-btn>
																		</v-badge>
																	</template>
																	<span>Ver / Agregar Comentarios en Sección</span>
																</v-tooltip>
															</div>
														</div>
														<div class="hover-section-content rounded" v-show="hover && newContent.contract_section_content_id != section_content.id">
															<div class="d-flex flex-column w-100 h-100 fill-height">
																<div class="d-flex justify-center align-center flex-grow-1">
																	<v-btn
																		depressed
																		color="warning"
																		class="mx-2"
																		icon
																		@click="editNewContent(section_content)"
																	>
																		<v-icon>mdi-pencil-outline</v-icon>
																	</v-btn>
																	<v-btn
																		depressed
																		color="#FF120B"
																		class="mx-2"
																		icon
																		@click="deleteContent(section_content)"
																	>
																		<v-icon>mdi-delete-outline</v-icon>
																	</v-btn>
																</div>
																<div class="d-flex justify-end mb-2 px-2">
																	<v-tooltip left>
																		<template v-slot:activator="{ on, attrs }">
																			<v-badge
																				:value="!!section_content?.observation"
																				:content="section_content.observation?.comments?.length"
																				color="red"
																				overlap
																			>
																				<v-btn
																					v-show="hover && section_content.observation"
																					depressed
																					color="warning"
																					outlined
																					small
																					rounded
																					@click="menuComment = section_content.id"
																					v-bind="attrs"
																					v-on="on"
																				>
																					<v-icon class="mr-4" :class="{'pulse-animation': section_content.observation && !hover}">mdi-comment-plus-outline</v-icon>
																					<p class="mb-0">Hay Observaciones / Comentarios en esta sección</p>
																				</v-btn>
																			</v-badge>
																		</template>
																		<span>Ver / Agregar Comentarios en Sección</span>
																	</v-tooltip>
																</div>
															</div>
														</div>
														<v-card v-if="menuComment == section_content.id" class="card-comment-container" color="white" width="400">
															<v-card-text style="background: white">
																<v-list dense class="py-0" max-height="300" style="overflow-y: auto;">
																	<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
																		<p class="caption mb-0 font-weight-bold white--text">Observaciones en Sección</p>
																	</v-list-item>
																	<v-list-item-group color="#524D4D" v-if="section_content.observation">
																		<v-list-item
																			v-for="(comment, index) in section_content.observation.comments"
																			:key="index"
																			dense
																		>
																			<v-list-item-title dense>
																				<div class="d-flex justify-space-between item-background-hover py-2">
																					<div class="d-flex">
																						<v-icon :color="comment.side == 1 ? 'warning':''">mdi-comment-plus-outline</v-icon>
																						<div class="ml-2">
																							<div class="font-weight-bold caption">{{ comment.user.profile.name }} {{ comment.user.profile.lastname }} <small>({{ comment.side == 1 ? 'Cliente':'Empresa' }})</small></div>
																							<div class="caption">{{ comment.comment }}</div>
																						</div>
																					</div>
																					<v-tooltip bottom>
																						<template v-slot:activator="{ on, attrs }">
																							<v-btn
																								fab
																								dark
																								x-small
																								icon
																								color="error"
																								@click="deleteComment(comment)"
																								v-bind="attrs"
																								v-on="on"
																								v-if="userInfo && userInfo.id == comment.user_id"
																							>
																								<v-icon small dark>mdi-delete-outline</v-icon>
																							</v-btn>
																						</template>
																						<span>Eliminar Comentario</span>
																					</v-tooltip>
																				</div>
																			</v-list-item-title>
																		</v-list-item>
																	</v-list-item-group>
																</v-list>
																<v-divider class="my-2"></v-divider>
																<div >
																	<v-text-field
																		label="Ingrese Comentario"
																		v-model="sectionComment"
																		class="custom-height-search custom-height-calendar mb-2"
																		hide-details
																		dense
																		outlined
																		:rules="requiredRule"
																	></v-text-field>
																	<v-btn
																		depressed
																		color="green"
																		:dark="!!sectionComment"
																		small
																		block
																		:disabled="!sectionComment"
																		@click="saveComment"
																	>
																		<v-icon small>mdi-check</v-icon>
																		<p class="mb-0 ml-2 caption">Confirmar</p>
																	</v-btn>
																	<v-btn
																		depressed
																		color="#959595"
																		text
																		x-small
																		block
																		class="mt-2"
																		@click="closeCommentForm"
																	>
																		<p class="mb-0">Cerrar</p>
																	</v-btn>
																</div>
															</v-card-text>
														</v-card>
													</div>
												</v-hover>
											</div>
										</div>
									</draggable>
								</div>
								<div class="pa-4">
									<div v-if="addingContent == section.id">
										<v-divider class="mb-4"></v-divider>
										<div class="mb-3 d-flex justify-space-between align-center">
											<div>
												<p class="mb-0 caption font-weight-bold">Agregar Contenido a Sección</p>
												<p class="mb-0 caption text--secondary">Puedes seleccionar una sección y un contenido preestablecido</p>
											</div>
											<div style="width: 30%">
												<v-select
													v-model="selectedCategory"
													:items="$store.state.contracts.companyContractCategories"
													:loading="$store.state.contracts.loadingCompanyContractCategories"
													:menu-props="{ top: true, offsetY: true }"
													item-text="title"
													item-value="id"
													label="Seleccione Sección"
													class="custom-height-search custom-height-calendar mb-2 w-100"
													outlined
													dense
													return-object
													required
													v-if="!blockCategorySelect"
												></v-select>
												<v-select
													v-model="selectedContent"
													:items="$store.state.contracts.companyContracts"
													:loading="$store.state.contracts.loadingCompanyContracts"
													:menu-props="{ top: true, offsetY: true }"
													item-text="title"
													item-value="id"
													label="Seleccione Contenido"
													class="custom-height-search custom-height-calendar w-100"
													outlined
													dense
													return-object
													required
													clearable
												></v-select>
											</div>
										</div>
										<v-text-field
											v-model="newContent.title"
											label="Título"
											placeholder="Ingrese título identificador"
											outlined
											required
											dense
											hide-details
											:rules="requiredRule"
										></v-text-field>
										<v-checkbox v-model="newContent.show_title" class="mb-2" color="black" label="Mostrar Título en Contrato" hide-details dense></v-checkbox>
										<vue2-tinymce-editor
											:inline="true"
											v-model="newContent.content"
											:options="{ menubar: false }" ref="tinymceEditor"></vue2-tinymce-editor>
										<div class="d-flex">
											<v-btn
												depressed
												color="#FF120B"
												class="my-2 mx-2"
												small
												outlined
												@click="closeNewContent()"
											>
												<v-icon>mdi-close-circle-outline</v-icon>
												<p class="mb-0 ml-2 caption">Cancelar</p>
											</v-btn>
											<v-btn
												depressed
												color="green"
												class="my-2 mx-2 flex-grow-1"
												small
												dark
												@click="confirmContent()"
											>
												<v-icon>mdi-check-circle-outline</v-icon>
												<p class="mb-0 ml-2 caption">Confirmar</p>
											</v-btn>
										</div>
									</div>
								</div>
							</div>
						</div>
					</draggable>
					<div class="py-2 px-4">
						<div class="section-container rounded">
							<div class="pa-4">
								<v-btn
									depressed
									color="#959595"
									class="my-2 flex-grow-1"
									small
									block
									outlined
									v-if="!addingSection"
									@click="addNewSection()"
								>
									<v-icon>mdi-plus</v-icon>
									<p class="mb-0 ml-2 caption">Agregar Sección</p>
								</v-btn>
								<div v-else>
									<div class="mb-3 d-flex justify-space-between align-center">
										<div>
											<p class="mb-0 caption font-weight-bold">Agregar una Sección</p>
											<p class="mb-0 caption text--secondary">Puedes seleccionar una sección preestablecida</p>
										</div>
										<div style="width: 30%">
											<v-select
												v-model="selectedCategory"
												:items="$store.state.contracts.companyContractCategories"
												:loading="$store.state.contracts.loadingCompanyContractCategories"
												:menu-props="{ top: true, offsetY: true }"
												item-text="title"
												item-value="id"
												label="Seleccione Sección"
												class="custom-height-search custom-height-calendar mb-2 w-100"
												outlined
												dense
												return-object
												required
												clearable
												@change="onChangeCategory"
											></v-select>
										</div>
									</div>
									<v-text-field
										v-model="newSection.title"
										label="Título"
										placeholder="Ingrese título de la sección"
										outlined
										required
										dense
										:rules="requiredRule"
									></v-text-field>
									<div class="d-flex">
										<v-btn
											depressed
											color="green"
											class="my-2 mx-2 flex-grow-1"
											small
											dark
											@click="confirmSection()"
										>
											<v-icon>mdi-check-circle-outline</v-icon>
											<p class="mb-0 ml-2 caption">Confirmar</p>
										</v-btn>
										<v-btn
											depressed
											color="#FF120B"
											class="my-2 mx-2"
											small
											outlined
											@click="closeNewSection()"
										>
											<v-icon>mdi-close-circle-outline</v-icon>
											<p class="mb-0 ml-2 caption">Cancelar</p>
										</v-btn>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <draggable v-model="contractSections" @end="startEndDragEvent">
					<div>
						<v-btn
							depressed
							color="#959595"
							class="my-2 flex-grow-1 mx-1"
							small
							block
							outlined
							@click="addNewSection"
						>
							<v-icon>mdi-plus</v-icon>
							<p class="mb-0 ml-2 caption">Agregar Sección</p>
						</v-btn>
					</div>
				</draggable> -->
			</v-col>
			<v-col cols="3" class="d-flex flex-column fill-height pb-0" style="overflow: scroll;">
				<div class="mb-4">
					<p class="mb-0 caption font-weight-bold">Secciones Empresa</p>
					<draggable
						v-model="$store.state.contracts.companyContractCategories"
						:group="{ name: 'project_contract', pull: 'clone', put: false }"
						id="contracts"
						item-key="id"
						@end="startEndDragNewCategory"
					>
						<div class="custom-list-drag pa-2 rounded my-2"
							:class="item.id == selectedCategory?.id ? 'selected':''"
							v-for="(item) in $store.state.contracts.companyContractCategories"
							:key="item.id"
							@click="onClickCategory(item)">
							<p class="mb-0 caption">{{ item.title }}</p>
						</div>
					</draggable>
				</div>
				<div>
					<p class="mb-0 caption font-weight-bold">Contenido Sección Seleccionada Empresa</p>
					<div v-if="selectedCategory">
						<draggable
							v-model="$store.state.contracts.companyContracts"
							:group="{ name: 'project_contract_sections', pull: 'clone', put: false }"
							id="contracts"
							item-key="id"
							class="draggable-contents"
							@end="startEndDragNewContent"
						>
							<div class="custom-list-drag pa-2 rounded my-2" v-for="(item) in $store.state.contracts.companyContracts" :key="item.id">
								<p class="mb-0 font-weight-bold caption">{{ item.title }}</p>
								<div class="caption pt-1" v-html="transformPlaceholdersToFriendlyView(item.content)"></div>
							</div>
						</draggable>
					</div>
					<p v-else class="mb-0 caption">Selecciona una sección del listado superior para ver los contenidos que la empresa ha configurado</p>
				</div>
			</v-col>
		</v-row>
		<div v-else>
			Cargando
		</div>
  </v-container>
</template>

<script>
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import draggable from "vuedraggable"
import _debounce from 'lodash/debounce'
import { getContract, createContractSectionProject, createContractSectionContent, updateContractSectionContent, updateContractSectionProject, deleteContractSectionProject, deleteContractSectionContent, updateContractSectionProjectOrders, updateContractSectionContentOrders, setTemplateToContract, createContractTemplate, deleteContractTemplate, createContractTitle, deleteContractTitle, deleteContractContentComment, createContractContentComment, createContractPDF, sendContractPdfEmail } from '@/helpers/api/contracts'
import NonEditableContract from "./NonEditableContract.vue"
import TextEditor from '@/components/Helpers/Editor/TextEditor'

export default {
	name: 'ContractDetail',
  components: { Vue2TinymceEditor, NonEditableContract, draggable, TextEditor },
  data() {
    return {
			selectedSectionToWork: null,
			sectionComment: null,
			menuComment: null,
			showNonEditable: false,
			testing: [],
			selectedTemplate: null,
			loadingPdf: false,
			loading: false,
			search: '',
			menuTitles: false,
			menuAddTitle: false,
			contractTitle: null,
			menuTemplates: false,
			menuTemplate: false,
			templateName: null,
			selectedCategory: null,
			selectedContent: null,
			editIndex: null,
			blockCategorySelect: false,
			addingSection: false,
			newSection: {
				contract_id: null,
				title: null,
				order: 1
			},
			addingContent: false,
			newContent: {
				title: null,
				order: 1,
				content: null,
				contract_section_id: null,
				show_title: false
			},
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			contract: null,
			contractSections: [
				{
					id: 1,
					title: 'Objeto del Contrato',
					parent_section_id: 1,
					section_contents: [{
						title: 'Section 1',
						parent_content_id: 1,
						content: '<p>Los servicios de arquitectura y especialidades del presente contrato contemplan el proyecto de dise&ntilde;o de una ampliaci&oacute;n de 80m&sup2; en un segundo piso de una vivienda existente de un piso de 60m&sup2; m&aacute;s la redistribuci&oacute;n de su planta original y la alteraci&oacute;n de esta (modificaciones a realizar o ya realizadas en la vivienda original), haciendo un total de 140m&sup2; m&aacute;ximos y sus gestiones pertinentes con la Direcci&oacute;n de Obras Municipales con el fin de obtener el permiso de edificaci&oacute;n por Art. 5.1.4 de la OGUC y Art. 166&deg; de la LGUC y luego obtener la recepci&oacute;n definitiva una vez sean terminadas las obras constructivas y certificadas las especialidades de agua potable, alcantarillado, electricidad, gas y otras. Esto se encuentra ubicado en calle 3 Poniente N&deg;2849, comuna de Macul, enmarc&aacute;ndose en las normativas pertinentes como Ordenanza General de Urbanismo y Construcci&oacute;n en adelante O.G.U.C., Ley General de Urbanismo y Construcci&oacute;n en adelante L.G.U.C., Plan Regulador Metropolitano de Santiago en adelante P.R.M.S. y Plan Regulador Comunal de Macul en adelante PRCM.</p>'
					}]
				}
			],
			contractPreviewing: false,
			placeholderMapping: {
				'{contract_start_date}': 'Fecha Inicio',
				'{contract_date}': 'Fecha del Contrato',
				'{address}': 'Dirección del Proyecto',
				'{district}': 'Municipio del Proyecto',
				'{client_name}': 'Nombre Cliente',
				'{client_dni}': 'Rut Cliente',
				'{company_name}': 'Nombre Empresa',
				'{company_rut}': 'Rut Empresa',
				'{company_responsible}': 'Nombre Representante',
				'{company_responsible_rut}': 'Rut Representante',
				'{company_address}': 'Dirección Empresa',
			}
    };
  },
  computed: {
    concepts() {
      return this.$store.state.companies.concepts
    },
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		userInfo(){
			return this.$store.state.user.userInfo
		}
  },
	watch: {
		selectedCategory(newValue){
			if(newValue){
				this.$store.dispatch('contracts/getCompanyContractsConfig', {category_id: this.selectedCategory.id, start: 0, limit: 100})
			}
		},
		selectedContent(newValue){
			this.newContent.title = newValue ? newValue.title:null
			this.newContent.content = newValue ? newValue.content:''
			this.newContent.company_contract_section_id = newValue ? newValue.id:null
		},
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
				this.$store.dispatch('contracts/getContractTemplates', {start: 0, limit: 100})
				this.$store.dispatch('contracts/getContractTitles', {start: 0, limit: 100})
			}
		},
		'$route.hash': function () {
			this.scrollToSection();
		}
	},
	mounted(){
		this.scrollToSection()
		if(this.selectedCompany){
			this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
			this.$store.dispatch('contracts/getContractTemplates', {start: 0, limit: 100})
      this.$store.dispatch('contracts/getContractTitles', {start: 0, limit: 100})
		}
		this.getContractInfo()
	},
  methods: {
		previewContract(){
			this.contractPreviewing = !this.contractPreviewing
		},
    scrollToSection() {
      this.$nextTick(() => {
        if (this.$route.hash) {
          const section = document.querySelector(this.$route.hash)
          const scrollContainer = this.$refs.colContractContainer
					window.history.replaceState(null, null, window.location.pathname + window.location.search)

          if (section && scrollContainer) {
            const offset = 100
            const sectionTop = section.offsetTop - scrollContainer.offsetTop
            scrollContainer.scrollTo({
              top: sectionTop - offset,
              behavior: 'smooth'
            })
          }
        }
      });
    },
		async sendEmail(){
			try {
				await sendContractPdfEmail({contract_id: this.contract.id})
				const messageData = {
					message: 'Se ha enviado correctamente el correo al cliente',
					title: 'Correo enviado',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			} catch (error) {
				const messageData = {
					message: 'Hubo un error en la operación, por favor intenta nuevamente',
					title: 'Ha ocurrido un error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.setMessage(messageData)
			}
		},
		async downloadPDF(){
			this.loadingPdf = true
			const pdf = await createContractPDF({contract_id: this.contract.id})
			if(pdf.code == 200){
				window.open(pdf.url);
			}
			this.loadingPdf = false
		},
		async deleteComment(comment){
			await deleteContractContentComment({contract_content_comment_id: comment.id})
			this.getContractInfo()
			this.sectionComment = null
		},
		closeCommentForm(){
			this.menuComment = null
		},
		async saveComment(){
			await createContractContentComment({contract_id: this.contract.id, contract_content_id: this.menuComment, comment: this.sectionComment, side: 2})
			this.getContractInfo()
			this.sectionComment = null
		},
		async deleteTitle(contract_title_id){
      await deleteContractTitle({contract_title_id})
      this.$store.dispatch('contracts/getContractTitles', {start: 0, limit: 100})
    },
    onChangeTitle(title){
      this.newSection.title = title.title
    },
    async saveTitle(){
      await createContractTitle({contract_title: this.contractTitle})
      this.closeTitleMenu()
      this.$store.dispatch('contracts/getContractTitles', {start: 0, limit: 100})
    },
    closeTitleMenu(){
      this.menuAddTitle = false
      this.contractTitle = null
    },
		handleGoTo(association){
			if(association.morphable_type == 'App\\Models\\PreProject'){
				this.$router.push({ name: 'PreProject', params: { id: association.morphable_id } })
			}
			else if(association.morphable_type == 'App\\Models\\BotConversation'){
				this.$router.push({ name: 'Conversation', params: { id: association.morphable_id } })
			}
		},
		async deleteTemplate(template){
			await deleteContractTemplate({contract_template_id: template.id})
			this.$store.dispatch('contracts/getContractTemplates', {start: 0, limit: 100})
		},
		closeTemplateMenu(){
			this.menuTemplate = false
			this.templateName = null
		},
		transformPlaceholdersToFriendlyView(content) {
			let result = content;
			for (const [placeholder, label] of Object.entries(this.placeholderMapping)) {
				const regex = new RegExp(placeholder, 'g');
				result = result.replace(regex, `<span class="friendly-placeholder" style="font-size: 12px;padding: 1px 6px;border: 1px solid grey;border-radius: 5px;background: white;color:orange;" contenteditable="false">${label}</span>`);
			}
			return result;
		},
		async getContractInfo(){
			if(this.$route.query.id){
				const contract = await getContract({contract_id: this.$route.query.id})
				if(contract.code == 200){
					this.contract = contract.contract

					setTimeout(() => {
						this.scrollToSection()
					}, 500)
				}
			}
		},
		onChangeCategory(category){
			this.newSection.title = category ? category.title:null
			this.newSection.company_planning_cat_id = category ? category.id:null
		},
		sectionIndex(index){
			return '' +  this.$store.state.general.romanNumerals[index] + '. ' + this.$store.state.general.ordinalsNumbers[index] + ':'
		},
		closeNewSection(){
			this.newSection = {
				contract_id: null,
				title: null,
				order: 1
			}
			this.addingSection = false
		},
		addNewSection(){
			this.selectedCategory = null
			this.selectedContent = null
			this.newSection = {
				contract_id: this.contract.id,
				title: null,
				order: this.contract.sections.length + 1
			}
			this.addingSection = true
		},
		editNewSection(section){
			this.newSection = {
				title: section.title,
				contract_section_id: section.id
			}
		},
		async confirmUpdateSection(){
			await updateContractSectionProject(this.newSection)
			this.getContractInfo()
			this.closeNewSection()
		},
		closeNewContent(){
			this.newContent = {
				contract_section_id: null,
				title: null,
				order: 1,
				content: null,
				show_title: false
			}
			this.addingContent = false
		},
		addNewContent(section){
			this.selectedCategory = null
			this.selectedContent = null
			if(section.company_planning_cat_id){
				this.selectedCategory = {id: section.company_planning_cat_id, title: section.title}
				this.blockCategorySelect = true
			}
			const subsections_count = section.contents.length + 1
			this.newContent = {
				title: null,
				contract_section_id: section.id,
				order: subsections_count,
				content: null,
				show_title: false
			}
			this.addingContent = section.id
		},
		editNewContent(content){
			this.newContent = {
				title: content.title,
				content: content.content,
				contract_section_content_id: content.id,
				show_title: content.show_title
			}
		},
		async confirmSection(){
			await createContractSectionProject(this.newSection)
			this.getContractInfo()
			this.closeNewSection()
		},
		async deleteSection(section){
			const data = {contract_section_id: section.id}
			await deleteContractSectionProject(data)
			this.getContractInfo()
		},
		async confirmContent(){
			console.log(this.newContent)
			await createContractSectionContent(this.newContent)
			this.getContractInfo()
			this.closeNewContent()
		},
		async confirmUpdateContent(){
			await updateContractSectionContent(this.newContent)
			this.getContractInfo()
			this.closeNewContent()
		},
		async deleteContent(section_content){
			const data = {contract_section_content_id: section_content.id}
			await deleteContractSectionContent(data)
			this.getContractInfo()
		},
		startEndDragEventCategory: _debounce(function(ev){ this.endDragEventCategory(ev) }, 800),
		async endDragEventCategory(ev){
			if (typeof ev.newIndex === 'number') {
				const sections = this.contract.sections.map((sections, index) => {
					return {
						id: sections.id,
						order: index + 1
					}
				})
				const sections_r = await updateContractSectionProjectOrders({sections});
				if(sections_r.code == 200){
					this.getContractInfo()
				}
			}
		},
		startEndDragEventSection: _debounce(function(ev){ this.endDragEventSection(ev) }, 800),
		async endDragEventSection(ev){
			if (typeof ev.newIndex === 'number') {
				const new_section_index = ev.to.id.replace('contracts_sections_', '')
				const new_section = this.contract.sections[new_section_index]
				const old_section_index = ev.from.id.replace('contracts_sections_', '')
				const old_section = this.contract.sections[old_section_index]

				const new_contents = new_section.contents.map((sections,index ) => {
					return {
						id: sections.id,
						order: index + 1,
						contract_section_id: new_section.id,
					}
				})
				const old_contents = old_section.contents.map((sections,index ) => {
					return {
						id: sections.id,
						order: index + 1,
						contract_section_id: old_section.id,
					}
				})
				const contents = [...new_contents, ...old_contents]
				const contents_r = await updateContractSectionContentOrders({contents});
				if(contents_r.code == 200){
					this.getContractInfo()
				}
			}
		},
    onMove(event) {
      if (event.draggedContext.element.section_type == 1 || event.relatedContext.element.section_type == 1) {
        return false;
      }
      return true;
    },
		startEndDragNewCategory: _debounce(function(ev){ this.endDragNewCategory(ev) }, 800),
		async endDragNewCategory(ev){
			const section = this.$store.state.contracts.companyContractCategories[ev.oldIndex]
			const newSection = {
				contract_id: this.contract.id,
				title: section.title,
				order: ev.newIndex + 1,
				company_planning_cat_id: section.id
			}
			await createContractSectionProject(newSection)
			this.getContractInfo()
		},
		startEndDragNewContent: _debounce(function(ev){ this.endDragNewContent(ev) }, 800),
		async endDragNewContent(ev){
			const section_index = ev.to.id.replace('contracts_sections_', '')
			const section = this.contract.sections[section_index]
			const content = this.$store.state.contracts.companyContracts[ev.oldIndex]
			const newContent = {
				title: content.title,
				content: content.content,
				contract_section_id: section.id,
				company_contract_section_id: content.id,
				order: ev.newIndex + 1
			}
			await createContractSectionContent(newContent)
			this.getContractInfo()
		},
		onClickCategory(data){
			this.selectedCategory = data
		},
		async onChangeTemplate(template){
			this.loading = true
			await setTemplateToContract({contract_id: this.contract.id, contract_template_id: template.id})
			this.loading = false
		},
		async saveTemplate(){
			const data = {
				contract_id: this.contract.id,
				title: this.templateName
			}
			await createContractTemplate(data)
			this.$store.dispatch('contracts/getContractTemplates', {start: 0, limit: 100})
			this.closeTemplateMenu()
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  },
};
</script>

<style scoped>
.contract-container{
	background-color: white;
	/* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}
.section-container{
	border: 1px solid  #d3d3d3;
}
.hover-section-content{
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.9);
	border: 1px solid #d3d3d3;
}
.section-content-container{
	position: relative;
}
.custom-list-drag{
	background: white;
	border: 1px solid #d3d3d3;
	cursor: pointer;
}
.custom-list-drag.selected{
	background: #d3d3d3;
}
.draggable-contents{
	max-height: 800px;
	overflow: scroll;
}
.action-container{
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.add-comment-icon{
	position: absolute;
	bottom: 0;
	right: 0;
	margin: auto;
	background: white;
	z-index: 9999;
}
.card-comment-container{
	position: absolute;
	top: 0;
	right: 60px;
	background: white;
	z-index: 9999;
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

.pulse-animation {
  animation: pulse 1.5s infinite;
}
/deep/ .section-content p{
	margin-bottom: 0;
}
</style>