<template>
  <v-container fluid>
		<div class="d-flex align-center justify-end">
			<div class="d-flex align-center mb-2" style="flex: 1;max-width: 400px;">
				<v-icon color="#524D4D" @click="searchForString">mdi-magnify</v-icon>
				<v-text-field
					v-model="searchInput"
					:loading="$store.state.contracts.loadingCompanyContractsList"
					outlined
					dense
					hide-details
					item-text="name"
					item-value="id"
					return-object
					clearable
					label="Búsqueda"
					class="mb-0 mr-2 custom-height-search custom-height-calendar"
					color="#524D4D"
					@input="sendToSearch"
				></v-text-field>
			</div>
		</div>
    <v-data-table
      :headers="headers"
      :items="$store.state.contracts.companyContractsList"
      :search="search"
      item-key="id"
      :loading="$store.state.contracts.loadingCompanyContractsList"
			:options.sync="options"
      loading-text="Cargando datos..."
			:footer-props="{
        itemsPerPageText: 'Filas por página',
				'items-per-page-options': [10, 30, 50, 100]
			}"
    >
			<template v-slot:item.client="{ item }">
				<b>{{ getUserName(item.morphable) }}</b>
				<div class="caption" v-if="item.morphable && item.morphable.client">
					{{ item.morphable.client ? item.morphable.client.email:'No identificado'}} - {{ item.morphable.client ? item.morphable.client.profile.phone:'No identificado'}}
				</div>
			</template>
      <template v-slot:item.project="{ item }">
				<p class="mb-0">
					{{ item.morphable?.name }}
				</p>
			</template>
      <template v-slot:item.address="{ item }">
				<p class="mb-0">
					{{ item.morphable?.info?.address?.address }}
				</p>
			</template>
      <template v-slot:item.district="{ item }">
				<p class="mb-0">
					{{ item.morphable?.info?.address?.district?.name }}
				</p>
			</template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom v-if="$store.state.user.userInfo">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="warning"
							class="mx-1"
							@click="toContractDetail(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import _debounce from 'lodash/debounce'

export default {
  data() {
    return {
      search: '',
			searchInput: null,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
			contracts: [],
			loading: true,
      headers: [
				{ text: 'Cliente', value: 'client' },
        { text: 'Proyecto', value: 'project' },
        { text: 'Dirección Proyecto', value: 'address' },
        { text: 'Municipio', value: 'district' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.reloadInfo()
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.reloadInfo()
		}
	},
  methods: {
		sendToSearch: _debounce(function() { this.searchForString() }, 800),
		searchForString(){
			this.reloadInfo(this.searchInput)
		},
		reloadInfo(search = null){
			let data = {
				...this.options,
				start: (this.options.page - 1) * this.options.itemsPerPage,
				limit: this.options.itemsPerPage,
			}
			if(search){
				data = {search}
			}
			this.$store.dispatch('contracts/getContracts', data)
		},
		getUserName(item){
			let name = ''
			if(item.client && item.client.profile){
				name = item.client.profile.name + ' ' + item.client.profile.lastname
			}
			else{
				name = 'No identificado'
			}
			return name
		},
		getCompanyProjects(){
			this.$store.dispatch('projects/getProjects')
		},
		toContractDetail(contract){
			this.$router.push({ name: 'ContractDetail', query: { id: contract.id } })
		}
  },
};
</script>

<style scoped>
</style>