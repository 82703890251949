<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.contracts.modalCategory"
      max-width="500"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" class="info-icon mr-2">mdi-information-symbol</v-icon>
								<div>
									<p class="mb-0 font-weight-bold text-primary title">Categoría contratso</p>
								</div>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4">
								<v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="companyContractSectionCategory.title"
                    label="Título Categoría"
                    placeholder="Ingrese un título"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {createContractSectionCategory, updateContractSectionCategory} from '@/helpers/api/contracts'

export default {
  name: 'ContractCategoryModal',
  components: { },
  data: () => ({
    validform: true,
		loadingData: false,
    companyContractSectionCategory: {
			title: null,
			order: 1
    },
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ],
  }),
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
  mounted(){
		if(this.$store.state.contracts.modalCategoryEditing){
			this.companyContractSectionCategory = {...this.$store.state.contracts.modalCategoryEditing}
			console.log('this.companyContractSectionCategory: ', this.companyContractSectionCategory)
		}
  },
  methods: {
    closeModal(){
      this.$store.commit('contracts/setModalCategory', false)
			this.$store.commit('contracts/setModalCategoryEditing', null)
      this.companyContractSectionCategory = {
				title: null,
				order: 1
			}
      this.validform = true
      this.loadingData = false
    },
    async sendForm(){
      if(this.$refs.form.validate()){
				this.loadingData = true
        const concept = this.companyContractSectionCategory.category_id ? await updateContractSectionCategory(this.companyContractSectionCategory):await createContractSectionCategory(this.companyContractSectionCategory)
        if(concept.code === 200){
          const messageData = {
            message: 'Se ha creado correctamente la categoría',
            title: 'Categoría creada',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
					this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
          this.setMessage(messageData)
          this.closeModal()
        }
        else{
          const messageData = {
            message: 'Hubo un error en la operación, por favor intenta nuevamente',
            title: 'Ha ocurrido un error',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#F4516C',
          }
          this.setMessage(messageData)
        }
				this.loadingData = false
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>