<template>
  <div class="modal-drag-schedule" :class="$vuetify.breakpoint.mobile && 'mobile-popup'">
		<modal
			name="schedule_modal"
			hide-overlay
			draggable=".drag-header"
			resizable
			:clickToClose="false"
			:focusTrap="true"
			:height="750"
			:class="hasMap ? 'with-map':'no-map'"
		>
			<div class="drag-header px-3 py-1">
				<v-icon color="white">mdi-drag-variant</v-icon>
			</div>
			<div class="pa-0">
				<div class="d-flex flex-row align-center justify-space-between py-2 px-4">
					<div class="d-flex flex-row align-center"
							style="flex: 1;max-width: 450px;">
						<v-icon color="white" small class="info-icon mr-2">mdi-calendar</v-icon>
						<v-text-field
							v-model="newSchedule.title"
							label="Título"
							solo
							flat
							hide-details
							dense
							outlined
						></v-text-field>
					</div>
					<div class="d-flex align-center">
						<v-chip
							v-show="scheduleTypeText"
							color=""
							label
							outlined
							x-small
							class="mx-2"
						>
							{{ scheduleTypeText }}
						</v-chip>
						<v-tooltip bottom v-if="newSchedule.simple_agenda">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									fab
									dark
									small
									icon
									color="#524D4D"
									@click="toConversation()"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon dark>mdi-file-document-arrow-right-outline</v-icon>
								</v-btn>
							</template>
							<span>Ver Detalle</span>
						</v-tooltip>
						<v-tooltip bottom v-if="newSchedule.id">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									fab
									dark
									x-small
									icon
									color="#524D4D"
									@click="duplicateSchedule()"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon small dark>mdi-content-duplicate</v-icon>
								</v-btn>
							</template>
							<span>Duplicar</span>
						</v-tooltip>
						<v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
							<v-icon dark>mdi-close</v-icon>
						</v-btn>
					</div>
				</div>
				<div class="d-flex px-4">
					<div class="pa-2">
						<v-form ref="form" v-model="validform" :style="newSchedule.coordinates ? 'max-width: 550px':''">
							<v-row align="center">
								<v-col class="py-1" cols="12">
									<v-row class="align-center">
										<v-col class="py-4" cols="5">
											<v-menu
												ref="menu"
												v-model="menuDatePicker"
												:close-on-content-click="false"
												:return-value.sync="newSchedule.date"
												transition="scale-transition"
												offset-y
												min-width="auto"
												eager
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														v-model="readOnlyDate"
														label="Fecha"
														prepend-icon="mdi-calendar"
														readonly
														hide-details
														v-bind="attrs"
														v-on="on"
													></v-text-field>
												</template>
												<v-date-picker
													v-model="newSchedule.date"
													no-title
													scrollable
													:first-day-of-week="1"
													locale="es-cl"
													:events="holidays"
													:picker-date.sync="pickerDate"
													event-color="red lighten-1"
													@input="(date) => $refs.menu.save(date)"
													class="pb-4"
												/>
											</v-menu>
										</v-col>
										<v-col class="py-4" cols="5">
											<v-menu
												ref="menutime"
												v-model="menuTimePicker"
												:close-on-content-click="false"
												:return-value.sync="newSchedule.time"
												transition="scale-transition"
												offset-y
												max-width="290px"
												min-width="290px"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														v-model="newSchedule.time"
														label="Hora"
														prepend-icon="mdi-clock-time-four-outline"
														readonly
														hide-details
														v-bind="attrs"
														v-on="on"
													></v-text-field>
												</template>
												<v-time-picker
													v-if="menuTimePicker"
													v-model="newSchedule.time"
													full-width
													format="24hr"
													@click:minute="$refs.menutime.save(newSchedule.time)"
												></v-time-picker>
											</v-menu>
										</v-col>
										<v-col class="py-4" cols="2">
											<v-menu
												ref="menuColors"
												v-model="menuColorPicker"
												:close-on-content-click="false"
												:return-value.sync="newSchedule.color"
												transition="scale-transition"
												offset-y
												max-width="170px"
												min-width="170px"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														text
														small
														v-bind="attrs"
														v-on="on"
													>
														<v-icon :color="newSchedule.color">mdi-circle</v-icon>
														<v-icon>mdi-chevron-down</v-icon>
													</v-btn>
												</template>
												<div class="white pa-1">
													<v-row no-gutters align="center">
														<v-col
															v-for="(col, i) in availableColors"
															:key="i"
															cols="2"
														>
															<v-btn
																icon
																small
																@click="onColorSelect(col)"
															>
																<v-icon :color="col">mdi-circle</v-icon>
															</v-btn>
														</v-col>
													</v-row>
												</div>
											</v-menu>
										</v-col>
									</v-row>
								</v-col>
								<v-col class="py-1" cols="12">
									<v-text-field
										v-model="newSchedule.user_name"
										label="Nombre Usuario"
										solo
										flat
										hide-details
										dense
										outlined
									></v-text-field>
									<v-row>
										<v-col cols="6" class="py-5">
											<v-text-field
												v-model="firstLastname"
												label="Primer Apellido"
												placeholder="Ingrese primer apellido del usuario"
												outlined
												required
												dense
												hide-details
											></v-text-field>
										</v-col>
										<v-col cols="6" class="py-5">
											<v-text-field
												v-model="secondLastname"
												label="Segundo Apellido"
												placeholder="Ingrese segundo apellido del usuario"
												outlined
												required
												dense
												hide-details
											></v-text-field>
										</v-col>
									</v-row>
								</v-col>
								<v-col class="py-1" cols="6">
									<v-text-field
										v-model="newSchedule.user_email"
										label="Email Usuario"
										solo
										flat
										dense
										outlined
                    :rules="emailRules"
									></v-text-field>
								</v-col>
								<v-col class="py-1" cols="6">
									<v-text-field
										v-model="newSchedule.user_phone"
										label="Teléfono Usuario"
										solo
										flat
										dense
										outlined
                    :rules="phoneRule"
										maxlength="11"
										@keypress="isNumberKey"
									></v-text-field>
								</v-col>
								<v-col class="py-1" cols="12">
									<v-autocomplete
										v-model="newSchedule.address"
										:items="[...items, ...[newSchedule.address]]"
										name="address"
										:search-input.sync="search"
										prepend-inner-icon="mdi-map-marker"
										label="Dirección"
										placeholder="Dirección"
										autocomplete="off"
										outlined
										dense
										hide-details
										:loading="loading"
										:filter="d => d"
										color="secondary"
										item-color="secondary"
										return-object
										@change="change"
									></v-autocomplete>
								</v-col>
								<v-col class="py-1" cols="12">
									<div class="d-flex align-center">
										<p class="mb-0 caption mr-2">Notificación</p>
										<v-text-field
											v-model="newSchedule.notification_value"
											:disabled="newSchedule.notification_type === 0"
											solo
											flat
											hide-details
											dense
											outlined
											type="number"
											style="max-width: 150px;"
											class="custom-height-calendar mr-2"
										></v-text-field>
										<v-select
											v-model="newSchedule.notification_type"
											:items="availableTypesNotification"
											outlined
											dense
											required
											hide-details
											color="#524D4D"
											item-color="#524D4D"
											class="custom-height-calendar"
										>
											<template v-slot:selection="{ item }">
												<p class="mb-0 caption">{{item.text}}</p>
											</template>
										</v-select>
									</div>
								</v-col>
								<v-col class="py-1" cols="12" md="12" v-if="!newSchedule.id && $store.state.schedule.userGoogleCalendar">
									<div class="d-flex align-center justify-space-between">
										<p class="mb-0 caption">Crear Link de Reunión Por Google Meet</p>
										<v-switch
											class="mt-0"
											color="#FF120B"
											dense
											hide-details
											v-model="newSchedule.create_meet"
										></v-switch>
									</div>
								</v-col>
								<v-col class="py-1" cols="12" md="6" v-if="!newSchedule.id && !newSchedule.crm_id">
									<div class="d-flex align-center justify-space-between">
										<p class="mb-0 caption">Crear Visita Técnica en CRM</p>
										<v-switch
											class="mt-0"
											color="#FF120B"
											dense
											hide-details
											v-model="newSchedule.to_crm"
										></v-switch>
									</div>
								</v-col>
								<v-col class="py-1" cols="12" md="6">
									<div class="d-flex justify-space-between align-center">
										<v-text-field
											v-model="newSchedule.address_value"
											label="Valor visita técnica"
											placeholder="Ingrese el valor"
											outlined
											required
											dense
											hide-details=""
											maxlength="9"
											class="my-1"
											@keypress="isNumberKey"
										>
											<!-- <template v-slot:append-outer>
												<v-btn
													icon
													color="error"
													@click="editValue = false"
													:disabled="!editPermission"
												>
													<v-icon>mdi-close</v-icon>
												</v-btn>
												<v-btn
													icon
													color="warning"
													@click="sendEditValue"
													:disabled="!editPermission"
												>
													<v-icon>mdi-check</v-icon>
												</v-btn>
											</template> -->
										</v-text-field>
									</div>
								</v-col>
								<v-col class="py-1" cols="12">
									<div v-if="loadingMce" class="d-flex flex-column justify-center align-center" style="min-height: 200px;">
										<v-skeleton-loader
											style="width: 250px;"
											class="mb-1"
											type="paragraph"
										></v-skeleton-loader>
										<p>Cargando editor...</p>
									</div>
									<vue2-tinymce-editor
										v-else
										:inline="true"
										v-model="newSchedule.description"
										:options="{ menubar: false, height: 120 }" ref="tinymceEditor"></vue2-tinymce-editor>
								</v-col>
							</v-row>

							<v-row align="center">
								<v-col cols="12">
									<div class="d-flex flex-row justify-space-between">
										<div>
											<v-btn
												class="mx-2 my-1"
												color="white"
												raised
												small
												v-if="newSchedule.id"
												@click="removeSchedule"
											>
												<p class="mb-0 text-primary">Eliminar</p>
											</v-btn>
										</div>
										<div class="d-flex flex-row justify-end">
											<v-btn
												class="mx-2 my-1"
												color="white"
												raised
												small
												@click="closeModal"
											>
												<p class="mb-0 text-primary">Cancelar</p>
											</v-btn>
											<v-btn
												depressed
												color="#524D4D"
												x-small
												outlined
												dark
												:loading="loading"
												class="ml-2"
												style="height: 40px"
												@click="saveSchedule()"
											>
												<p class="mb-0 caption">Guardar sin Invitación</p>
											</v-btn>
											<v-btn
												depressed
												color="#524D4D"
												x-small
												dark
												:loading="loading"
												class="ml-2"
												style="height: 40px"
												@click="saveSchedule(true)"
											>
												<p class="mb-0 caption">Guardar y Enviar Invitación</p>
											</v-btn>
										</div>
									</div>
								</v-col>
							</v-row>
						</v-form>
					</div>
					<div v-show="newSchedule.coordinates" class="pa-2" style="height: 100%;flex: 1;">
						<GmapMap
							:center="newSchedule.coordinates ?? { lat: -33.4489, lng: -70.6693 }"
							:zoom="mapZoom"
							style="width: 100%; height: 100%;min-height: 500px;"
							ref="mapRefModal"
							@zoom_changed="updateZoom"
							@dragend="updateCenter"
						>
							<GmapMarker
								v-if="newSchedule.coordinates"
								:position="newSchedule.coordinates"
								:icon="{url: require('@/assets/marker_schedule.png'), scaledSize: { width: 45, height: 47 }}"
								@mousedown="startZoomIn"
								@mouseup="stopZoomIn"
							/>
						</GmapMap>
					</div>
				</div>
			</div>
		</modal>
  </div>
</template>

<script>
import moment from "moment"
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import {createUserSchedule, updateUserSchedule, deleteUserSchedule, sendSingleEmailForSchedule, getHolidays} from '@/helpers/api/schedule'
import {getCoordinatesValuesPolygon} from '@/helpers/api/general'
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'NewScheduleModal',
  components: {Vue2TinymceEditor},
  data: () => ({
		isZooming: false,
		mapZoom: 11,
		zoomInterval: null,
		items: [],
		search: '',
		address: null,
		firstLastname: null,
		secondLastname: null,
		autocompleteService: null,
    validform: true,
		loadingMce: true,
		currentYear: 2024,
		currentYearHolidays: null,
		menuDatePicker: false,
		menuTimePicker: false,
		menuColorPicker: false,
		showMap: true,
		pickerDate: null,
		addressPolygon: null,
		newSchedule: {
			id: null,
			to_crm: true,
			create_meet: false,
			title: 'Sin Título',
			address: null,
			coordinates: null,
			meeting_link: null,
			user_name: null,
			user_email: null,
			user_phone: null,
			description: '',
			color: '#959595',
			date: moment(new Date()).format('YYYY-MM-DD'),
			time: moment(new Date()).format('HH:mm'),
			date_time: null,
			notification_type: 1,
			notification_value: 30,
			crm_id: null,
			address_value: null,
			placeChanged: false
		},
		availableTypesNotification: [
			{ text: 'Minutos', value: 1 },
			{ text: 'Horas', value: 2 },
			{ text: 'Días', value: 3 },
			{ text: 'Semanas', value: 4 },
			{ text: 'Sin notificación', value: 0 },
		],
		availableColors: [
			'#2C3E50','#FFD700','#DC143C','#228B22','#4169E1','#6A5ACD','#008080','#4B0082','#FF1493','#FF8C00'
		],
		loading: false,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    phoneRule: [
			v => !v || /^569\d{8}$/.test(v) || 'Debe tener 11 dígitos y empezar con 569',
    ],
    emailRules: [
      v => !v || /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ]
  }),
  computed: {
		google: gmapApi,
		newScheduleWatch(){
			return Object.assign({}, this.newSchedule)
		},
		scheduleTypeText(){
			if(this.newSchedule.simple_agenda && this.newSchedule.simple_agenda.mode == 2){
				return 'Agenda Automática'
			}
			else if(this.newSchedule.simple_agenda){
				return 'Agenda Manual'
			}
			return null
		},
		hasMap(){
			return this.newSchedule.coordinates && Object.keys(this.newSchedule.coordinates).length > 0
		},
		readOnlyDate(){
			return moment(this.newSchedule.date).format('DD-MM-YYYY')
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		newScheduleModal(){
			return this.$store.state.schedule.newScheduleModal
		},
		holidays(){
			return this.currentYearHolidays || this.$store.state.general.holidayDays
		},
		newScheduleEditing(){
			return this.$store.state.schedule.newScheduleEditing
		}
	},
	watch: {
		newScheduleModal(newValue){
			if(newValue){
        this.$modal.show('schedule_modal')
			}
		},
    search(newSearch) {
      if (newSearch) {
        this.googleSearch(newSearch);
      } else {
        this.items = []; // Limpiar los resultados si no hay búsqueda
      }
    },
		currentYear(newValue, oldValue){
			if(newValue != oldValue){
				this.updateHolidays()
			}
		},
		pickerDate(newValue){
			if(newValue){
				this.currentYear = moment(newValue).format('YYYY')
			}
		},
		newScheduleWatch: {
			handler(newValue, oldValue) {
				if (newValue.coordinates != oldValue.coordinates && newValue.coordinates.lat && newValue.coordinates.lng) {
					this.placeChanged && this.getPolygonWithCoords();
				}
			},
			deep: true
		},
		newScheduleEditing(newValue){
			this.newSchedule = {
				...this.newSchedule,
				...newValue,
				...{
					date: moment(newValue.date_time).format('YYYY-MM-DD'),
					time: moment(newValue.date_time).format('HH:mm'),
					address: newValue.address?.address,
					address_value: newValue.simple_agenda?.project_info?.address_value,
					coordinates: newValue.address && newValue.address.coordinates ? {
						lat: newValue.address?.coordinates?.coordinates[1],
						lng: newValue.address?.coordinates?.coordinates[0]
					}:null
				}
			}
		}
	},
  mounted(){
		this.updateHolidays()
		if(this.$store.state.schedule.newScheduleEditing){
			const newSchedule = this.$store.state.schedule.newScheduleEditing
			this.newSchedule = {
				...this.newSchedule,
				...newSchedule,
				...{
					date: moment(newSchedule.date_time).format('YYYY-MM-DD'),
					time: moment(newSchedule.date_time).format('HH:mm'),
					address: newSchedule.address?.address,
					address_value: newSchedule.simple_agenda?.project_info?.address_value,
					coordinates: newSchedule.address && newSchedule.address.coordinates ? {
						lat: newSchedule.address?.coordinates?.coordinates[1],
						lng: newSchedule.address?.coordinates?.coordinates[0]
					}:null
				}
			}
			if(this.newSchedule.lastname){
				const parts = this.newSchedule.lastname.split(' ')
				this.firstLastname = parts[0] || ''
				this.secondLastname = parts[1] || ''
			}
		}
		this.$modal.show('schedule_modal')
		this.$nextTick(() => {
			this.loadingMce = false
			this.$gmapApiPromiseLazy().then(() => {
				this.autocompleteService = new this.google.maps.places.AutocompleteService()
			})
		});
  },
  methods: {
		async getPolygonWithCoords(){
			const polygon = await getCoordinatesValuesPolygon({
				latitude: this.newSchedule.coordinates.lat,
				longitude: this.newSchedule.coordinates.lng,
			})
			if(polygon.code == 200){
				this.addressPolygon = polygon.polygon
				this.newSchedule.address_value = polygon.polygon.price
			}
		},
		async updateHolidays(){
			const holidays = await getHolidays({year: this.currentYear})
			if(holidays.code == 200){
				this.currentYearHolidays = holidays.events
			}
		},
    updateZoom(zoom) {
      this.mapZoom = zoom;
    },
    updateCenter() {
      const map = this.$refs.mapRefModal.$mapObject;
      const newCenter = {
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
      };

      if (this.mapCenter.lat !== newCenter.lat || this.mapCenter.lng !== newCenter.lng) {
        this.mapCenter = newCenter;
      }
    },
		onAddressClick() {
			if(this.newSchedule.coordinates){
				this.startZoomIn()
			}
		},
    startZoomIn() {
      this.isZooming = true;
      this.zoomIn();
    },
    stopZoomIn() {
      this.isZooming = false;
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
        this.zoomInterval = null;
      }
    },
    zoomIn() {
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
      }

      this.zoomInterval = setInterval(() => {
        if (this.isZooming && this.mapZoom < 20) {
          this.mapZoom += 1;
					
					const map = this.$refs.mapRefModal.$mapObject;
					if (map) {
						map.setCenter(this.newSchedule.coordinates);
					}
        } else {
          clearInterval(this.zoomInterval);
          this.zoomInterval = null;
        }
      }, 100);
    },
		duplicateSchedule(){
			this.newSchedule = {...this.newSchedule, ...{id: null}}
		},
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
		toConversation(){
			this.$router.push({ name: 'Conversation', params: { id: this.newSchedule.simple_agenda.bot_conversation_id } })
			this.closeModal()
		},
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.items = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.items = [];
        }
      });
    },
		async getPlaceDetails(placeId) {
			if (typeof this.google !== 'undefined' && this.google.maps) {
				const service = new this.google.maps.places.PlacesService(document.createElement('div'));
				
				service.getDetails({ placeId }, (place, status) => {
					if (status === this.google.maps.places.PlacesServiceStatus.OK) {
						const { geometry } = place;
						this.newSchedule.coordinates = {
							lat: geometry.location.lat(),
							lng: geometry.location.lng()
						}
					}
				});
			} else {
				console.error('Google Maps API no está cargada');
			}
		},
    change(value) {
			this.placeChanged = true
			this.getPlaceDetails(value.value.place_id)
      this.newSchedule.address = value.text;
    },
    dayClass(date) {
			const isHoliday = this.$store.state.general.holidayDays.map(f => f.date).includes(moment(date).format('YYYY-MM-DD'))
      return isHoliday ? 'holiday-cell' : '';
    },
		onColorSelect(color){
			this.newSchedule.color = color
			this.$refs.menuColors.save(color)
		},
		async removeSchedule(){
			const data = {schedule_id: this.newSchedule.id}
			this.closeModal()
			await deleteUserSchedule(data)
			this.$store.dispatch('schedule/getUserSchedule')
		},
		async saveSchedule(sendEmail = false){
      if(this.$refs.form.validate()){
				
				try {

					const data = {
						...this.newSchedule,
						...{lastname: this.firstLastname + ' ' + this.secondLastname},
						...{
							schedule_id: this.newSchedule.id,
							date_time: this.newSchedule.date + ' ' + this.newSchedule.time,
							title: this.newSchedule.title || this.newSchedule.title != '' ? this.newSchedule.title:'Sin título'
						}
					}
					const schedule = this.newSchedule.id ? await updateUserSchedule(data):await createUserSchedule(data)
					this.$store.dispatch('schedule/getUserSchedule')
					const dataMessage = {
						message: this.newSchedule.id  ? 'Actualización realizada correctamente.':'Creación realizada correctamente',
						title: 'Ok',
						created: new Date(),
						type: 'success',
						icon: 'mdi-check-circle',
						color: '#3EBFA3'
					}
					sendEmail && sendSingleEmailForSchedule({schedule_id: schedule.schedule.id, type: this.newSchedule.id ? 2:1})
					this.$store.dispatch('general/addMessage', dataMessage)
					this.closeModal()
					
				}
				catch (error) {
					const dataMessage = {
						message: 'Por favor intenta nuevamente.',
						title: 'Error',
						created: new Date(),
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#F4516C',
					}
					this.$store.dispatch('general/addMessage', dataMessage)
					if(error.data){
						error.data.forEach(message => {
							const messageData = {
								message: message.message,
								title: 'Error',
								created: new Date(),
								type: 'danger',
								icon: 'mdi-close-octagon',
								color: '#F4516C',
							}
							this.$store.dispatch('general/addMessage', messageData)
						})
					}
				}

				// const schedule = this.newSchedule.id ? await updateUserSchedule(data):await createUserSchedule(data)
				// if(schedule.code == 200){
				// 	this.$store.dispatch('schedule/getUserSchedule')
				// 	const data = {
				// 		message: this.newSchedule.id  ? 'Actualización realizada correctamente.':'Creación realizada correctamente',
				// 		title: 'Ok',
				// 		created: new Date(),
				// 		type: 'success',
				// 		icon: 'mdi-check-circle',
				// 		color: '#3EBFA3'
				// 	}
				// 	sendEmail && sendSingleEmailForSchedule({schedule_id: schedule.schedule.id, type: this.newSchedule.id ? 2:1})
				// 	this.$store.dispatch('general/addMessage', data)
				// 	this.closeModal()
				// }
				// else{
				// 	const data = {
				// 		message: 'Por favor intenta nuevamente.',
				// 		title: 'Error',
				// 		created: new Date(),
				// 		type: 'danger',
				// 		icon: 'mdi-close-octagon',
				// 		color: '#F4516C',
				// 	}
				// 	this.$store.dispatch('general/addMessage', data)
				// 	if(error.data){
				// 		error.data.forEach(message => {
				// 			const messageData = {
				// 				message: message.message,
				// 				title: 'Error',
				// 				created: new Date(),
				// 				type: 'danger',
				// 				icon: 'mdi-close-octagon',
				// 				color: '#F4516C',
				// 			}
				// 			this.$store.dispatch('general/addMessage', messageData)
				// 		})
				// 	}
				// }
			}
		},
    closeModal(){
      this.$store.commit('schedule/setNewScheduleModal', false)
			this.$store.commit('schedule/setNewScheduleEditing', null)
			this.newSchedule.crm_id && this.$store.dispatch('crm/getConversation', this.newSchedule.crm_id)
      this.newSchedule = {
				id: null,
				to_crm: true,
				title: 'Sin Título',
				address: null,
				coordinates: null,
				meeting_link: null,
				user_name: null,
				user_email: null,
				user_phone: null,
				description: '',
				color: '#959595',
				date: moment(new Date()).format('YYYY-MM-DD'),
				time: moment(new Date()).format('HH:mm'),
				date_time: null,
				notification_type: 1,
				notification_value: 30,
				crm_id: null,
				address_value: null
			}
			this.loadingMce = true
      this.validform = true
      this.loadingData = false
			this.menuDatePicker = false
			this.menuTimePicker = false
			this.menuColorPicker = false
    }
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
	.drag-header{
		cursor: move;
		background-color: #959595;
	}
	.gmap-autocomplete {
		z-index: 1000;
	}
</style>
<style>
	.modal-drag-schedule .vm--container.with-map .vm--modal{
		min-width: 900px!important;
		height: 660px!important;
	}
	.modal-drag-schedule.mobile-popup .vm--container .vm--modal{
		max-width: 100%!important;
		height: 660px!important;
	}
	.modal-drag-schedule .vm--container .vm--modal{
		resize: horizontal;
	}
</style>