<template>
  <v-container fluid>
		<div class="d-flex align-center justify-end">
			<div class="d-flex align-center mb-2" style="flex: 1;max-width: 400px;">
				<v-icon color="#524D4D" @click="searchForString">mdi-magnify</v-icon>
				<v-text-field
					v-model="searchInput"
					:loading="$store.state.contracts.loadingCompanyClients"
					outlined
					dense
					hide-details
					item-text="name"
					item-value="id"
					return-object
					clearable
					label="Búsqueda"
					class="mb-0 mr-2 custom-height-search custom-height-calendar"
					color="#524D4D"
					@input="sendToSearch"
				></v-text-field>
			</div>
		</div>
    <v-data-table
      :headers="headers"
      :items="$store.state.users.companyClients"
      :search="search"
      item-key="id"
      :loading="$store.state.users.loadingCompanyClients"
			:options.sync="options"
      loading-text="Cargando datos..."
			:footer-props="{
        itemsPerPageText: 'Filas por página',
				'items-per-page-options': [10, 30, 50, 100]
			}"
    >
      <template v-slot:item.client="{ item }">
				<div class="d-flex align-center">
					<v-avatar
						size="30"
					>
						<img :src="item.profile?.avatar" class="user-avatar" v-if="item.profile?.avatar"/>
						<v-icon v-else class="user-avatar icon" color="#524D4D" x-large>mdi-account</v-icon>
					</v-avatar>
					<div>
						<p class="ml-2 mb-0">{{ item.profile?.name }} {{ item.profile?.lastname }}</p>
						<p class="ml-2 mb-0 caption">{{ item.email }}</p>
					</div>
				</div>
      </template>
      <template v-slot:item.address="{ item }">
				<div class="d-flex align-center">
					<p class="ml-2 mb-0">{{ item.profile?.address }}</p>
				</div>
      </template>
      <template v-slot:item.district="{ item }">
				<div class="d-flex align-center">
					<p class="ml-2 mb-0">{{ item.profile?.city?.name }}</p>
				</div>
      </template>
      <template v-slot:item.phone="{ item }">
				<div class="d-flex align-center">
					<p class="ml-2 mb-0">{{ item.profile?.phone }}</p>
				</div>
      </template>
      <template v-slot:item.project_type="{ item }">
				<div class="d-flex align-center">
					<p class="ml-2 mb-0">{{ item.preprojects[0]?.info?.project_type?.name }}</p>
				</div>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="#959595"
							class="mx-1"
							@click="toClientDetail(item)"

							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-clipboard-account-outline</v-icon>
						</v-btn>
					</template>
					<span>Ver ficha cliente</span>
				</v-tooltip>
				<v-tooltip bottom v-if="$checkPermissions(['edit_users'])">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="warning"
							class="mx-1"
							@click="editUser(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-pencil-outline</v-icon>
						</v-btn>
					</template>
					<span>Editar</span>
				</v-tooltip>
				<!-- <v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="#31D56A"
							class="mx-1"
							@click="$router.push({ name: 'Chat', query: { user_id: item.id } })"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-chat-outline</v-icon>
						</v-btn>
					</template>
					<span>Chatear</span>
				</v-tooltip> -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
export default {
  data() {
    return {
      search: '',
			searchInput: null,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      headers: [
        { text: 'Cliente', value: 'client', class: 'with-divider', cellClass: 'with-divider', sortable: false },
        { text: 'Dirección', value: 'address', class: 'with-divider', cellClass: 'with-divider', sortable: false },
        { text: 'Comuna', value: 'district', class: 'with-divider', cellClass: 'with-divider', sortable: false },
        { text: 'Teléfono', value: 'phone', class: 'with-divider', cellClass: 'with-divider', sortable: false },
        { text: 'Tipo Proyecto', value: 'project_type', class: 'with-divider', cellClass: 'with-divider', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    filteredUsers() {
      return this.$store.state.users.usersList.filter(user => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.reloadInfo()
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.reloadInfo()
		}
	},
  methods: {
		sendToSearch: _debounce(function() { this.searchForString() }, 800),
		searchForString(){
			this.reloadInfo(this.searchInput)
		},
		reloadInfo(search = null){
			let data = {
				...this.options,
				start: (this.options.page - 1) * this.options.itemsPerPage,
				limit: this.options.itemsPerPage,
			}
			if(search){
				data = {search}
			}
			this.$store.dispatch('users/getCompanyClients', data)
		},
		toClientDetail(item){
			console.log('toClientDetail: ', item)
			this.$router.push({ name: 'Client', params: { id: item.id } })
		},
    editUser(user) {
			const data = {...user, ...{user_id: user.id, client: true}}
			this.$store.commit('users/setNewUserModalEditing', data)
			this.$store.commit('users/setNewUserModal', true)
    },
  },
};
</script>

<style scoped>
</style>
<style>

.with-divider {
  border-right: 1px solid grey;
}
</style>