import { getCompanyContracts, getCompanyContractSections, getCompanyContractSectionCategories, getContractTemplates, getContractTitles } from "@/helpers/api/contracts"

const ChatStore = {
  namespaced: true,
  state: {
		loadingCompanyContractsList: false,
		companyContractsList: [],
		loadingCompanyContracts: false,
		companyContracts: [],
		loadingCompanyContractCategories: false,
		companyContractCategories: [],
		modalCategory: false,
		modalCategoryEditing: null,
		modalSection: false,
		modalSectionEditing: null,
		modalContract: false,
		loadingTemplateContracts: false,
		templateContracts: [],
		loadingTitleContracts: false,
		titleContracts: []
  },
  mutations: {
    setLoadingCompanyContractsList(state, status) {
      state.loadingCompanyContractsList = status
    },
    setCompanyContractsList(state, companyContracts) {
      state.companyContractsList = companyContracts
    },
    setLoadingCompanyContracts(state, status) {
      state.loadingCompanyContracts = status
    },
    setCompanyContracts(state, companyContracts) {
      state.companyContracts = companyContracts
    },
    setLoadingTitleContracts(state, status) {
      state.loadingTitleContracts = status
    },
    setTitleContracts(state, titles) {
      state.titleContracts = titles
    },
    setLoadingTemplateContracts(state, status) {
      state.loadingTemplateContracts = status
    },
    setTemplateContracts(state, templates) {
      state.templateContracts = templates
    },
    setLoadingCompanyContractCategories(state, status) {
      state.loadingCompanyContractCategories = status
    },
    setCompanyContractCategories(state, cats) {
      state.companyContractCategories = cats
    },
    setModalCategory(state, status) {
      state.modalCategory = status
    },
    setModalCategoryEditing(state, value) {
      state.modalCategoryEditing = value
    },
    setModalSection(state, status) {
      state.modalSection = status
    },
    setModalSectionEditing(state, value) {
      state.modalSectionEditing = value
    },
    setModalContract(state, status) {
      state.modalContract = status
    },
  },
  actions: {
    async getContracts({ commit }, {search = null, start = 0, limit = 20} = {}) {
			start = isNaN(start) ? 0 : start
      commit('setLoadingCompanyContractsList', true)
      const data = {
				search,
        start,
        limit
      }
      const contracts = await getCompanyContracts(data)
      if (contracts.code === 200) {
        commit('setCompanyContractsList', [])
				setTimeout(() => {
					commit('setCompanyContractsList', contracts.contracts)
				}, 50);
      }
      commit('setLoadingCompanyContractsList', false)
    },
    async getCompanyContractsConfig({ commit }, data) {
      commit('setLoadingCompanyContracts', true)
      const section = await getCompanyContractSections(data)
      if (section.code === 200) {
        commit('setCompanyContracts', section.contract_sections)
      }
      commit('setLoadingCompanyContracts', false)
    },
    async getContractTemplates({ commit }, data) {
      commit('setLoadingTemplateContracts', true)
      const templates = await getContractTemplates(data)
      if (templates.code === 200) {
        commit('setTemplateContracts', templates.contracts)
      }
      commit('setLoadingTemplateContracts', false)
    },
    async getContractTitles({ commit }, data) {
      commit('setLoadingTitleContracts', true)
      const titles = await getContractTitles(data)
      if (titles.code === 200) {
        commit('setTitleContracts', titles.company_contract_titles)
      }
      commit('setLoadingTitleContracts', false)
    },
    async getCompanyContractCategoriesConfig({ commit }, data) {
      commit('setLoadingCompanyContractCategories', true)
      const categories = await getCompanyContractSectionCategories(data)
      if (categories.code === 200) {
        commit('setCompanyContractCategories', categories.contract_categories)
      }
      commit('setLoadingCompanyContractCategories', false)
    }
  }
}

export default ChatStore
