<template>
<div class="contract-container flex-grow-1 pb-0" v-if="contract && selectedCompany">
	<div class="d-flex justify-end pa-4 pb-0" v-if="showEdit">
		<v-btn
			depressed
			color="warning"
			dark
			outlined
			class="mb-2"
			x-small
			@click="toContractEdit"
		>
			<v-icon small>mdi-pencil-outline</v-icon>
			<p class="mb-0 ml-2 caption">Ir A Edición de Contrato</p>
		</v-btn>
	</div>
	<div class="py-2 px-4 d-flex justify-space-between">
		<div class="d-flex justify-space-between">
			<img :src="selectedCompany.company.logo" style="width: 120px;"/>
			<div class="px-2">
				<p class="font-weight-bold mb-0" style="color: #606060;font-size: 19.6px !important">{{$store.state.companies.selectedCompany.company.name}}</p>
				<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.description}}</p>
				<p class="caption mb-0" style="color: #606060;">Rut: {{$store.state.companies.selectedCompany.company.rut | formatRut}}</p>
				<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.phone}}</p>
				<p class="caption mb-0" style="color: #606060;">{{$store.state.companies.selectedCompany.company.email}}</p>
			</div>
		</div>
		<div>
			<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
				<div style="width: 120px">Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">{{ contract.code }} - {{ contract.created_at | toYear }}</b>
			</div>
			<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
				<div style="width: 120px">Fecha Cotización</div><span>:</span> <b class="text-end ml-3" style="flex: 1;">{{ contract.created_at | toDate }}</b>
			</div>
			<div class="d-flex justify-space-between mb-1 px-1 item-background-hover caption">
				<div style="width: 120px">Fecha Inicio</div><span>:</span>
				<b class="text-end ml-3" style="flex: 1;" v-if="contract.start_date">{{ contract.start_date | toDate }}</b>
				<b class="text-end ml-3" style="flex: 1;" v-else>A Convenir</b>
			</div>
		</div>
	</div>
	<div class="py-2 px-4" v-for="(section, index) in contract.sections" :key="index">
		<div class="section-container rounded">
			<div class="pa-4">
				<div class="d-flex align-center mb-3 justify-space-between">
					<div class="d-flex flex-grow-1 justify-space-between">
						<p class="mb-0 font-weight-bold text-center flex-grow-1" v-if="section.section_type == 1">{{section.title}}</p>
						<p class="mb-0 font-weight-bold" v-else>{{ sectionIndex(index) }} {{section.title}}</p>
					</div>
				</div>
				<div v-for="(section_content, indexB) in section.contents" :key="'a'+indexB">
					<div class="mb-4">
						<v-hover v-slot="{ hover }">
							<div class="section-content-container">
								<div class="px-2">
									<div v-if="section_content.show_title" class="font-weight-bold">{{ (indexB + 1) + '.- ' }} {{ section_content.title }}</div>
									<div class="section-content pa-2" v-html="transformPlaceholdersToFriendlyView(section_content.content)"></div>
								</div>
								<v-tooltip left>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-show="hover"
											depressed
											color="warning"
											class="my-2 mx-2 add-comment-icon"
											large
											icon
											@click="menuComment = section_content.id"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon>mdi-comment-plus-outline</v-icon>
										</v-btn>
									</template>
									<span>Ver / Agregar Comentarios en Sección</span>
								</v-tooltip>
								<v-card v-if="menuComment == section_content.id" class="card-comment-container" color="white" width="400">
									<v-card-text style="background: white">
										<v-list dense class="py-0">
											<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
												<p class="caption mb-0 font-weight-bold white--text">Observaciones en Sección</p>
											</v-list-item>
											<v-list-item-group color="#524D4D" v-if="section_content.observation">
												<v-list-item
													v-for="(comment, index) in section_content.observation.comments"
													:key="index"
													dense
												>
													<v-list-item-title dense>
														<div class="d-flex justify-space-between item-background-hover py-2">
															<div class="d-flex">
																<v-icon :color="comment.side == 1 ? 'warning':''">mdi-comment-plus-outline</v-icon>
																<div class="ml-2">
																	<div class="font-weight-bold caption">{{ comment.user.profile.name }} {{ comment.user.profile.lastname }} <small>({{ comment.side == 1 ? 'Cliente':'Empresa' }})</small></div>
																	<div class="caption">{{ comment.comment }}</div>
																</div>
															</div>
															<v-tooltip bottom>
																<template v-slot:activator="{ on, attrs }">
																	<v-btn
																		fab
																		dark
																		x-small
																		icon
																		color="error"
																		@click="deleteComment(comment)"
																		v-bind="attrs"
																		v-on="on"
																		v-if="userInfo && userInfo.id == comment.user_id"
																	>
																		<v-icon small dark>mdi-delete-outline</v-icon>
																	</v-btn>
																</template>
																<span>Eliminar Comentario</span>
															</v-tooltip>
														</div>
													</v-list-item-title>
												</v-list-item>
											</v-list-item-group>
										</v-list>
										<v-divider class="my-2"></v-divider>
										<div >
											<v-text-field
												label="Ingrese Comentario"
												v-model="sectionComment"
												class="custom-height-search custom-height-calendar mb-2"
												hide-details
												dense
												outlined
												:rules="requiredRule"
											></v-text-field>
											<v-btn
												depressed
												color="green"
												:dark="sectionComment"
												small
												block
												:disabled="!sectionComment"
												@click="saveComment"
											>
												<v-icon small>mdi-check</v-icon>
												<p class="mb-0 ml-2 caption">Confirmar</p>
											</v-btn>
											<v-btn
												depressed
												color="#959595"
												text
												x-small
												block
												class="mt-2"
												@click="closeCommentForm"
											>
												<p class="mb-0">Cerrar</p>
											</v-btn>
										</div>
									</v-card-text>
								</v-card>
							</div>
						</v-hover>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { getContract, createContractContentComment, deleteContractContentComment } from '@/helpers/api/contracts'

export default {
  data() {
    return {
      search: '',
			sectionComment: null,
			menuComment: null,
			contract: null,
			loading: true,
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			placeholderMapping: {
				'{contract_start_date}': 'Fecha Inicio',
				'{contract_date}': 'Fecha del Contrato',
				'{address}': 'Dirección del Proyecto',
				'{district}': 'Municipio del Proyecto',
				'{client_name}': 'Nombre Cliente',
				'{client_dni}': 'Rut Cliente',
				'{company_name}': 'Nombre Empresa',
				'{company_rut}': 'Rut Empresa',
				'{company_responsible}': 'Nombre Representante',
				'{company_responsible_rut}': 'Rut Representante',
				'{company_address}': 'Dirección Empresa',
			}
    };
  },
	props: {
		contract_info: {
			type: Object,
			default: null
		},
		contract_id: {
			type: Number,
			default: null
		},
		showEdit: {
			type: Boolean,
			default: false
		},
	},
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		userInfo(){
			return this.$store.state.user.userInfo
		}
  },
	watch: {
	},
	mounted(){
		this.contract = this.contract_info
		if(!this.contract){
			this.getContractInfo()
		}
	},
  methods: {
		async deleteComment(comment){
			await deleteContractContentComment({contract_content_comment_id: comment.id})
			this.getContractInfo()
			this.sectionComment = null
		},
		closeCommentForm(){
			this.menuComment = null
		},
		async saveComment(){
			await createContractContentComment({contract_content_id: this.menuComment, comment: this.sectionComment, side: this.showEdit ? 2:1})
			this.getContractInfo()
			this.sectionComment = null
		},
		toContractEdit(){
			this.$router.push({ name: 'ContractDetail', query: { id: this.contract.id } })
		},
		async getContractInfo(){
			if(this.contract_id){
				const contract = await getContract({contract_id: this.contract_id})
				if(contract.code == 200){
					this.contract = contract.contract
				}
			}
		},
		sectionIndex(index){
			return '' +  this.$store.state.general.romanNumerals[index] + '. ' + this.$store.state.general.ordinalsNumbers[index] + ':'
		},
		transformPlaceholdersToFriendlyView(content) {
			let result = content;
			for (const [placeholder, label] of Object.entries(this.placeholderMapping)) {
				const regex = new RegExp(placeholder, 'g');
				result = result.replace(regex, `<span class="friendly-placeholder" style="font-size: 12px;padding: 1px 6px;border: 1px solid grey;border-radius: 5px;background: white;color:orange;" contenteditable="false">${label}</span>`);
			}
			return result;
		},
  },
};
</script>

<style scoped>
.contract-container{
	background-color: white;
}
.section-content-container{
	position: relative;
}
.add-comment-icon{
	position: absolute;
	bottom: 0;
	right: 0;
	margin: auto;
	background: white;
}
.card-comment-container{
	position: absolute;
	top: 0;
	right: 60px;
	background: white;
	z-index: 9999;
}
</style>