import {getBudgetConcepts, getBudgetCompanyCategories, getBudgetCompanyDetails, getCompanyBudgetNoteTemplates, getCompanyBudgets} from '@/helpers/api/budgets'

const messagesStore = {
  namespaced: true,
  state: {
		loadingBudgetConcepts: false,
		companiesBudgetConcepts: [],
		newBudgetConceptModal: false,
		newBudgetConceptModalEditing: null,
		loadingBudgetCategories: false,
		companiesBudgetCategories: [],
		newBudgetCategoryModal: false,
		newBudgetCategoryModalEditing: null,
		loadingBudgetDetails: false,
		companiesBudgetDetails: [],
		loadingBudgetNotes: false,
		companiesBudgetNotes: [],
		newBudgetDetailModal: false,
		newBudgetDetailModalEditing: null,
		newBudgetNoteTemplateModal: false,
		newBudgetNoteTemplateModalEditing: null,
		loadingCompanyBudgets: true,
		companyBudgets: [],
		modalNewBudget: false
  },
  mutations: {
		setNewBudgetConceptModal(state, value){
			state.newBudgetConceptModal = value
		},
		setNewBudgetConceptModalEditing(state, value){
			state.newBudgetConceptModalEditing = value
		},
		setLoadingBudgetConcepts(state, value){
			state.loadingBudgetConcepts = value
		},
		setCompaniesBudgetConcepts(state, value){
			state.companiesBudgetConcepts = value
		},
		setNewBudgetCategoryModal(state, value){
			state.newBudgetCategoryModal = value
		},
		setNewBudgetCategoryModalEditing(state, value){
			state.newBudgetCategoryModalEditing = value
		},
		setLoadingBudgetCategories(state, value){
			state.loadingBudgetCategories = value
		},
		setCompaniesBudgetCategories(state, value){
			state.companiesBudgetCategories = value
		},
		setNewBudgetDetailModal(state, value){
			state.newBudgetDetailModal = value
		},
		setNewBudgetDetailModalEditing(state, value){
			state.newBudgetDetailModalEditing = value
		},
		setLoadingBudgetDetails(state, value){
			state.loadingBudgetDetails = value
		},
		setCompaniesBudgetDetails(state, value){
			state.companiesBudgetDetails = value
		},
		setNewBudgetNoteTemplateModal(state, value){
			state.newBudgetNoteTemplateModal = value
		},
		setNewBudgetNoteTemplateModalEditing(state, value){
			state.newBudgetNoteTemplateModalEditing = value
		},
		setLoadingBudgetNotes(state, value){
			state.loadingBudgetNotes = value
		},
		setCompaniesBudgetNotes(state, value){
			state.companiesBudgetNotes = value
		},
		setLoadingCompanyBudgets(state, value){
			state.loadingCompanyBudgets = value
		},
		setCompanyBudgets(state, value){
			state.companyBudgets = value
		},
		setModalNewBudget(state, value){
			state.modalNewBudget = value
		},
  },
  actions: {
    async getBudgetConcepts({ commit }) {
      commit('setLoadingBudgetConcepts', true)
      const data = {
        start: 0,
        limit: 300
      }
      const concepts = await getBudgetConcepts(data)
      if (concepts.code === 200) {
        commit('setCompaniesBudgetConcepts', concepts.budget_concepts)
      }
      commit('setLoadingBudgetConcepts', false)
    },
    async getBudgetCategories({ commit }) {
      commit('setLoadingBudgetCategories', true)
      const data = {
        start: 0,
        limit: 300
      }
      const categories = await getBudgetCompanyCategories(data)
      if (categories.code === 200) {
        commit('setCompaniesBudgetCategories', categories.budget_categories)
      }
      commit('setLoadingBudgetCategories', false)
    },
    async getBudgetDetails({ commit }) {
      commit('setLoadingBudgetDetails', true)
      const data = {
        start: 0,
        limit: 300
      }
      const details = await getBudgetCompanyDetails(data)
      if (details.code === 200) {
        commit('setCompaniesBudgetDetails', details.budget_details)
      }
      commit('setLoadingBudgetDetails', false)
    },
    async getBudgetNotes({ commit }) {
      commit('setLoadingBudgetNotes', true)
      const data = {
        start: 0,
        limit: 300
      }
      const notes = await getCompanyBudgetNoteTemplates(data)
      if (notes.code === 200) {
        commit('setCompaniesBudgetNotes', notes.notes)
      }
      commit('setLoadingBudgetNotes', false)
    },
    async getCompanyBudgets({ commit }, {search = null, start = 0, limit = 20} = {}) {
      commit('setLoadingCompanyBudgets', true)
      const data = {
				search,
        start,
        limit
      }
      const budgets = await getCompanyBudgets(data)
      if (budgets.code === 200) {
        commit('setCompanyBudgets', budgets.budgets)
      }
      commit('setLoadingCompanyBudgets', false)
    },
  }
}

export default messagesStore
