<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.companies.newCompanyModal"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" class="info-icon mr-2">mdi-information-symbol</v-icon>
								<p class="mb-0 font-weight-bold text-primary title">Creación de Empresas</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4 mt-2">
                <v-col cols="12" md="2" class="py-0">
									<v-avatar
										size="80"
										class="profile-avatar"
										@click="triggerFileInput"
									>
										<img :src="logoUrl" class="user-avatar" v-if="logoUrl && logoUrl"/>
										<v-icon v-else class="user-avatar" color="#524D4D" x-large>mdi-image</v-icon>
										<div class="logo-text">Modificar Logo</div>
									</v-avatar>
                  <v-file-input
										ref="fileInputLogo"
                    v-model="newCompany.logo"
                    show-size
                    outlined
                    dense
                    accept=".png,.jpg,.jpeg"
                    placeholder="Seleccione imagen de perfil"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
										style="display: none;"
										@change="onFileSelected"
                  ></v-file-input>
								</v-col>
								<v-col cols="12" md="10" class="py-0">
									<v-row>
										<v-col cols="12" class="py-0">
											<v-text-field
												v-model="newCompany.legal_name"
												label="Nombre Legal (SII)"
												placeholder="Ingrese el nombre legal de la empresa"
												outlined
												required
												dense
												:rules="requiredRule"
											></v-text-field>
										</v-col>
										<v-col cols="12" class="py-0">
											<v-text-field
												v-model="newCompany.name"
												label="Nombre de Fantasía"
												placeholder="Ingrese nombre de fantasía de la empresa"
												outlined
												required
												dense
												:rules="requiredRule"
											></v-text-field>
										</v-col>
									</v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newCompany.rut"
                    label="Rut"
                    placeholder="Ingrese el rut de la empresa"
                    outlined
                    required
                    dense
										hint="Ingresa rut sin puntos ni guión"
                    :rules="rutRule"
										@blur="onBlurRut"
										@focus="onFocusRut"
										maxlength="9"
                  ></v-text-field>
								</v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newCompany.description"
                    label="Descripción"
                    placeholder="Ingrese una descripción para la empresa"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
								</v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newCompany.email"
                    label="Email"
                    placeholder="Ingrese email de contacto"
                    outlined
                    required
                    dense
                    :rules="emailRules"
                  ></v-text-field>
								</v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newCompany.phone"
                    label="Teléfono"
                    placeholder="Ingrese teléfono de contacto (Ej: 56912345678)"
                    outlined
                    required
                    dense
                    :rules="phoneRule"
										maxlength="11"
										@keypress="isNumberKey"
                  ></v-text-field>
								</v-col>
								<v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newCompany.address"
                    label="Dirección"
                    placeholder="Ingrese direccción completa de la empresa"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
								<v-col cols="12" class="py-0">
									<v-select
										v-model="newCompany.state_id"
										:items="$store.state.general.states"
										:loading="$store.state.general.loadingStates"
										:menu-props="{ top: true, offsetY: true }"
										item-text="name"
										item-value="id"
										label="Seleccione Región"
										outlined
										dense
										required
										:rules="requiredRule"
										@change="onChangeState"
									></v-select>
                </v-col>
								<v-col cols="12" class="py-0">
									<v-select
										v-model="newCompany.city_id"
										:items="$store.state.general.cities"
										:loading="$store.state.general.loadingCities"
										item-text="name"
										item-value="id"
										label="Seleccione Comuna"
										outlined
										dense
										required
										:disabled="!newCompany.state_id"
										:rules="requiredRule"
									></v-select>
                </v-col>
								<v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newCompany.url"
                    label="Url sitio web"
                    placeholder="Ingrese url del sitio web"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
									<v-select
										v-model="newCompany.responsible"
										:items="$store.state.users.usersList"
										:loading="$store.state.users.loadingUserList"
										item-text="profile.name"
										item-value="id"
										label="Usuario responsable"
										outlined
										dense
										required
										hide-details
										:rules="requiredRule"
									>
										<template v-slot:item="data">
											<v-list-item-content>
												<v-list-item-title>{{data.item.profile.name}} - <span class="light-blue-text">[{{data.item.email}}]</span></v-list-item-title>
											</v-list-item-content>
										</template>
										<template #append-outer>
											<v-btn
												class="my-0"
												icon
												@click="addUser"
											>
												<v-icon>mdi-account-plus</v-icon>
											</v-btn>
										</template>
									</v-select>
								</v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {createCompany, updateCompany} from '@/helpers/api/company'
import {validate, format, clean} from '@/helpers/rut.js'

export default {
  name: 'CompanyModal',
  components: { },
  data: () => ({
    validform: true,
		loadingData: false,
    newCompany: {
			company_id: null,
			name: null,
			description: null,
			logo: null,
			url: null,
			rut: null,
			address: null,
			legal_name: null,
			responsible: null,
			state_id: null,
			phone: null,
			email: null,
			city_id: null
    },
		logoUrl: null,
		rutRule: [
			v => validate(v) || 'Rut no válido'
		],
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    phoneRule: [
			v => !!v || 'Campo requerido',
			v => /^569\d{8}$/.test(v) || 'Debe tener 11 dígitos y empezar con 569',
    ],
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ],
  }),
  mounted(){
		if(this.$store.state.companies.newCompanyModalEditing){
			this.newCompany = this.$store.state.companies.newCompanyModalEditing
			this.logoUrl = this.$store.state.companies.newCompanyModalEditing.logo
			// this.newCompany.responsible = this.$store.state.user.userInfo.id
			this.newCompany.logo = null
			if(this.newCompany.state_id){
				this.onChangeState(this.newCompany.state_id)
			}
		}
		this.$store.dispatch('users/getUserFromCompany')
  },
  methods: {
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
		onBlurRut(){
			if(validate(this.newCompany.rut)){
				this.newCompany.rut = format(this.newCompany.rut)
			}
		},
		onFocusRut(){
			if(validate(this.newCompany.rut)){
				this.newCompany.rut = clean(this.newCompany.rut)
			}
		},
		onChangeState(state){
			this.$store.dispatch('general/getCities', state)
		},
    addUser() {
			this.$store.commit('users/setNewUserModalEditing', null)
			this.$store.commit('users/setNewUserModal', true)
    },
    triggerFileInput() {
      this.$refs.fileInputLogo.$el.querySelector('input[type="file"]').click();
    },
    onFileSelected(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.logoUrl = e.target.result
        };
        reader.readAsDataURL(file)
      }
    },
    closeModal(){
      this.$store.commit('companies/setNewCompanyModal', false)
			this.$store.commit('companies/setNewCompanyModalEditing', null)
      this.newCompany = {
				company_id: null,
				name: null,
				description: null,
				logo: null,
				url: null,
				rut: null,
				address: null,
				legal_name: null,
				responsible: null,
				phone: null,
				email: null,
				state_id: null,
				city_id: null
			}
      this.validform = true
      this.loadingData = false
    },
    async sendForm(){
      if(this.$refs.form.validate()){
				this.loadingData = true
        const formData = new FormData()
        let newCompany = this.newCompany
				newCompany.rut = clean(newCompany.rut)
        Object.keys(newCompany).forEach((key) => {
          if(newCompany[key]) {
            formData.append(key, newCompany[key])
          }
        })
        const company = this.newCompany.company_id ? await updateCompany(formData):await createCompany(formData)
        if(company.code === 200){
          const messageData = {
            message: 'Se ha creado correctamente la empresa',
            title: 'Empresa creada',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
					this.$store.dispatch('companies/getCompaniesList')
          this.setMessage(messageData)
          this.closeModal()
        }
        else{
          const messageData = {
            message: 'Hubo un error en la operación, por favor intenta nuevamente',
            title: 'Ha ocurrido un error',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#F4516C',
          }
          this.setMessage(messageData)
        }
				this.loadingData = false
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
		position: relative;
	}
	.logo-text{
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		color: white;
		font-size: 12px;
		padding-bottom: 10px;
		padding-top: 3px;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>