<template>
  <v-container fluid>
		<v-row v-if="loading">
			<v-col col="12">
				
			</v-col>
		</v-row>
		<v-row v-else-if="cost_categories.length == 0">
			<v-col col="12">
				<div class="d-flex  flex-column align-center">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="green"
								large
								icon
								v-bind="attrs"
								v-on="on"
								@click="addCategory"
							>
								<v-icon large>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Agregar Categoría de Costo</span>
					</v-tooltip>
					<p class="mb-0 caption font-weight-bold mr-2">No se ha creado nada en esta tabla de costo, crea tu primera categoría con el botón +</p>
				</div>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col cols="8">
				<v-form ref="form" v-model="validform">
					<v-row>
						<v-col cols="3">
							<p class="font-weight-bold caption mb-0">Concepto</p>
						</v-col>
						<v-col cols="2">
							<p class="font-weight-bold caption mb-0">Valor Unitario (UF)</p>
						</v-col>
						<v-col cols="2">
							<p class="font-weight-bold caption mb-0">Cantidad</p>
						</v-col>
						<v-col cols="2">
							<p class="font-weight-bold caption mb-0 text-center">Total UF</p>
						</v-col>
						<v-col cols="2">
							<p class="font-weight-bold caption mb-0 text-center">Total Pesos</p>
						</v-col>
						<v-col cols="1">
						</v-col>
					</v-row>
					<div class="py-4" v-for="(category, i1) in cost_categories" :key="i1">
						<div class="d-flex align-center pl-2 pt-1 pb-0 mb-0">
							<v-text-field
								v-model="category.name"
								label="Categoría"
								outlined
								required
								dense
								hide-details
								:loading="loadingBudget"
								class="custom-height-search custom-height-calendar"
								style="max-width: 400px;"
								:rules="requiredRule"
								@input="updateCategory(i1)"
							></v-text-field>
							<!-- <p class="mb-0 caption font-weight-bold mr-2">{{ category.name }}</p> -->
							<div class="d-flex align-center">
								<!-- <v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											color="warning"
											small
											icon
											v-bind="attrs"
											v-on="on"
										>
											<v-icon small>mdi-pencil-outline</v-icon>
										</v-btn>
									</template>
									<span>Editar Categoría de Costo</span>
								</v-tooltip> -->
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											color="#FF120B"
											small
											icon
											v-bind="attrs"
											v-on="on"
											@click="deleteCategory(category.id, i1)"
										>
											<v-icon small>mdi-delete-outline</v-icon>
										</v-btn>
									</template>
									<span>Eliminar Categoría de Costo</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											color="green"
											small
											icon
											v-bind="attrs"
											v-on="on"
											@click="addCategory"
										>
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</template>
									<span>Agregar Categoría de Costo</span>
								</v-tooltip>
							</div>
						</div>
						<div v-if="!category.items || (category.items && category.items.length == 0)" class="d-flex justify-center">
							<v-btn
								depressed
								color="#524D4D"
								small
								outlined
								@click="addSubcategory(i1)"
							>
								<v-icon>mdi-plus</v-icon>
								<p class="mb-0 ml-2 caption">Agregar Subcategoría</p>
							</v-btn>
						</div>
						<div v-for="(subcategory, i2) in category.items" :key="i2">
							<v-divider class="my-2 ml-2"></v-divider>
							<div class="d-flex align-center pl-4 pt-3 pb-2 mb-6">
								<v-text-field
									v-model="subcategory.name"
									label="Subcategoría"
									outlined
									required
									dense
									hide-details
									:loading="loadingBudget"
									class="custom-height-search custom-height-calendar"
									style="max-width: 400px;"
									:rules="requiredRule"
									@input="updateSubcategory(i1, i2)"
								></v-text-field>
								<!-- <p class="mb-0 caption font-weight-bold mr-2">{{ subcategory.name }}</p> -->
								<div class="d-flex align-center">
									<!-- <v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="warning"
												small
												icon
												v-bind="attrs"
												v-on="on"
											>
												<v-icon small>mdi-pencil-outline</v-icon>
											</v-btn>
										</template>
										<span>Editar Subcategoría</span>
									</v-tooltip> -->
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="#FF120B"
												small
												icon
												v-bind="attrs"
												v-on="on"
												@click="deleteSubcategory(subcategory.id, i1, i2)"
											>
												<v-icon small>mdi-delete-outline</v-icon>
											</v-btn>
										</template>
										<span>Eliminar Subcategoría</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="green"
												small
												icon
												v-bind="attrs"
												v-on="on"
												@click="addSubcategory(i1)"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</template>
										<span>Agregar Subcategoría</span>
									</v-tooltip>
								</div>
							</div>
							<div v-for="(cost_item, i3) in subcategory.items" :key="i3">
								<v-row>
									<v-col cols="3">
										<v-text-field
											v-model="cost_item.concept"
											label="Concepto"
											outlined
											required
											dense
											hide-details
											:loading="loadingBudget"
											class="pl-6 custom-height-search custom-height-calendar mr-2"
											:rules="requiredRule"
											@input="updateItem(i1, i2, i3)"
										></v-text-field>
									</v-col>
									<v-col cols="2">
										<v-text-field
											v-model="cost_item.unit_cost"
											label="Valor Unitario (UF)"
											outlined
											required
											dense
											hide-details
											:loading="loadingBudget"
											class="custom-height-search custom-height-calendar"
											@keypress="isNumberKey"
											@input="updateItem(i1, i2, i3)"
											:rules="requiredRule"
										></v-text-field>
									</v-col>
									<v-col cols="2">
										<v-text-field
											v-model="cost_item.quantity"
											label="Cantidad"
											outlined
											required
											dense
											hide-details
											:loading="loadingBudget"
											class="custom-height-search custom-height-calendar"
											@keypress="isNumberKey"
											@input="updateItem(i1, i2, i3)"
											:rules="requiredRule"
										></v-text-field>
									</v-col>
									<v-col cols="2" align-self="center">
										<p class="font-weight-bold caption mb-0 text-center">{{ cost_item.total_uf | toThousandFilter }}</p>
									</v-col>
									<v-col cols="2" align-self="center">
										<p class="font-weight-bold caption mb-0 text-center">${{ cost_item.total_clp | toThousandFilter }}</p>
									</v-col>
									<v-col cols="1" align-self="center">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													depressed
													color="#FF120B"
													small
													icon
													v-bind="attrs"
													v-on="on"
													@click="deleteItem(cost_item.id, i1, i2, i3)"
												>
													<v-icon small>mdi-delete-outline</v-icon>
												</v-btn>
											</template>
											<span>Eliminar Item de Costo</span>
										</v-tooltip>
									</v-col>
								</v-row>
							</div>
							<div class="d-flex justify-center my-4">
								<v-btn
									depressed
									color="#524D4D"
									small
									outlined
									@click="addItem(i1, i2)"
								>
									<v-icon>mdi-plus</v-icon>
									<p class="mb-0 ml-2 caption">Agregar Item de Costo a Subcategoría</p>
								</v-btn>
							</div>
						</div>
						<v-row class="mt-4" style="background-color: #d3d3d3;">
							<v-col cols="7">
								<p class="font-weight-bold caption text-right mb-0">Totales Categoría {{ category.name }}</p>
							</v-col>
							<v-col cols="2">
								<p class="font-weight-bold caption mb-0 text-center">{{ totalUf(category) | toThousandFilter }}</p>
							</v-col>
							<v-col cols="2">
								<p class="font-weight-bold caption mb-0 text-center">{{ totalClp(category) | toThousandFilter }}</p>
							</v-col>
							<v-col cols="1">
							</v-col>
						</v-row>
					</div>
				</v-form>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
import {getCostCategory, saveAllCostCategory, deleteCostCategory, deleteCostSubcategory, deleteCostItem} from '@/helpers/api/costs'

export default {
  data() {
    return {
			validform: true,
			editingCategory: null,
			editingSubcategory: null,
			cost_categories: [],
			loading: true,
      loadingBudget: false,
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			limit: 10,
			start: 0,
    };
  },
	computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		ufValue(){
			return this.$store.state.general.dayUfValue
		}
	},
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.getCosts()
			}
		},
	},
	mounted(){
		if(this.selectedCompany){
			this.getCosts()
		}
	},
  methods: {
		async getCosts(){
			const project_id = this.$route.params.id
			const costs = await getCostCategory({project_id})
			if(costs.code == 200){
				this.cost_categories = costs.cost_category
			}
			this.loading = false
		},
		addCategory(){
			const new_category = {
				name: "",
				order: this.cost_categories.length + 1,
				items: []
			}
			this.cost_categories.push(new_category)
		},
		addSubcategory(category_index){
			const category = this.cost_categories[category_index]
			const new_subcategory = {
				name: "",
				order: category.items ? category.items.length + 1:0,
				cost_category_id: category.id,
				items: []
			}
			category.items.push(new_subcategory)
		},
		addItem(category_index, subcategory_index){
			const subcategory = this.cost_categories[category_index].items[subcategory_index]
			const new_item = {
				concept: "",
				unit_cost: null,
				quantity: 1,
				total_uf: null,
				total_clp: null,
				order: subcategory.items ? subcategory.items.length + 1:0,
				cost_subcategory_id: subcategory.id
			}
			subcategory.items.push(new_item)
		},
		updateCategory(){
			this.startSaveCost()
		},
		updateSubcategory(){
			this.startSaveCost()
		},
		updateItem(category_index, subcategory_index, item_index){
			const item = this.cost_categories[category_index].items[subcategory_index].items[item_index]
			this.$set(item, 'total_uf', item.quantity * item.unit_cost)
			this.$set(item, 'total_clp', item.quantity * item.unit_cost * this.ufValue)
			this.startSaveCost()
		},
		startSaveCost: _debounce(function() { this.saveCost() }, 800),
		saveCost(){
      if(this.$refs.form.validate()){
				const data = {
					item_type: "project",
					item_id : this.$route.params.id,
					cost_categories: this.cost_categories
				}
				saveAllCostCategory(data)
			}
		},
		totalUf(category){
			if(category.items && category.items.length > 0){
				const totalUF = category.items.reduce((sum, cat) => {
					return sum + cat.items.reduce((subSum, item) => subSum + (item.total_uf || 0), 0);
				}, 0).toFixed(2)
				return totalUF
			}
			return 0
		},
		totalClp(category){
			if(category.items && category.items.length > 0){
				const totalUF = category.items.reduce((sum, cat) => {
					return sum + cat.items.reduce((subSum, item) => subSum + (item.total_clp || 0), 0);
				}, 0).toFixed(2)
				return totalUF
			}
			return 0
		},
		async deleteCategory(cost_category, index){
			if(!cost_category){
				this.cost_categories.splice(index, 1)
				return
			}
			await deleteCostCategory({cost_category})
			this.getCosts()
		},
		async deleteSubcategory(cost_subcategory_id, category_index, subcategory_index){
			if(!cost_subcategory_id){
				this.cost_categories[category_index].items.splice(subcategory_index, 1)
				return
			}
			await deleteCostSubcategory({cost_subcategory_id})
			this.getCosts()
		},
		async deleteItem(cost_item_id, category_index, subcategory_index, item_index){
			if(!cost_item_id){
				this.cost_categories[category_index].items[subcategory_index].items.splice(item_index, 1)
				return
			}
			await deleteCostItem({cost_item_id})
			this.getCosts()
		},
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			const input = event.target.value;
			if (charCode >= 48 && charCode <= 57) {
				return;
			}
			if (charCode === 46) {
				if (input.includes('.') || input.length === 0) {
					event.preventDefault();
				}
				return;
			}
			event.preventDefault()
		}
  },
};
</script>

<style scoped>
</style>