<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.projects.projectModal"
      max-width="700"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
								<div>
									<p class="mb-0 font-weight-bold text-primary title">Proyecto</p>
									<p class="mb-0 text-primary caption">Estás creando un <b>{{ this.project.doc_type == 1 ? 'Contrato':'Presupuesto' }}</b> para el proyecto</p>
								</div>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
							<v-row>
								<v-col class="py-2">
									<v-select
										v-model="project.client_id"
										:items="$store.state.users.companyClients"
										:loading="$store.state.users.loadingCompanyClients"
										:menu-props="{ top: true, offsetY: true }"
										item-text="profile.name"
										item-value="id"
										label="Seleccione Cliente"
										outlined
										dense
										hide-details
										color="secondary"
										@change="changeClient"
									>
										<template v-slot:item="data">
											<v-list-item-content>
												<v-list-item-title>{{data.item.profile.name}} - <span class="light-blue-text">[{{data.item.email}}]</span></v-list-item-title>
											</v-list-item-content>
										</template>
										<!-- <template #append-outer>
											<v-btn
												class="my-0"
												icon
												@click="addUser"
											>
												<v-icon>mdi-account-plus</v-icon>
											</v-btn>
										</template> -->
									</v-select>
									<div class="mt-1">
										<p class="caption mb-0" v-if="clientInfo"><b>Datos del usuario:</b> Email: {{clientInfo.email}} | Teléfono: {{clientInfo.profile.phone}}</p>
										<p class="caption mb-0" v-else>Seleccione usuario para visualizar más datos</p>
									</div>
								</v-col>
							</v-row>

							<v-row>
								<v-col class="py-2">
									<v-select
										v-model="projectName"
										:items="clientProjects"
										:loading="loadingClientProjects"
										:menu-props="{ top: true, offsetY: true }"
										item-text="name"
										item-value="id"
										label="Seleccione Proyecto de Cliente"
										outlined
										dense
										hide-details
										color="secondary"
										@change="changeProject"
									>
										<template v-slot:item="data">
											<v-list-item-content>
												<v-list-item-title>{{data.item.name}}</v-list-item-title>
											</v-list-item-content>
										</template>
									</v-select>
									<!-- <v-text-field
										label="Nombre Proyecto"
										v-model="project.name"
										outlined
										hide-details
										required
										dense
										color="secondary"
										item-color="secondary"
										:rules="requiredRule"
									></v-text-field> -->
								</v-col>
							</v-row>

							<v-row>
								<v-col class="py-2">
									<v-autocomplete
										v-model="project.address"
										:items="[...items, ...[project.address]]"
										name="address"
										:search-input.sync="search"
										prepend-inner-icon="mdi-map-marker"
										label="Dirección"
										placeholder="Dirección"
										autocomplete="off"
										outlined
										dense
										hide-details
										:loading="loading"
										:filter="d => d"
										color="secondary"
										item-color="secondary"
										return-object
										@change="change"
									></v-autocomplete>
								</v-col>
							</v-row>

							<v-row>
								<v-col class="py-2">
									<v-select
										v-model="project.state_id"
										:items="$store.state.general.states"
										:loading="$store.state.general.loadingStates"
										:menu-props="{ top: true, offsetY: true }"
										item-text="name"
										item-value="id"
										label="Seleccione Región"
										outlined
										dense
										hide-details
										color="secondary"
										@change="onChangeState"
									></v-select>
								</v-col>
								<v-col class="py-2">
									<v-select
										v-model="project.district_id"
										:items="$store.state.general.cities"
										:loading="$store.state.general.loadingCities"
										item-text="name"
										item-value="id"
										label="Seleccione Municipio"
										outlined
										dense
										color="secondary"
										hide-details
									></v-select>
								</v-col>
							</v-row>

							<v-row>
								<v-col class="py-2">
									<v-select
										v-model="project.zone_id"
										:items="$store.state.projects.zoneIpt"
										:loading="$store.state.projects.loadingZoneIpt"
										:menu-props="{ top: true, offsetY: true }"
										item-text="name"
										item-value="id"
										label="Selecciona Zona Proyecto"
										outlined
										hide-details
										dense
										required
										color="secondary"
										:rules="requiredRule"
									></v-select>
								</v-col>
								<v-col class="py-2">
									<v-select
										v-model="project.project_type_id"
										:items="$store.state.companies.companiesProjectTypes"
										:loading="$store.state.companies.loadingCompaniesProjectTypes"
										:menu-props="{ top: true, offsetY: true }"
										item-text="name"
										item-value="id"
										label="Selecciona Tipo Proyecto"
										outlined
										hide-details
										dense
										required
										color="secondary"
										:rules="requiredRule"
									></v-select>
								</v-col>
							</v-row>

							<!-- <v-row>
								<v-col class="py-2">
									<v-text-field
										v-model="project.area"
										label="Superficie Proyecto"
										placeholder="Ingrese superficie del proyecto (m2)"
										outlined
										required
										dense
										hide-details
										maxlength="6"
										color="secondary"
										@keypress="isNumberKey"
									></v-text-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col class="py-2">
									<v-text-field
										label="Normativa General"
										v-model="project.general_regulations"
										outlined
										hide-details
										required
										dense
										color="secondary"
										:rules="requiredRule"
									></v-text-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col class="py-2">
									<v-text-field
										label="Plan Regulador"
										v-model="project.regulatory_plan"
										outlined
										hide-details
										required
										dense
										:rules="requiredRule"
									></v-text-field>
								</v-col>
							</v-row> -->

							<!-- <v-row>
								<v-col class="py-2">
									<v-text-field
										label="Normativa Específica"
										v-model="project.specific_regulations"
										outlined
										hide-details
										required
										dense
										color="secondary"
										:rules="requiredRule"
									></v-text-field>
								</v-col>
							</v-row> -->

							<!-- <v-row>
								<v-col class="py-2">
									<div class="d-flex align-center justify-space-between">
										<p class="mb-0 font-weight-bold caption">Incluye permiso municipal</p>
										<v-switch
											class="mt-0"
											color="#FF120B"
											dense
											hide-details
											v-model="project.licence"
										></v-switch>
									</div>
								</v-col>
								<v-col class="py-2">
									<div class="d-flex align-center justify-space-between">
										<p class="mb-0 font-weight-bold caption">Incluye recepción municipal</p>
										<v-switch
											class="mt-0"
											color="#FF120B"
											dense
											hide-details
											v-model="project.reception"
										></v-switch>
									</div>
								</v-col>
							</v-row> -->

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loading"
                      @click="confirmProject"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import {createProject, getUserProjects} from '@/helpers/api/projects'

export default {
  name: 'ProjectModal',
  data: () => ({
    validform: true,
		search: '',
		items: [],
		autocompleteService: null,
		clientProjects: null,
		loadingClientProjects: false,
		projectName: null,
		project: {
			name: null,
			client_id: null,
			address: null,
			address_id: null,
			state_id: null,
			district_id: null,
			zone_id: null,
			project_type_id: null,
			licence: false,
			reception: false,
			area: null,
			general_regulations: null,
			specific_regulations: null,
			regulatory_plan: null
		},
		loading: false,
		projectType: null,
		isGeneralStage: true,
		zoneId: null,
		newStageName: null,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
		google: gmapApi,
		clientInfo(){
			const found = this.$store.state.users.companyClients.find(user => user.id == this.project.client_id)
			console.log(found)
			return found
		}
  },
	watch: {
    search(newSearch) {
      if (newSearch) {
        this.googleSearch(newSearch);
      } else {
        this.items = []; // Limpiar los resultados si no hay búsqueda
      }
    },
	},
  mounted(){
		this.$nextTick(() => {
			this.$gmapApiPromiseLazy().then(() => {
				this.autocompleteService = new this.google.maps.places.AutocompleteService()
			})
			this.$store.dispatch('users/getCompanyClients')
		});
		if(this.$store.state.projects.projectModalInfo){
			this.startWithInfo()
		}
  },
  methods: {
		async startWithInfo(){
			this.project.client_id = this.$store.state.projects.projectModalInfo.client_id
			this.project.doc_type = this.$store.state.projects.projectModalInfo.doc_type
			console.log('this.project', this.project)
			const projects = await getUserProjects({client_id: this.project.client_id})
			if(projects.code == 200){
				this.clientProjects = projects.preproject
				this.projectName = this.$store.state.projects.projectModalInfo
				this.changeProject(this.$store.state.projects.projectModalInfo.id)
			}
		},
		changeProject(project){
			this.project.address = null
			this.project.address_id = null
			this.project.state_id = null
			this.project.district_id = null
			this.project.preproject_id = null
			const info = this.clientProjects.find(client_project => client_project.id == project)
			this.project.name = info.name
			this.project.preproject_id = info.id
			if(info && info.info){
				this.project.project_type_id = info.info.project_type_id
				if(info.info.address){
					this.project.address = info.info.address.address
					this.project.address_id = info.info.address_id
					if(info.info.address.state_id){
						this.project.state_id = info.info.address.state_id
						this.onChangeState(this.project.state_id)
						this.project.district_id = info.info.address.district_id
					}
				}
			}
		},
		async changeClient(){
			this.clientProjects = null
			this.loadingClientProjects = true
			const projects = await getUserProjects({client_id: this.project.client_id})
			if(projects.code == 200){
				this.clientProjects = projects.preproject
			}
			this.loadingClientProjects = false
		},
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.items = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.items = [];
        }
      });
    },
		async getPlaceDetails(placeId) {
			if (typeof this.google !== 'undefined' && this.google.maps) {
				const service = new this.google.maps.places.PlacesService(document.createElement('div'));
				
				service.getDetails({ placeId }, (place, status) => {
					if (status === this.google.maps.places.PlacesServiceStatus.OK) {
						const { geometry } = place;
						this.newSchedule.coordinates = {
							lat: geometry.location.lat(),
							lng: geometry.location.lng()
						}
					}
				});
			} else {
				console.error('Google Maps API no está cargada');
			}
		},
    change(value) {
			this.placeChanged = true
			this.getPlaceDetails(value.value.place_id)
      this.project.address = value.text;
			this.project.address_id = null
    },
		onChangeState(state){
			this.$store.dispatch('general/getCities', state)
		},
    closeModal(){
			this.$store.commit('projects/setProjectModalInfo', null)
			this.$store.commit('projects/setProjectModal', false)
			this.projectType = null
			this.zoneId = null
      this.validform = true
      this.loading = false
			this.project = {
				name: null,
				address: null,
				client_id: null,
				state_id: null,
				district_id: null,
				zone_id: null,
				project_type_id: null,
				licence: false,
				reception: false,
				area: null,
				general_regulations: null,
				specific_regulations: null,
				regulatory_plan: null
			}
    },
		async confirmProject(){
      if(this.$refs.form.validate()){
				this.loading = true
				const category = await createProject(this.project)
				if(category.code == 200){
					this.$store.dispatch('projects/getProjects')
					this.$store.commit('general/setNewCreatedElement', {type: 'project', value: category.project})
					this.closeModal()
          const messageData = {
            message: 'Se ha creado correctamente el proyecto',
            title: 'Proyecto Creado',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
          this.setMessage(messageData)
        }
        else{
          const messageData = {
            message: 'Hubo un error en la operación, por favor intenta nuevamente',
            title: 'Ha ocurrido un error',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#F4516C',
          }
          this.setMessage(messageData)
        }
				this.loading = false
			}
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
		position: relative;
		width: 200px;
	}
	.profile-image{
		width: 100%;
		height: 150px;
	}
	.logo-text{
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		color: white;
		font-size: 12px;
		padding: 5px 10px;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>