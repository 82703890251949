<template>
  <v-container fluid class="crm-conversation">
    <v-row class="mb-4" v-if="$store.state.users.loadingClient">
      <v-col cols="12">
				<div class="d-flex flex-column justify-center align-center">
					<v-progress-circular
						:size="50"
						:width="5"
						color="rgba(0,0,0,0.2)"
						class="mb-3"
						indeterminate
					></v-progress-circular>
					Cargando Información
				</div>
      </v-col>
    </v-row>
		<div v-else-if="client">
			<v-row>
				<v-col cols="7" v-show="!hideFirstCol">
					<v-card class="mb-4">
						<v-card-text class="py-2">
							<div class="d-flex align-center justify-space-between">
								<div class="d-flex align-center">
									<p class="font-weight-bold body-2 mb-0 mr-2">Datos Cliente</p>
									<!-- <v-btn
										icon
										color="#524D4D"
										@click="editUser = !editUser"
										:disabled="!editPermission"
									>
										<v-icon>{{editUser ? 'mdi-close':'mdi-account-box-edit-outline'}}</v-icon>
									</v-btn> -->
								</div>
							</div>
							<v-divider class="my-1"></v-divider>
							<div class="pa-2" v-if="client">
								<div v-if="editUser">
									<div class="d-flex justify-space-between align-center mb-1">
										<span class="mr-5">Nombre: </span>
										<v-text-field
											v-model="userData.name"
											outlined
											required
											dense
											hide-details
											style="max-width: 75%"
										></v-text-field>
									</div>
									<div class="d-flex justify-space-between align-center mb-1">
										<span class="mr-5">Email: </span>
										<v-text-field
											v-model="userData.email"
											outlined
											required
											dense
											hide-details
											style="max-width: 75%"
										></v-text-field>
									</div>
									<div class="d-flex justify-space-between align-center mb-1">
										<span class="mr-5">Teléfono: </span>
										<v-text-field
											v-model="userData.phone"
											outlined
											required
											dense
											style="max-width: 75%"
											:rules="phoneRule"
											maxlength="11"
											@keypress="isNumberKey"
										></v-text-field>
									</div>
									<v-btn
										depressed
										color="#524D4D"
										dark
										block
										small
										class="mt-3"
										:loading="loadingEditUser"
										@click="editUserData()"
									>
										<v-icon small>mdi-account-edit</v-icon>
										<p class="mb-0 ml-2 caption">Confirmar Cambios</p>
									</v-btn>
								</div>
								<div v-else>
									<div class="d-flex justify-space-between mb-1"><div style="width: 150px">Nombre</div><span>:</span> <b class="text-end" style="flex: 1;">{{client.profile.name}} {{client.profile.lastname}}</b></div>
									<div class="d-flex justify-space-between mb-1"><div style="width: 150px">Email</div><span>:</span> <b class="text-end" style="flex: 1;">{{client.email}}</b></div>
									<div class="d-flex justify-space-between mb-1"><div style="width: 150px">Teléfono</div><span>:</span> <b class="text-end" style="flex: 1;">{{client.profile.phone}}</b></div>
								</div>
							</div>
							<div class="pa-2" v-else>
								No se logró obtener datos de usuario
							</div>
						</v-card-text>
					</v-card>
					<v-tabs v-model="tab" class="custom-color-tabs" show-arrows grow>
						<v-tab>CRM / Solicitudes</v-tab>
						<v-tab>Antecedentes</v-tab>
						<v-tab>Contratos Y Costos</v-tab>
						<v-tab>Proyectos</v-tab>
						<v-tab>Supervisión de Obra /<br/> Construcción</v-tab>
					</v-tabs>
					<v-tabs-items v-model="tab" class="tab-item-custom" style="width: 100%; flex-grow: 1;">
						<v-tab-item>
							<div>
								<CrmList :clientId="clientId" :openCrmDetail="handleOpenCrmDetail" />
							</div>
						</v-tab-item>
						<v-tab-item>
							<div>
								<PreProjectList :clientId="clientId" :openPreProjectDetail="handleOpenPreProjectDetail" />
							</div>
						</v-tab-item>
						<v-tab-item>
							<div class="pa-4">
								<ContractList :clientId="clientId" :openContractDetail="handleOpenContractDetail" />
							</div>
						</v-tab-item>
					</v-tabs-items>
					<v-divider class="my-2"></v-divider>
					<DocumentsTable />
				</v-col>
				<v-col :cols="hideFirstCol ? 12:5" :class="!selectedContractId ? 'pa-0':''" style="position: relative;">
					<v-btn
						depressed
						color="#f2f2f2"
						small
						tile
						width="10px"
						min-width="10px"
						class="change-col-size"
						@click="changeColSize"
					>
						<v-icon>{{hideFirstCol ? 'mdi-chevron-right':'mdi-chevron-left'}}</v-icon>
					</v-btn>
					<CrmDetail
						v-if="selectedCrmId"
						:crmId="selectedCrmId"
						isVertical />
					<PreProjectDetail
						v-else-if="selectedPreProjectId"
						:preprojectId="selectedPreProjectId"
						:openCrmDetail="handleOpenCrmDetail"
						isVertical />
					<NonEditableContract
						v-else-if="selectedContractId"
						:contract_id="selectedContractId"
						:openCrmDetail="handleOpenCrmDetail"
						showEdit />
				</v-col>
			</v-row>
		</div>
  </v-container>
</template>

<script>
import CrmList from '@/components/Views/Clients/Parts/CRM'
import CrmDetail from '@/components/Views/Clients/Parts/CRM/crm'
import PreProjectList from '@/components/Views/Clients/Parts/PreProjects'
import PreProjectDetail from '@/components/Views/Clients/Parts/PreProjects/preproject'
import ContractList from '@/components/Views/Clients/Parts/Contracts/index'
import NonEditableContract from '@/components/Views/Contracts/NonEditableContract'
import DocumentsTable from '@/components/Views/PreProjects/DocumentsTable'

export default {
	name: 'ClientDetail',
  components: { CrmList, CrmDetail, PreProjectList, PreProjectDetail, ContractList, NonEditableContract, DocumentsTable },
  data() {
    return {
			hideFirstCol: false,
			clientId: null,
			selectedCrmId: null,
			selectedPreProjectId: null,
			selectedContractId: null,
			mapZoom: 7,
      zoomInterval: null,
      isZooming: false,
			itemsAddress: [],
			loadingAddress: false,
			search: '',
			autocompleteService: null,
			tab: null,
			items: ['Información', 'Conversación', 'Correos Enviados', 'Informes'],
			reportTemplate: null,
			loading: false,
			editValue: false,
			editProjectDetail: false,
			editScheduleTitle: false,
			editUser: false,
			editAddress: false,
			newValueCrm: null,
			newScheduleTitle: null,
			newProjectDetail: null,
			loadingEditUser: false,
			reportEditor: '',
			menuTemplates: false,
			cc_report: null,
			scheduledUserId: null,
			changingScheduleUser: false,
			subject: 'Informe Visita Técnica',
			report_title: 'Informe Visita Técnica Revisión Normativa',
			activePanel: null,
			activePanelB: null,
			templateName: null,
			userData: {
				name: null,
				email: null,
				phone: null
			},
			cachingItems: false,
			newAddress: {address: null, coordinates: null},
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			phoneRule: [
				v => !!v || 'Campo requerido',
				v => /^569\d{8}$/.test(v) || 'Debe tener 11 dígitos y empezar con 569',
			],
    };
  },
  computed: {
		editPermission(){
			return true
		},
		client(){
			return this.$store.state.users.client
		},
		queryRoute(){
			return this.$route.query
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.clientId = parseInt(this.$store.state.user.userInfo.id)
				try {
					this.$store.dispatch('users/getCompanyClient', this.clientId)
				} catch (error) {
					console.log('Error: ', error)
				}
			}
		},
		queryRoute(){
			const preproject_id = this.$route.query.p
			const crm_id = this.$route.query.crm
			const contract_id = this.$route.query.ct
			if(preproject_id){
				this.tab = 1
				this.handleOpenPreProjectDetail(preproject_id)
			}
			else if(crm_id){
				this.tab = 0
				this.handleOpenCrmDetail(crm_id)
			}
			else if(contract_id){
				this.tab = 2
				this.handleOpenContractDetail(contract_id)
			}
		}
	},
	mounted(){
		this.clientId = parseInt(this.$route.params.id)
		try {
			this.$store.dispatch('users/getCompanyClient', this.clientId)
			const preproject_id = this.$route.query.p
			const crm_id = this.$route.query.crm
			const contract_id = this.$route.query.ct
			if(preproject_id){
				this.tab = 1
				this.handleOpenPreProjectDetail(preproject_id)
			}
			else if(crm_id){
				this.tab = 0
				this.handleOpenCrmDetail(crm_id)
			}
			else if(contract_id){
				this.tab = 2
				this.handleOpenContractDetail(contract_id)
			}
		} catch (error) {
			console.log('Error: ', error)
		}
	},
	beforeDestroy(){
		this.$store.commit('users/setClient', null)
	},
  methods: {
		changeColSize(){
			this.hideFirstCol = !this.hideFirstCol
		},
		handleOpenCrmDetail(crm_id){
			this.selectedPreProjectId = null
			this.selectedContractId = null
			this.selectedCrmId = crm_id
			this.$router.replace({  query: {crm: crm_id} })
		},
		handleOpenPreProjectDetail(preproject_id){
			this.selectedCrmId = null
			this.selectedContractId = null
			this.selectedPreProjectId = preproject_id
			this.$router.replace({  query: {p: preproject_id} })
		},
		handleOpenContractDetail(contract_id){
			this.selectedCrmId = null
			this.selectedPreProjectId = null
			this.selectedContractId = contract_id
			this.$router.replace({  query: {ct: contract_id} })
		},
		toConversationDetail(crm) {
			this.$router.push({ name: 'Conversation', params: { id: crm.id } })
		},
		toPreProjectDetail(preproject) {
			this.$router.push({ name: 'PreProject', params: { id: preproject.id } })
		},
  },
};
</script>

<style scoped>
	.client-items-container{
		border: 1px solid rgb(213, 211, 211);
	}
	.single-message{
		background-color: rgb(217, 239, 217);
		border: 1px solid rgb(197, 232, 197);
		padding: 4px 8px;
		max-width: 80%;
		border-radius: 10px;
		font-size: 14px;
		margin-bottom: 8px;
		text-transform: none;
	}
	.single-message.assistant{
		background-color: rgb(213, 211, 211);
		border: 1px solid rgb(193, 191, 191);
		align-self: flex-end;
	}
	.template-list-item:hover{
		background-color: rgba(0,0,0,0.2);
	}
	.menu-bg-white{background: white;}
	.vertical-tabs .rotated-tab {
		writing-mode: vertical-lr;
		transform: rotate(180deg);
		text-align: center;
		height: 150px;
		line-height: 1;
	}

	.vertical-tabs .v-tab {
		justify-content: center;
		align-items: center;
		min-height: 100px;
		padding: 0px;
		min-width: 40px;
		width: 40px;
		font-size: 12px;
	}
	.vertical-tabs {
		align-items: flex-start;
		height: 100%;
	}
	.custom-color-tabs .v-tab{
		font-size: 12px;
	}
	.custom-color-tabs .v-tab.v-tab--active {
		background-color: #FF120B;
		color: #FFFFFF;
	}
	.custom-color-tabs .v-tab:not(.v-tab--active) {
		background-color: white;
		color: #000000!important;
	}
	.change-col-size{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9999;
	}
</style>
<style>
	.crm-conversation .single-message p{
		margin-bottom: 0;
	}
	.tab-item-custom .v-window__container{height: auto;}
</style>