<template>
  <v-app>
		<v-main>
			<Main/>
		</v-main>
  </v-app>
</template>

<script>
import Main from './components/Layout/Main'

export default {
  name: 'App',
  components: {
    Main
  },
	computed: {
		userAuth() {
			return this.$store.state.user.isAuthenticated
		},
		isMobile(){
			return this.$vuetify.breakpoint.mobile
		},
    preproject() {
      return this.$store.state.preprojects.preProject
    },
    preprojects() {
      return this.$store.state.preprojects.preProjects
    },
    conversation() {
      return this.$store.state.crm.conversation
    },
    conversations() {
      return this.$store.state.crm.conversations
    }
	},
	watch: {
		userAuth(newData, oldData) {
			if(newData && !oldData){
				this.getUserInfo()
				this.getUserGeo()
			}
		},
		isMobile(newData) {
			if(!newData){
				this.$store.commit('menu/setMenuSidebarStatus', true)
			}
		},
    preproject(newValue) {
      if (!this.hasWatched && newValue) {
        this.hasWatched = true;
				this.checkModalStatus()
			}
    },
    conversation(newValue) {
      if (!this.hasWatched && newValue) {
        this.hasWatched = true;
				this.checkModalStatus()
			}
    },
    preprojects(newValue) {
      if (!this.hasWatched && newValue) {
        this.hasWatched = true;
				this.checkModalStatus()
			}
    },
    conversations(newValue) {
      if (!this.hasWatched && newValue) {
        this.hasWatched = true;
				this.checkModalStatus()
			}
    }
	},
  data: () => ({
		hasWatched: false
  }),
	mounted(){
		this.$store.commit('menu/setMenuSidebarStatus', !this.$vuetify.breakpoint.mobile)
		this.$store.dispatch('general/getHolidayDays', '2024')
		this.$store.dispatch('general/getDayUf')
		if(this.$store.state.user.isAuthenticated){
			this.getUserInfo()
			this.getUserGeo()
			this.$store.dispatch('general/getStates')
			this.$store.dispatch('general/getCountryCities', 44)
		}
	},
	methods: {
		getUserGeo(){
			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						const lat = position.coords.latitude;
						const lng = position.coords.longitude;
						this.$store.commit('general/setUserGeo', {lat,lng})
					},
					(error) => {
						console.error('Error obteniendo la ubicación: ', error);
					}
				);
			}
		},
		getUserInfo(){
			this.$store.dispatch('user/getUserInfo')
			this.$store.dispatch('companies/getCompanyProjectTypes')

			//Ver para cambiar sólo a budget modal
			this.$store.dispatch('budget/getBudgetConcepts')
			this.$store.dispatch('budget/getBudgetCategories')
			this.$store.dispatch('budget/getBudgetDetails')
			this.$store.dispatch('budget/getBudgetNotes')
		},
		checkModalStatus(){
			const chat_open = localStorage.getItem('chat_open')
			const budget_main_open = localStorage.getItem('budget_main_open')
			const budget_open = localStorage.getItem('budget_open')

			if(chat_open){
				this.$store.commit('preprojects/setOpenChatModal', true)
			}
			else{
				if(budget_main_open){
					this.$store.commit('preprojects/setOpenBudgetModal', true)
				}
				if(budget_open){
					const data = JSON.parse(budget_open)
					setTimeout(() => {
						this.$store.commit('general/setNewBudgetModal', data)
					}, 300)
				}
			}
		}
	}
};
</script>
<style>
	.v-date-picker-table td {
		padding: 0!important;
	}
	body .v-application .v-progress-linear__indeterminate,
	body .v-application .v-progress-linear__background{
		background-color: #FF120B!important; /* Cambia este color */
		border-color: #FF120B!important; /* Cambia este color */
	}
	body .v-select__selections .v-select__selection--comma{font-size: 12px;}
	body .w-100{width: 100%!important;}
</style>