const endpoints = {
	createCostCategory: '/cost/createCostCategory',
	createCostSubcategory: '/cost/createCostSubcategory',
	createCostItem: '/cost/createCostItem',
	getCostCategories: '/cost/getCostCategories',
	getCostCategory: '/cost/getCostCategory',
	saveAllCostCategory: '/cost/saveAllCostCategory',
	deleteCostCategory: '/cost/deleteCostCategory',
	deleteCostSubcategory: '/cost/deleteCostSubcategory',
	deleteCostItem: '/cost/deleteCostItem'
}
export {endpoints}