<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.contracts.modalSection"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" class="info-icon mr-2">mdi-information-symbol</v-icon>
								<div>
									<p class="mb-0 font-weight-bold text-primary title">Sección contrato</p>
								</div>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4">
								<v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="companyContractSection.title"
                    label="Título Sección"
                    placeholder="Ingrese un título"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
								<v-col cols="12" class="py-0">
									<div v-if="loadingMce" class="d-flex flex-column justify-center align-center" style="min-height: 200px;">
										<v-skeleton-loader
											style="width: 250px;"
											class="mb-1"
											type="paragraph"
										></v-skeleton-loader>
										<p>Cargando editor...</p>
									</div>
									<div v-else>
										<p class="mb-1 font-weight-bold">Contenido Sección</p>
										<p class="mb-2 caption">Este será el contenido que aparecerá en la sección del contrato cuando la selecciones</p>
										<vue2-tinymce-editor
											:inline="true"
											v-model="companyContractSection.content"
											:options="{
												plugins: 'paste image imagetools',
												language: 'es',
												language_url: 'https://cdn.tiny.cloud/1/no-api-key/tinymce/5/langs/es.js',
												font_formats: 'Archivo Narrow=system-ui; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
												toolbar: 'undo redo | fontselect fontsizeselect addVariableButton | styleselect bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table',
												setup: setupTiny,
												content_style: `@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400..700;1,400..700&display=swap');`,
												content_css: `
													.friendly-placeholder {
														color: #007bff;
														background-color: #e9f7ff;
														padding: 2px 4px;
														border-radius: 4px;
														font-style: italic;
													}
												`,
												automatic_uploads:true,
												images_upload_handler: uploadImageFromTiny,
												file_picker_callback: filePickerTiny
											}"
											ref="tinymceEditor"></vue2-tinymce-editor>
										</div>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import {createContractSection, updateContractSection} from '@/helpers/api/contracts'
import {fileUploadToReport} from '@/helpers/api/files'

export default {
  name: 'ContractSectionModal',
  components: { Vue2TinymceEditor },
  data: () => ({
    validform: true,
		loadingData: false,
		loadingMce: true,
    companyContractSection: {
			title: null,
			code: null,
			type: null,
			order: 1,
			content: null,
			category_id: null
    },
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ],
		isUpdatingContent: false,
		placeholderMapping: {
			'{contract_start_date}': 'Fecha Inicio',
			'{contract_date}': 'Fecha del Contrato',
			'{address}': 'Dirección del Proyecto',
			'{district}': 'Municipio del Proyecto',
			'{client_name}': 'Nombre Cliente',
			'{client_dni}': 'Rut Cliente',
			'{company_name}': 'Nombre Empresa',
			'{company_rut}': 'Rut Empresa',
			'{company_responsible}': 'Nombre Representante',
			'{company_responsible_rut}': 'Rut Representante',
			'{company_address}': 'Dirección Empresa',
		}
  }),
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
  mounted(){
		if(this.$store.state.contracts.modalSectionEditing){
			this.companyContractSection = this.$store.state.contracts.modalSectionEditing
		}
		this.$nextTick(() => {
			this.loadingMce = false
		});
  },
  methods: {
    closeModal(){
      this.$store.commit('contracts/setModalSection', false)
			this.$store.commit('contracts/setModalSectionEditing', null)
      this.companyContractSection = {
				title: null,
				code: null,
				type: null,
				order: 1,
				content: null,
				category_id: null,
			}
      this.validform = true
      this.loadingData = false
    },
    async sendForm(){
      if(this.$refs.form.validate()){
				this.loadingData = true
        const concept = this.companyContractSection.section_id ? await updateContractSection(this.companyContractSection):await createContractSection(this.companyContractSection)
        if(concept.code === 200){
					this.$store.dispatch('contracts/getCompanyContractsConfig', {category_id: this.companyContractSection.category_id, start: 0, limit: 100})
          const messageData = {
            message: 'Se ha creado correctamente la sección',
            title: 'Sección creada',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
          this.setMessage(messageData)
          this.closeModal()
        }
        else{
          const messageData = {
            message: 'Hubo un error en la operación, por favor intenta nuevamente',
            title: 'Ha ocurrido un error',
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#F4516C',
          }
          this.setMessage(messageData)
        }
				this.loadingData = false
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
		transformPlaceholdersToFriendlyView(content) {
			let result = content;
			for (const [placeholder, label] of Object.entries(this.placeholderMapping)) {
				const regex = new RegExp(placeholder, 'g');
				result = result.replace(regex, `<span class="friendly-placeholder" style="font-size: 12px;padding: 1px 6px;border: 1px solid grey;border-radius: 5px;background: white;color:orange;" contenteditable="false">${label}</span>`);
			}
			return result;
		},
		transformFriendlyViewToPlaceholders(content) {
			let result = content;
			for (const [placeholder, label] of Object.entries(this.placeholderMapping)) {
				const regex = new RegExp(`<span class="friendly-placeholder"[^>]*>${label}</span>`, 'g');
				result = result.replace(regex, placeholder);
			}
			return result;
		},
		uploadImageFromTiny(file, succes, failure){
			const formData = new FormData();
			formData.append('file', file.blob());

			fileUploadToReport(formData)
				.then((uploaded_file) => {
					if (uploaded_file.code === 200) {
						succes(uploaded_file.url);
					} else {
						failure(new Error(`Error: ${uploaded_file.code}`));
					}
				})
				.catch((error) => {
					failure(error);
				});
		},
		filePickerTiny(callback) {
			const input = this.$refs.fileInput;
			input.onchange = async (event) => {
				const file = event.target.files[0];
				const formData = new FormData();
				formData.append('file', file);
				try {
					const uploaded_file = await fileUploadToReport(formData);
					if (uploaded_file.code === 200) {
						const url = uploaded_file.url;
						callback(url, { alt: file.name });
					} else {
						console.error('Error al subir la imagen:', uploaded_file.message);
					}
				} catch (error) {
					console.error('Error en la subida de la imagen:', error);
				}
			};
			input.click();
		},
		setupTiny(editor){
			editor.on('init', () => {
				const content = editor.getContent();
				const transformedContent = this.transformPlaceholdersToFriendlyView(content);
				editor.setContent(transformedContent);
			})
			editor.on('input SetContent', () => {
				if (this.isUpdatingContent) return
				this.isUpdatingContent = true
				const content = editor.getContent()
				const updatedContent = this.transformPlaceholdersToFriendlyView(content)
				if (updatedContent !== content) {
					editor.setContent(updatedContent)
				}

				this.isUpdatingContent = false;
			})
			editor.on('GetContent', (event) => {
				const originalContent = this.transformFriendlyViewToPlaceholders(event.content);
				event.content = originalContent;
			})
      editor.ui.registry.addMenuButton('addVariableButton', {
        text: 'Insertar Variable',
        icon: 'paste-text',
				fetch: (callback) => {
					const items = [
						{
							type: 'nestedmenuitem',
							text: 'Cliente',
							getSubmenuItems: () => [
								{
									type: 'menuitem',
									text: 'Nombre Cliente',
									onAction: () => editor.insertContent('{client_name}')
								},
								{
									type: 'menuitem',
									text: 'Rut Cliente',
									onAction: () => editor.insertContent('{client_dni}')
								}
							]
						},
						{
							type: 'nestedmenuitem',
							text: 'Proyectos',
							getSubmenuItems: () => [
								{
									type: 'menuitem',
									text: 'Fecha del Contrato',
									onAction: () => editor.insertContent('{contract_date}')
								},
								{
									type: 'menuitem',
									text: 'Fecha Inicio del Proyecto',
									onAction: () => editor.insertContent('{contract_start_date}')
								},
								{
									type: 'menuitem',
									text: 'Dirección del Proyecto',
									onAction: () => editor.insertContent('{address}')
								},
								{
									type: 'menuitem',
									text: 'Municipio del Proyecto',
									onAction: () => editor.insertContent('{district}')
								}
							]
						},
						{
							type: 'nestedmenuitem',
							text: 'Empresa',
							getSubmenuItems: () => [
								{
									type: 'menuitem',
									text: 'Nombre',
									onAction: () => editor.insertContent('{company_name}')
								},
								{
									type: 'menuitem',
									text: 'Rut',
									onAction: () => editor.insertContent('{company_rut}')
								},
								{
									type: 'menuitem',
									text: 'Nombre Representante',
									onAction: () => editor.insertContent('{company_responsible}')
								},
								{
									type: 'menuitem',
									text: 'Rut Representante',
									onAction: () => editor.insertContent('{company_responsible_rut}')
								},
								{
									type: 'menuitem',
									text: 'Dirección',
									onAction: () => editor.insertContent('{company_address}')
								}
							]
						},
					];
					callback(items);
				}
      })

			editor.on('paste', (e) => {
				const clipboard = e.clipboardData || e.originalEvent.clipboardData
				
				if (clipboard && clipboard.items) {
					let pastedContent = '';
					let imageCounter = 0;

					const handleItem = (item) => {
							return new Promise((resolve) => {
									if (item.type.indexOf('image') !== -1) {
											// Procesar imágenes como archivos
											const file = item.getAsFile();
											const formData = new FormData();
											formData.append('file', file);

											fileUploadToReport(formData)
													.then((uploaded_file) => {
															if (uploaded_file.code == 200) {
																	const url = uploaded_file.url;
																	pastedContent += `<img src="${url}" />`;
															}
															resolve();
													});
									} else if (item.type === 'text/html') {
											// Procesar HTML (posibles imágenes en base64)
											item.getAsString((htmlContent) => {
													const parser = new DOMParser();
													const doc = parser.parseFromString(htmlContent, 'text/html');
													const images = doc.querySelectorAll('img');

													if (images.length > 0) {
														// Si hay imágenes en base64, subirlas al servidor
														images.forEach((img, index) => {
															if (img.src.startsWith('data:image')) {
																const base64Data = img.src.split(',')[1];
																const contentType = img.src.split(';')[0].split(':')[1];

																const byteCharacters = atob(base64Data);
																const byteNumbers = new Array(byteCharacters.length);
																for (let i = 0; i < byteCharacters.length; i++) {
																	byteNumbers[i] = byteCharacters.charCodeAt(i);
																}

																const byteArray = new Uint8Array(byteNumbers);
																const blob = new Blob([byteArray], { type: contentType });
																const file = new File([blob], `image${imageCounter}.png`, { type: contentType });

																const formData = new FormData();
																formData.append('file', file);
																imageCounter++;

																// Subir imagen al servidor
																fileUploadToReport(formData)
																	.then((uploaded_file) => {
																		if (uploaded_file.code == 200) {
																			img.src = uploaded_file.url;
																		}
																		if (index === images.length - 1) {
																			pastedContent += doc.body.innerHTML;
																			resolve();
																		}
																	});
															} else {
																// Si no es base64, insertar la imagen directamente
																pastedContent += doc.body.innerHTML;
																resolve();
															}
														});
													} else {
															// No hay imágenes, solo insertar el HTML pegado
															pastedContent += htmlContent;
															resolve();
													}
											});
									} else if (item.type === 'text/plain') {
											// Procesar texto sin formato
											item.getAsString((plainText) => {
													pastedContent += plainText;
													resolve();
											});
									} else {
											resolve();
									}
							});
					};

					const processItemsSequentially = async () => {
							for (let i = 0; i < clipboard.items.length; i++) {
									await handleItem(clipboard.items[i]);
							}
							// Una vez procesados todos los ítems, insertar el contenido en el editor
							editor.execCommand('mceInsertContent', false, pastedContent);
					};

					// Procesar los ítems de forma secuencial
					processItemsSequentially();
				}
			})
		},
  }
};
</script>
<style>
  .friendly-placeholder {
    color: #007bff;
    background-color: #e9f7ff;
    padding: 2px 4px;
    border-radius: 4px;
    font-style: italic;
  }
</style>
<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
	.friendly-placeholder{
		background: red;
	}
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>